import React from 'react';
import SessionManager from './Utilities/SessionManager';
import { Router, navigate } from "@gatsbyjs/reach-router";
import Dashboard from './Pages/Dashboard/Dashboard';
import Landing from './Pages/Landing/Landing';
import { SessionStore } from './Stores/SessionStore';
import { AccountStore } from './Stores/AccountStore';
import { CompanyValuesStore } from './Stores/CompanyValuesStore';
import './Styles/Basic.scss';
import 'react-tippy/dist/tippy.css';
import APIFetch from './Utilities/APIFetch';
import { setUpNotifications } from './Utilities/Notifications';
import { RewardsStore } from './Stores/RewardsStore';
import Tutorial from './Pages/Tutorial/Tutorial';
import AppLinks from './Pages/AppLinks/AppLinks';
import { getMyProfile, getMyProfileImage } from './Utilities/GraphAPI';
import { EmployeeStore } from './Stores/EmployeeStore';
import { FeedStore } from './Stores/FeedStore';

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      unauthorized: false,
      began: false,
      pendingTerms: null
    }

    if(/Mobi|iPad|iPhone|iPod|Android/i.test(navigator.userAgent)){
      navigate('/app');
    } else {
      navigate('/');
      this.sessionManager = new SessionManager(this.loginSuccess, () => { this.state.loading = false; } );
      SessionStore.update(s => { s.sessionManager = this.sessionManager });
    }
  }

  beginLogin = () => {
    this.setState({ loading: true, began: true });
    this.sessionManager.startSession();
  }

  loginSuccess = () => {
    APIFetch('GET', 'terms/pending')
    .then((result) => {
        if(result && result.ok) {
            if(result.data && result.data.length > 0) {
              // Take to usage guidance
              this.setState({ pendingTerms: result.data, termsCallback: () => { this.setState({ pendingTerms: null, termsCallback: null }); this.loginSuccess(); } });
            } else {
              this.loginComplete();
            }
        } else if(result.status === 403) {
          // Unauthorized user
          this.setState({ unauthorized: true });
          navigate('/');
        } else {
          // Terms no longer mandatory - allow access after failure
          this.loginComplete();
        }
    })
    .catch(e => {
      // Terms no longer mandatory - allow access after failure
      this.loginComplete();
    })
  }

  loginComplete = () => {
    if(!this.state.loading) {
      this.setState({ loading: true });
    }
    
    setUpNotifications();
    
    // Get user status
    Promise.all([
      APIFetch('GET', 'user/status/web')
      .then((result) => {
        if(result && result.ok) {
          AccountStore.update(s => { s.status = result.data });
        } else {
          if(result.status === 401) {
            if(!this.state.began) this.beginLogin();
          } else if(result.status === 403) {
            // Unauthorized user
            this.setState({ unauthorized: true });
            navigate('/');
            throw result;
          } else {
            throw result;
          }
        }
      })
      .catch(e => {
        if(e && e.status === 403) {
          throw e;
        }
        // Continue without data
      }),
      APIFetch('GET', 'companyvalues')
      .then((result) => {
        if(result && result.ok) {
          CompanyValuesStore.update(s => { s.values = result.data });
        } else {
          if(result.status === 401) {
            if(!this.state.began) this.beginLogin();
          } else if(result.status === 403) {
            // Unauthorized user
            this.setState({ unauthorized: true });
            navigate('/');
            throw result;
          } else {
            throw result;
          }
        }
      })
      .catch(e => {
        if(e && e.status === 403) {
          throw e;
        }
        // Continue without data
      }),
      APIFetch('GET', 'rewards')
        .then((result) => {
            if(result && result.ok) {
                result.data.sort((a,b) => (a.company > b.company) ? 1 : ((b.company > a.company) ? -1 : 0));
                result.data.forEach(r => {
                  r.cards.forEach(c => {
                      // Remove random number in url to allow caching. 
                      c.imageURL = c.imageURL.split('?')[0];
                  });
                });
                RewardsStore.update(s => { s.rewards = result.data });
            } else {
                RewardsStore.update(s => { s.rewards = null });
            }
        })
        .catch(e => {
            RewardsStore.update(s => { s.rewards = null });
        }),
        getMyProfile()
          .then(result => {
              if(result.status === 200) {
                getMyProfileImage()
                .then(imageResult => {
                    if(imageResult !== null) {
                        result.data.image = "data:image/png;base64, " + imageResult;
                    }
                    
                    EmployeeStore.update(s => { s[result.data.id] = result.data });
                    AccountStore.update(s => { s.profile = result.data });
                })
                .catch(e => {});
              }
          })
          .catch(e => {
              AccountStore.update(s => { s.profile = null });
          }),
          APIFetch('GET', 'badges')
          .then((result) => {
              if(result && result.ok) {
                  AccountStore.update(s => { s.badges = result.data });
              }
          })
          .catch(e => {
          }),
          APIFetch('GET', 'social/posts?api-version=2.0')
          .then((result) => {
              if(result && result.ok) {
                  FeedStore.update(s => { s.socialFeed = result.data });

                  var pendingMandatory = result.data.filter(p => p.mandatory === true);
                  AccountStore.update(s => { s.pendingPosts = pendingMandatory }); 
              } else {
                  FeedStore.update(s => { s.socialFeed = null });
              }
          })
          .catch(e => {
              FeedStore.update(s => { s.socialFeed = null });
          })
    ])
    .then(() => {
      if(!AccountStore.currentState.status.loggedInWeb) {
        navigate('/tutorial');
        this.setState({
          loading: false,
          unauthorized: false,
          began: false,
          pendingTerms: null
        });
      } else if(window.location.pathname !== '/admin' && window.location.pathname !== '/tutorial') {
        navigate('/dashboard');
        this.setState({
          loading: false,
          unauthorized: false,
          began: false,
          pendingTerms: null
        });
      }
    })
    .catch(e => {
      // Continue without data
    })
  }

  render() {
    return (
        <Router>
          <Landing path="/" loading={this.state.loading} beginLogin={this.beginLogin} unauthorized={this.state.unauthorized} pendingTerms={this.state.pendingTerms} termsCallback={this.state.termsCallback}/>
          <Dashboard path="/dashboard"/>
          <Tutorial path="/tutorial" />
          <AppLinks path="/app" />
        </Router>
    );
  }
}