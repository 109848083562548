import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Scrollbar from 'react-scrollbars-custom';
import Loader from 'react-loader-spinner';
import APIFetch from '../../Utilities/APIFetch';
import RoleAssignmentModal from '../../Components/RoleAssignmentModal/RoleAssignmentModal';
import RoleAssignmentItem from '../../Components/RoleAssignmentItem/RoleAssignmentItem';
import DetailsPopOver from '../../Components/DetailsPopOver/DetailsPopOver';

const RoleAssignment = (props) => {
    const [assignments, setAssignments] = useState(null);
    const [assignmentModal, setAssignmentModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    
    useEffect(() => {
        getAssignments();
    }, []);

    const getAssignments = () => {
        APIFetch('GET', `roles`)
        .then((result) => {
            if(result && result.ok) {
                setAssignments(result.data);
            }
            else {
                setErrorText("An error occurred when attempting to retrieve existing role assignments, please try again later.");
            }
        })
        .catch(e => {
            setErrorText("An error occurred when attempting to retrieve existing role assignments, please try again later.");
        });
    }

    const deleteEntry = (id) => {
        var newAssignments = [...assignments];
        newAssignments.splice(newAssignments.findIndex(a => a.userRoleOverrideID == id));
        setAssignments(newAssignments);
    }

    const saveAssignment = (employee, role) => {
        if(!employee || !role) {
            setAssignmentModal(false);
            return;
        }

        APIFetch('POST', `roles`, { userId: employee.id, roleName: role })
        .then((result) => {
            if(result && result.ok) {
                if(assignmentModal.user) {
                    // Edit
                    var newAssignments = [...assignments];
                    newAssignments.find(a => a.userId == employee.id).roleName = role;
                    setAssignments(newAssignments);
                } else {
                    // Create
                    var newAssignments = [...assignments];
                    newAssignments.push(result.data);
                    setAssignments(newAssignments);
                }

                setAssignmentModal(false);
            } else {
                setErrorText("An error occurred with your request, please try again later.");
            }
        })
        .catch(e => {
            setAssignmentModal(false);
            setErrorText("An error occurred with your request, please try again later.");
        });
    }

    return(
        <div className="flex full-height">
            { errorText ? <DetailsPopOver onClose={() => { setErrorText(null); }} title="Oops, something went wrong!" content={errorText}/> : null }
            { assignmentModal ? <RoleAssignmentModal onClose={() => { setAssignmentModal(false) }} onConfirm={saveAssignment} edit={assignmentModal}/> : null }
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onBack} tabIndex={0} role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <div className="flex-row flex-align-center padding-left-1 margin-bottom-2">
                <div>
                    <div className="text-grey text-lg bold">Role Assignment</div>
                    <div className="text-grey light margin-bottom-3">Override the default role assignments for specific users.</div>
                </div>
                <div style={{ marginRight: '35px' }} className="btn btn-positive flex-item-right" onClick={() => { setAssignmentModal(true) } } tabIndex={0} role="button">
                    Assign New Role
                </div>
            </div>
            <Scrollbar className="flex flex-grow">
                { assignments === null ? <Loader
                    type="Oval"
                    color="#FF0000"
                    height={40}
                    width={40}
                    className="list-loader"
                /> : assignments.map(a => <RoleAssignmentItem assignment={a} onDelete={() => { deleteEntry(a.userRoleOverrideID)  }} onEdit={(user, roleName) => { setAssignmentModal({ user, roleName}) }}/>)}
            </Scrollbar>
        </div>
    )
}

export default RoleAssignment;