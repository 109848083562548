import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import { AccountStore } from '../../Stores/AccountStore';
import { FeedStore } from "../../Stores/FeedStore";
import APIFetch from "../../Utilities/APIFetch";

const SelectedItem = React.forwardRef((props, ref) => {
    const [isBusy, setIsBusy] = useState(false);
    const availablePoints = useStoreState(AccountStore, s => s.status.availablePoints);
    const [selectedCard, setSelectedCard] = useState(props.giftCard.cards[0]);
    const [openDetail, setOpenDetail] = useState(0);
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    useEffect(() => {
        var newSelected = 0;

        for(var i = 0; i < props.giftCard.cards.length; i++) {
            if(props.giftCard.cards[i].inStock) {
                newSelected = i;
                break;
            }
        }

        setSelectedCard(props.giftCard.cards[newSelected]);
    }, [props.giftCard]);

    const updateFeed = () => {
        // After successful purchase get new feed
        var date = new Date();
        APIFetch('GET', `feed?from=0&to=100&date=${date.toISOString()}`)
        .then((result) => {
            if(result && result.ok) {
                FeedStore.update(s => { s.feed = result.data.feed });
            }
        })
        .catch(e => {
            
        });
    }

    const purchase = () => {
        if(!isBusy && selectedCard.value * 100 <= availablePoints) {
            setIsBusy(true);
            APIFetch('POST', `rewards/redeemcard/${selectedCard.id}`)
            .then((result) => {
                if(result && result.ok) {
                    AccountStore.update(s => { s.status.availablePoints -= selectedCard.value * 100 });
                    updateFeed();
                    props.onComplete(selectedCard);
                } else {
                    if(result.status === 400) {
                        result.data.text()
                        .then(text => {
                            setErrorText(text);
                            setErrorModal(true);
                            setIsBusy(false);
                        })
                        .catch(() => {
                            setErrorModal(true);
                            setIsBusy(false);
                        });
                    }
                }
            })
            .catch(e => {
                setErrorModal(true);
                setIsBusy(false);
            });
        }
    }

    const onClose = () => {
        if(errorModal) {
            setErrorModal(false);
        } else if(openDetail > 0) {
            setOpenDetail(0);
        } else {
            props.onCancel();
        }
    }

    if(openDetail > 0) {
        return (
            <div className="selected-item box-shadow margin-left-1 margin-bottom-2 relative terms" style={{ padding: "40px" }} ref={ref} role="group" tabIndex="0">
                <FontAwesomeIcon icon={faTimes} className="close pointer" onClick={onClose} role="button" tabIndex="0"/>
                <p className="text-xlg margin-0 margin-bottom-1 bold">{openDetail === 1 ? "Description" : "Terms & Conditions"}</p>
                <Scrollbar style={{ height: 'unset', minHeight: "220px", paddingRight: "5px" }} scrollerProps={{tabIndex: 0}}>
                    <p className="text-md text-alt text-grey-2">{openDetail === 1 ? selectedCard.description : selectedCard.terms}</p>
                </Scrollbar>
            </div>
        )
    }

    return (
        <div className="selected-item box-shadow margin-left-1 margin-bottom-2 relative" ref={ref} role="group" tabIndex="0">
            <FontAwesomeIcon icon={faTimes} className="close pointer" onClick={onClose} role="button" tabIndex="0"/>
            {errorModal ? <DetailsPopOver onClose={() => setErrorModal(false)} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            <div className="flex-row flex-align-center flex-space-evenly" role="group" tabIndex="0">
                <img className="flex min-height-0 flex-grow" src={selectedCard.imageURL} alt={props.giftCard.company} />
                { confirmation ? <div className="flex-grow">
                        <div className="flex flex-center-both">
                            <p className="light text-lg margin-bottom-1 text-align-center">{`You are about to redeem`}</p>
                            <p className="margin-top-0 margin-bottom-2 text-xlg text-align-center">{selectedCard.title}</p>
                        </div>
                        <div className="flex-row flex-center-both margin-top-2">
                            <div className="btn margin-right-2 padding-top-1 padding-bottom-1 btn-positive-alt" onClick={purchase}>Confirm</div>
                            <div className="btn btn-negative padding-top-1 padding-bottom-1" onClick={() => setConfirmation(false)}>Cancel</div>
                        </div>
                    </div> : 
                    <div className="flex flex-align-center flex-grow">
                        <div className="flex flex-center-both">
                            <p className="light text-lg margin-bottom-1 text-align-center">{`Are you sure you want to exchange ${selectedCard.value * 100} coins for`}</p>
                            <p className="margin-top-0 margin-bottom-2 text-xlg text-align-center">{selectedCard.title}</p>
                        </div>
                        <div className="selector box-shadow flex-row">
                            {props.giftCard.cards.map(c => {
                                return <div key={c.id} className={"selector-item text-md " + (c === selectedCard ? " active" : "") + (c.inStock ? "" : " disabled")} onClick={() => { setSelectedCard(c); }}>
                                    £{c.value}
                                </div>
                            })}
                        </div>

                        { selectedCard.inStock && selectedCard.value * 100 <= availablePoints ?
                           <div className="flex-row flex-center-both margin-top-2">
                                <div className={"btn margin-right-2 padding-top-1 padding-bottom-1 btn-positive-alt"} onClick={() => { setConfirmation(true) }}>Yes, I want it!</div>
                                <div className="btn btn-negative padding-top-1 padding-bottom-1" onClick={props.onCancel}>No, thanks</div>
                            </div>
                        : <div className="flex-row flex-center-both margin-top-2">
                            <div className="btn btn-negative-alt padding-top-1 padding-bottom-1">{selectedCard.inStock === false ? "Out of stock" : "Insufficient funds"}</div>
                        </div> }

                        <div className="flex-row flex-center-both margin-top-1">
                            <p className="underline pointer margin-right-2 text-grey text-sm" onClick={() => setOpenDetail(1)}>Description <FontAwesomeIcon icon={faInfoCircle}/></p>
                            <p className="underline pointer text-grey text-sm" onClick={() => setOpenDetail(2)}>{"Terms & Conditions"} <FontAwesomeIcon icon={faInfoCircle}/></p>
                        </div>
                    </div>
                }
            </div>
            {isBusy ? <LoadingOverlay /> : null }
        </div>
    );
});

export default SelectedItem;