import { faComment, faEye, faHeart, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import FlipMove from 'react-flip-move';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import Customer from '../../Assets/images/values/Customer.png';
import Engagement from '../../Assets/images/values/Engagement.png';
import Operational from '../../Assets/images/values/Operational.png';
import Partnering from '../../Assets/images/values/Partnering.png';
import Performance from '../../Assets/images/values/Performance.png';
import Responsibility from '../../Assets/images/values/Responsibility.png';
import Safety from '../../Assets/images/values/Safety.png';
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from '../../Stores/ActionStore';
import { CompanyValuesStore } from "../../Stores/CompanyValuesStore";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { FeedStore } from "../../Stores/FeedStore";
import { getProfile } from "../../Utilities/GraphAPI";
import Badge from "../Badge/Badge";
import Coins from '../../Assets/images/Coins.svg';
import './NotificationItem.scss';
import APIFetch from '../../Utilities/APIFetch';

const CUSTOM_ENTER_ANIM = {
    from: { transform: 'scaleX(0)', transformOrigin: 'right center' },
    to: { transform: '', transformOrigin: 'right center' },
}

const CUSTOM_LEAVE_ANIM = {
    from: { transform: 'scaleX(1)', transformOrigin: 'right center' },
    to: { transform: 'scaleX(0)', transformOrigin: 'right center' },
}

const valueLookup = {
    "b0dcb8e0-d1de-4bd7-9e87-e9457b7ca705": Partnering,
    "76085e52-af11-4787-a66e-36cf9bbc241c": Customer,
    "3a4ab1e0-259b-479c-a9e6-3b604400d9e3": Operational,
    "b9915816-4466-4157-bc21-ce03dfd468fd": Engagement,
    "3d6b8439-1a7c-4e6c-a23a-442110a4d793": Safety,
    "1effd07f-d4f8-4ec9-932e-d983069a1e13": Responsibility,
    "886234fe-fa2a-4e67-ae09-8a476cbde82d": Performance
}

// TODO: Split into seperate items per type

const NotificationItem = (props) => {
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const companyValues = useStoreState(CompanyValuesStore, s => s.values);
    const [liking, setLiking] = useState(false);
    const [userLiked, setUserLiked] = useState(false);
    var bodyContent = null;
    var match = null;
    // Kudos
    var coinSplit = "";
    var bodySplit;
    var employeeId;

    // Badge
    const myBadges = useStoreState(AccountStore, s => s.badges);
    var badgeName;

    useEffect(() => {
        setUserLiked(props.notification.userLiked || false);
    }, [props.notification])

    if(props.notification.type === "kudos") {
        bodySplit = props.notification.body.split("}");
        employeeId = bodySplit[0].substring(1);
    } else if(props.notification.type === "badge" || props.notification.type === "award" ) {
        bodySplit = props.notification.body.split("}");
        badgeName = bodySplit[0].substring(1);
    }

    var employee = useStoreState(EmployeeStore, s => { return props.notification.type === "kudos" ? (s[employeeId] || null) : null });
    var badge = myBadges && badgeName ? myBadges.find(b => b.name === badgeName) || null : null;

    if(props.notification.type === "kudos") {
        if(bodySplit.length > 1) {
            coinSplit = bodySplit[1].split('coins');
        }

        if(employee === null) {
            getProfile(employeeId)
            .catch(e => {});
        }

        bodyContent = <p className="text-sub light margin-0" style={coinSplit.length > 1 ? { marginTop: '-9px' } : null}><span className="underline pointer" onClick={() => onProfileClick(employee)} tabIndex={0} role="button">{employee ? employee.displayName : employeeId}</span>{coinSplit[0]}{coinSplit.length > 1 ? [<img src={Coins} className="coins-icon" alt="coins" key="coin-icon"/>, coinSplit[1]] : null }</p>;
    } else if(props.notification.type === "badge" || props.notification.type === "award") {
        bodyContent = <p className="text-sub light margin-0">{bodySplit[1]}</p>
    } else {
        bodyContent = <p className="text-sub light margin-0">{props.notification.body}</p>
    }

    const onProfileClick = (profile) => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
    }

    const onReadMoreClick = () => {
        if(props.notification.type === 'socialPost') {
            var match = FeedStore.currentState.socialFeed ? FeedStore.currentState.socialFeed.find(p => p.id === props.notification.itemId) : null;
            if(match) {
                ActionStore.update(s => { s.modals.push({ type: 'SocialPost', data: match }) });
            }
        } else if(props.notification.type === 'comment' || props.notification.type === 'kudos' || props.notification.type === 'badge') {
            ActionStore.update(s => { s.modals.push({ type: 'FullPost', data: { postId: props.notification.itemId, commentId: props.notification.secondaryId } }) });
        }
    }

    const openComments = () => {
        ActionStore.update(s => { s.modals.push({ type: 'FullPost', data: { postId: props.notification.itemId, commentId: props.notification.secondaryId } }) });
    }

    const onBadgeClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Badges', data: myProfile }) });
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${props.notification.itemId}`)
                .then((result) => {
                    if(result && result.ok) {
                        setUserLiked(false);
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${props.notification.itemId}`)
                .then((result) => {
                    if(result && result.ok) {
                        setUserLiked(true);
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    if(props.notification.type === 'kudos') {
        match = FeedStore.currentState.feed.find(a => a.kudos && (a.kudos.id === props.notification.itemId));
        var valueMatch = match ? companyValues.find(v => v.id === match.kudos.valueId) : null;

        return (
            <div className="notification-item flex-row flex-align-center padding-1" tabIndex={0} role="group">
                { employee ? <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-sm pointer margin-right-1 flex-shrink-0" onClick={() => onProfileClick(employee)} alt={employee.displayName} tabIndex={0} role="button"/> : null }
                <div className="flex text-align-left flex-justify-center flex-grow">
                    <p className="margin-0 margin-bottom-1">{props.notification.title}</p>
                    {bodyContent}
                    <div className="flex-row margin-top-1">
                        <p className="time-since text-sub text-sm margin-0">{moment(props.notification.createdAt).calendar()}</p>
                    </div>
                </div>
                { userLiked !== null ? <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex={0} role="button"/>
                </div> : null }
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2" style={{ marginRight: '75px' }}>
                    <FontAwesomeIcon icon={faComment} className={"text-lg pointer text-grey"} onClick={openComments} tabIndex={0} role="button"/>
                </div>
                <FlipMove
                    typeName={null}
                    duration={200}
                    enterAnimation={CUSTOM_ENTER_ANIM}
                    leaveAnimation={CUSTOM_LEAVE_ANIM}>
                    {detailsOpen ? <div className="details-expand flex-row flex-align-center">
                        <div className="comment">'{match.kudos.comment}'</div>
                        {valueMatch ? <div className={"company-value-card box-shadow flex-row flex-align-center padding-1"}>
                            <img src={valueLookup[valueMatch.id]} className="margin-right-2" alt=""/>
                            <div className="flex flex-center-both flex-grow">
                                {valueMatch.welshName ? <p className="text-align-center margin-0 flex-grow bold">{valueMatch.welshName}</p> : null }
                                <p className="text-align-center margin-0 flex-grow bold">{valueMatch.name}</p>
                            </div>
                        </div>: null }
                    </div> : null }
                </FlipMove>
                {match ? <div className="expander pointer text-md flex flex-center-both flex-shrink-0" onClick={() => { setDetailsOpen(!detailsOpen); }} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={detailsOpen ? faTimes : faPlus } className="text-md text-alt"/>
                </div> : null }
            </div>
        )
    } else if(props.notification.type === 'award') {
        match = FeedStore.currentState.feed.find(a => a.award && (a.award.id === props.notification.itemId));

        return (
            <div className="notification-item flex-row flex-align-center padding-1" tabIndex={0} role="group">
                { badge ? <div className="pointer" onClick={onBadgeClick} tabIndex={0} role="button"><Badge badge={badge} imageClassName="profile-image-x-sm pointer margin-right-1 flex-shrink-0"/></div> : null }
                <div className="flex text-align-left flex-justify-center flex-grow">
                    <p className="margin-0 margin-bottom-1">{props.notification.title}</p>
                    {bodyContent}
                    <div className="flex-row margin-top-1">
                        <p className="time-since text-sub text-sm margin-0">{moment(props.notification.createdAt).calendar()}</p>
                    </div>
                </div>
                { userLiked !== null ? <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex={0} role="button"/>
                </div> : null }
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2" style={{ marginRight: '75px' }}>
                    <FontAwesomeIcon icon={faComment} className={"text-lg pointer text-grey"} onClick={openComments} tabIndex={0} role="button"/>
                </div>
                <FlipMove
                    typeName={null}
                    duration={200}
                    enterAnimation={CUSTOM_ENTER_ANIM}
                    leaveAnimation={CUSTOM_LEAVE_ANIM}>
                    {detailsOpen ? <div className="details-expand flex-row flex-align-center">
                        <div className="comment">'{match.award.comment}'</div>
                    </div> : null }
                </FlipMove>
                {match ? <div className="expander pointer text-md flex flex-center-both flex-shrink-0" onClick={() => { setDetailsOpen(!detailsOpen); }} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={detailsOpen ? faTimes : faPlus } className="text-md text-alt"/>
                </div> : null }
            </div>
        )
    } else if(props.notification.type === 'badge') {
        return (
            <div className="card notification-item flex-row flex-align-center padding-1" tabIndex={0} role="group">
                { employee ? <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-x-sm pointer margin-right-1 flex-shrink-0" onClick={() => onProfileClick(employee)} alt="" tabIndex={0} role="button"/> : null }
                <div className="pointer" onClick={onBadgeClick}><Badge badge={badge} imageClassName="profile-image-x-sm pointer margin-right-1 flex-shrink-0"/></div>
                <div className="flex text-align-left flex-justify-center flex-grow">
                    <p className="margin-0 margin-bottom-1">{props.notification.title}</p>
                    {bodyContent}
                    <div className="flex-row margin-top-1">
                        <p className="time-since text-sub text-sm margin-0">{moment(props.notification.createdAt).calendar()}</p>
                    </div>
                </div>
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2">
                    <FontAwesomeIcon icon={faEye} className={"text-lg pointer text-grey"} onClick={onBadgeClick} tabIndex={0} role="button"/>
                </div>
                { userLiked !== null ? <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex={0} role="button"/>
                </div> : null }
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2" style={{ marginRight: '75px' }}>
                    <FontAwesomeIcon icon={faComment} className={"text-lg pointer text-grey"} onClick={openComments} tabIndex={0} role="button"/>
                </div>
            </div>
        );
    }

    return (
        <div className="card notification-item flex-row flex-align-center padding-1" tabIndex={0} role="group">
            { employee ? <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-x-sm pointer margin-right-1 flex-shrink-0" onClick={() => onProfileClick(employee)} alt="" tabIndex={0} role="button"/> : null }
            { badge ? <div className="pointer" onClick={onBadgeClick}><Badge badge={badge} imageClassName="profile-image-x-sm pointer margin-right-1 flex-shrink-0"/></div> : null }
            <div className="flex text-align-left flex-justify-center flex-grow">
                <p className="margin-0 margin-bottom-1">{props.notification.title}</p>
                {bodyContent}
                <div className="flex-row margin-top-1">
                    <p className="time-since text-sub text-sm margin-0">{moment(props.notification.createdAt).calendar()}</p>
                    <p className="text-link text-sm margin-top-0 margin-bottom-0 flex-item-right" onClick={onReadMoreClick} tabIndex={0} role="button">{props.notification.type === 'badge' ? 'View badges' : 'Read more'}</p>
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;