import { Store } from "pullstate";

export const AccountStore = new Store({
    profile: {
        id : null,
        displayName : null,
        givenName : null,
        surname : null,
        mail : null,
        jobTitle : null,
        mobilePhone : null,
        officeLocation : null,
        preferredLanguage : null,
        businessPhones : null,
        image: null
    },
    badges: null,
    status: {},
    pendingTerms: [],
    pendingPosts: []
});