import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import { useStoreState } from "pullstate";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { ActionStore } from '../../Stores/ActionStore';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';

import { getProfile } from "../../Utilities/GraphAPI";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes, faHeart, faComment, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import APIFetch from "../../Utilities/APIFetch";
import FlipMove from 'react-flip-move';
import Badge from "../Badge/Badge";

import '../KudosItem/KudosItem.scss';
import { AccountStore } from "../../Stores/AccountStore";
import LikeList from "../LikeList/LikeList";
import Scrollbar from "react-scrollbars-custom";
import CommentsSection from "../CommentsSection/CommentsSection";

const CUSTOM_ENTER_ANIM = {
    from: { transform: 'scaleX(0)', transformOrigin: 'right center' },
    to: { transform: '', transformOrigin: 'right center' },
}

const CUSTOM_LEAVE_ANIM = {
    from: { transform: 'scaleX(1)', transformOrigin: 'right center' },
    to: { transform: 'scaleX(0)', transformOrigin: 'right center' },
}

const AwardItem = (props) => {
    const a = props.award;
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const employee = useStoreState(EmployeeStore, s => { return s[a.recipientId] || null });
    const role = useStoreState(AccountStore, s => s.status.role);
    const [liking, setLiking] = useState(false);
    const [showLikes, setShowLikes] = useState(false);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(!props.award.muted);
    const [menu, setMenu] = useState(false);
    const [muting, setMuting] = useState(false);
    const wrapperRef = useRef(null);

    if(employee === null) {
        getProfile(a.recipientId)
        .catch(e => {});
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if(menu && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, menu, setMenu]);

    const onProfileClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: employee }) });
    }

    const onBadgeClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Badges', data: employee }) });
    }

    const toggleNotifications = () => {
        if(!muting) {
            setMuting(true);
            setMenu(false);

            if(notificationsEnabled) {
                APIFetch("POST", `comment/mute/${a.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(false);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            } else {
                APIFetch("POST", `comment/unmute/${a.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(true);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            }
        }
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(a.userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${a.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(false);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${a.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(true);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const viewLikes = () => {
        if(a.likes > 0) {
            setShowLikes(!showLikes);
        }
    }

    const viewComments = () => {
        setShowComments(!showComments);
    }

    return (
        <div className="kudos-item margin-top-1 margin-top-first-0">
            <div className={"card flex-row flex-align-center card-alert padding-1 " + (props.className || '')} role="group" tabIndex="0">
                <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-sm pointer margin-1 flex-shrink-0" tabIndex="0" onClick={onProfileClick} alt=""/>
                <div className="flex text-align-left flex-shrink-1 margin-right-1">
                    <p className="margin-0 margin-left-2 bold"><span className="underline pointer" tabIndex="0" onClick={onProfileClick}>{employee ? (employee.id === myProfile.id ? 'You' : employee.displayName || "Someone" ) : "Someone"}</span> earned an award!</p>
                    {role === 'Admin' ? <p className="margin-0 text-sm text-sub margin-left-2 admin-coin-count"><span className="bold">{a.coins}</span> Coins Received</p> : null }
                    <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{moment(a.createdAt).calendar()}</p>
                </div>
                <div className="pointer" tabIndex="0" onClick={onBadgeClick}>
                    <Badge badge={{ title: a.awardDisplayName, name: a.awardName }} withTitle={true} className="flex-row flex-align-center" imageClassName="margin-left-2 mini-card"/>
                </div>
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (a.userLiked ? '' : ' text-grey')} tabIndex="0" onClick={onLike} aria-label="Like" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (a.likes > 0 ? " pointer underline bold" : "")} onClick={viewLikes} tabIndex="0" onBlur={() => setShowLikes(false)}>{a.likes}</p>
                    {showLikes ? <LikeList id={a.id} onClose={() => setShowLikes(false)} /> : null}
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={showComments ? faTimes : faComment} className={"text-lg pointer text-grey"} tabIndex="0" onClick={viewComments} aria-label="Comments" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (a.comments > 0 ? " pointer underline bold" : "")} onClick={viewComments} tabIndex="0">{a.comments}</p>
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 absolute" style={{ right: '34px' }}>
                    <FontAwesomeIcon icon={faEllipsisH} className={"text-lg pointer text-grey"} style={{ width: "30px" }} tabIndex="0" onClick={() => setMenu(!menu)} aria-label="Show post options" role="button"/>
                </div>
                {menu ? <div className="card-menu" tabIndex={0} onBlur={() => setMenu(false)} ref={wrapperRef}>
                            <div className="pointer bold" onClick={toggleNotifications}>
                                {notificationsEnabled ? "Turn off notifications for this post" : "Turn on notifications for this post"}
                            </div>
                        </div> : null }
                <FlipMove
                    typeName={null}
                    duration={200}
                    enterAnimation={CUSTOM_ENTER_ANIM}
                    leaveAnimation={CUSTOM_LEAVE_ANIM}>
                    { detailsOpen ? <div className="details-expand flex-row flex-align-center">
                            <Scrollbar className="flex-justify-center margin-right-4">
                                <p className="bold text-md margin-0">TfWRL said:</p>
                                <p className="text-md margin-0">‘{a.comment}’</p>
                            </Scrollbar>
                        </div> : null
                    }
                </FlipMove>
                <div className="expander pointer text-md flex flex-center-both flex-shrink-0" tabIndex="0" onClick={() => { setDetailsOpen(!detailsOpen); }}>
                    <FontAwesomeIcon icon={detailsOpen ? faTimes : faPlus } className="text-md text-alt"/>
                </div>
            </div>
            {showComments ? <CommentsSection activityId={a.id} employeeId={a.recipientId}/> : null }
        </div>
    );
};

export default AwardItem;