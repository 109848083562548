import React, { useEffect, useState } from "react";
import RichTextEditor from "react-rte";
import Scrollbar from "react-scrollbars-custom";
import appConfig from "../../config.json";
import './SocialPostModal.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const SocialPostModal = (props) => {
    const p = props.post;
    const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(p.body, 'markdown'));

    useEffect(() => {
        setEditorValue(RichTextEditor.createValueFromString(p.body, 'markdown'));
    }, [props.post, p.body])

    return (
        <div className="social-post-modal content full-height flex flex-grow flex-align-start">
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onClose} tabIndex="0" role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <Scrollbar scrollerProps={{tabIndex: 0}} className={(p && p.imagePath ? " with-image" : "")}>
                {p && p.imagePath ? <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + p.imagePath} alt=""/> : null }
                <div className="margin-left-2 margin-right-1 margin-top-1 text-align-left post-title">{p.title}</div>
                <div className="text-content margin-top-1 text-align-left padding-1">
                    <RichTextEditor
                        value={editorValue}
                        readOnly={true}
                        className="rte"
                        editorClassName="editor"
                    />
                </div>
            </Scrollbar>
        </div>
    );
};

export default SocialPostModal;