import { UserAgentApplication } from 'msal';
import appConfig from "../config.json";

// Configuration object constructed.
const config = {
  auth: {
      clientId: appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].CLIENT_ID,
      redirectUri: appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].APP_DOMAIN, //defaults to application start page
      postLogoutRedirectUri: appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].APP_DOMAIN,
      authority: `https://login.microsoftonline.com/${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].TENANT_ID}`,
      navigateToLoginRequestUrl: false 
  }
}

// create UserAgentApplication instance
const userAgentApplication = new UserAgentApplication(config);

const loginRequest = {
  authority: `https://login.microsoftonline.com/${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].TENANT_ID}`,
  scopes: [`api://${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].CLIENT_ID}/api_access`, "User.Read", "User.Read.All", "People.Read"]
}

const accessTokenRequest = {
  scopes: [`api://${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].CLIENT_ID}/api_access`]
}

const graphRequest = {
  authority: `https://login.microsoftonline.com/${appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].TENANT_ID}`,
  scopes: ["User.Read", "User.Read.All", "People.Read"]
}

export default class SessionManager {
    constructor(onReady, notLoggedIn) {
        this.onReady = onReady;

        if (userAgentApplication.getAccount()) {
          this.validateAuth(); // User logged in, get token silently
        } else {
          notLoggedIn();
        }
    }

    startSession() {
      userAgentApplication.loginRedirect(loginRequest);
    }

    endSession() {
      userAgentApplication.logout();
    }

    getToken() {
        return userAgentApplication.acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          return accessTokenResponse.accessToken;
        }).catch(function (error) {
            userAgentApplication.acquireTokenRedirect(accessTokenRequest);
        });
    }

    getGraphToken() {
      return userAgentApplication.acquireTokenSilent(graphRequest)
      .then((accessTokenResponse) => {
        return accessTokenResponse.accessToken;
      }).catch(function (error) {
        userAgentApplication.acquireTokenRedirect(graphRequest);
      });
    }

    validateAuth  = () => {
      userAgentApplication.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
        console.log('TOKEN: ' + accessTokenResponse.accessToken);
        this.onReady();
      }).catch(function (error) {
        userAgentApplication.acquireTokenRedirect(accessTokenRequest);
      });
    }
}