import React, { useEffect, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import APIFetch from '../../Utilities/APIFetch';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const UserActivityLine = (props) => {
    const [data, setData] = useState(null);
    const [startDate, setStartDate] = useState(moment(new Date()).startOf(props.period === 'daily' ? 'day' : 'month').subtract(props.period === 'daily' ? 28 : 12, props.period === 'daily' ? 'days' : 'months'));
    const [endDate, setEndDate] = useState(moment(new Date()).startOf(props.period === 'daily' ? 'day' : 'month'));
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectValue, setSelectValue] = useState("0");

    useEffect(() => {
        getData();
    }, []);

    const getData =(from, to) => {
        APIFetch('POST', `reporting/${props.endpoint}`, { from: from ? from.toISOString() : startDate.toISOString(), to: to ? to.toISOString() : endDate.toISOString(), period: props.period })
        .then((result) => {
            if(result && result.ok) {
                var newData = [];
                let date = moment(from || startDate);

                if(props.period === 'daily') {
                    while(date.isBefore(to || endDate)) {
                        var matchedData = result.data.find(d => {
                            var dataDate = new Date(d.date);
                            return dataDate.getDate() === date.date() && dataDate.getMonth() === date.month();
                        });

                        newData.push({
                            x: date.format('Do MMM'),
                            y: matchedData ? matchedData.count : 0
                        });

                        date.add(1, 'days');
                    }
                } else {
                    while(date.isBefore(to || endDate)) {
                        var matchedData = result.data.find(d => {
                            var dataDate = moment(d.date);
                            return dataDate.month() === date.month() && dataDate.year() === date.year();
                        });

                        newData.push({
                            x: moment(date).format('MMM YY'),
                            y: matchedData ? matchedData.count : 0
                        });

                        date.add(1, 'months');
                    }
                }

                setData([
                    {
                        id: 'User Activity',
                        color: "red",
                        data: newData
                    }
                ]);
            }
        })
        .catch(e => {
        });
    }

    const updateDates = (from, to) => {
        getData(from, to);
    }

    const updateSelect = (event) => {
        var value = event.target.value;

        setSelectValue(value);

        switch(value) {
            case "2":
                setStartDate(moment(new Date()).subtract(1, 'years').startOf('month'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'years'), moment(new Date()));
                break;
            case "3":
                setStartDate(moment(new Date()).subtract(1, 'months'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'months'), moment(new Date()));
                break;
            case "4":
                setStartDate(moment(new Date()).subtract(7, 'days'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(7, 'days'), moment(new Date()));
                break;
            default:
                break;
        }
    }

    if(!data) {
        return <div>Loading...</div>;
    }

    return <div className="full-width full-height">
        <div className="flex-row flex-align-center">
            <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => { setSelectValue("0"); setStartDate(startDate); setEndDate(endDate); updateDates(startDate, endDate); }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(newFocusedInput) => { setFocusedInput(newFocusedInput); }} // PropTypes.func.isRequired,
                isOutsideRange={(day) => {
                    if(day.startOf('day').isAfter(moment(new Date()).startOf('day'))) {
                        return true;
                    }

                    if(focusedInput == "endDate" && startDate) {
                        var dateDiff = startDate.startOf('day').diff(day.startOf('day'), 'days');

                        // Two months or 5 years
                        if(dateDiff > 0 || dateDiff < (props.period === 'daily' ? -62 : -1825)) {
                            return true;
                        }
                    }

                    return false;
                }}
                displayFormat="DD/MM/YYYY"
                minimumNights={1}
            />
            <div className="select-wrapper">
                <select className="select-css" onChange={updateSelect} value={selectValue}>
                    <option value="0">
                        Custom
                    </option>
                    {props.period === 'monthly' ? <option value="2">
                        Past Year
                    </option> : null }
                    {props.period === 'daily' ? <option value="3">
                        Past Month
                    </option> : null }
                    {props.period === 'daily' ? <option value="4">
                        Past Week
                    </option> : null }
                </select>
                <FontAwesomeIcon className="chevron" icon={faChevronDown} />
            </div>
        </div>
        <ResponsiveLine
            data={data}
            colors={['red']}
            margin={{ top: 25, right: 50, bottom: props.period === "monthly" ? 100 : 60, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{ type: 'linear', min: 0, max: 'auto', stacked: true, reverse: false }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -75,
                legend: props.period === "monthly" ? 'Month' : 'Date',
                legendOffset: props.period === "monthly" ? 80 : 55,
                legendPosition: 'middle'
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: props.yLabel || 'Active Users',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            pointSize={10}
            pointColor={'transparent'}
            pointBorderWidth={2}
            pointBorderColor={'red'}
            pointLabelYOffset={-12}
            useMesh={true}
        />
    </div>
}

export default UserActivityLine;