import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import { useStoreState } from "pullstate";
import React, { useState } from "react";
import Coins from '../../Assets/images/Coins.svg';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from '../../Stores/ActionStore';
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { RewardsStore } from "../../Stores/RewardsStore";
import APIFetch from "../../Utilities/APIFetch";
import { getProfile } from "../../Utilities/GraphAPI";
import LikeList from '../LikeList/LikeList';

const RedemptionItem = (props) => {
    const r = props.redemption;
    const giftCards = useStoreState(RewardsStore, s => s.rewards);
    const employee = useStoreState(EmployeeStore, s => { return s[r.userId] || null });
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const [liking, setLiking] = useState(false);
    const [showLikes, setShowLikes] = useState(false);
    var giftCard = null;
    var company = null;

    if(employee === null) {
        getProfile(r.userId)
        .catch(e => {});
    }

    const onProfileClick = (profile) => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(r.userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${r.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(false);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${r.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(true);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const viewLikes = () => {
        if(r.likes > 0) {
            setShowLikes(!showLikes);
        }
    }

    // Find specific card
    if(r && giftCards) {
        for(var a = 0; a < giftCards.length; a++) {
            for(var b = 0; b < giftCards[a].cards.length; b++) {
                if(giftCards[a].cards[b].id === r.cardId) {
                    giftCard = giftCards[a].cards[b];
                    company = giftCards[a].company;
                    break;
                }
            }
            if(giftCard) {
                break;
            }
        }
    }

    return (
        <div className="kudos-item margin-top-1 margin-top-first-0">
            <div className={"card flex-row flex-align-center padding-1 card-alert " + (props.className || '')} style={{ borderRadius: 20 }} role="group" tabIndex="0">
                <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employee)} alt="" tabIndex="0"/>
                <div className="flex text-align-left flex-shrink-1 margin-right-1">
                    <p className="margin-0 margin-left-2 bold"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employee)}>{employee ? (employee.id === myProfile.id ? 'You' : employee.displayName || "Someone") : "Someone"}</span> redeemed {giftCard ? giftCard.value * 100 : "some of their"} <img src={Coins} className="coins-icon" alt="coins"/> for a{giftCard ? (' £' + giftCard.value) : ''}{company ? ' ' + company : ''} gift card!</p>
                    <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{moment(r.createdAt).calendar()}</p>
                </div>
                <img className="margin-left-2 mini-card" src={giftCard ? giftCard.imageURL : null} alt={""} />
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (r.userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex="0" aria-label="Like" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (r.likes > 0 ? " pointer underline bold" : "")} onClick={viewLikes} tabIndex="0" onBlur={() => setShowLikes(false)}>{r.likes}</p>
                    {showLikes ? <LikeList id={r.id} onClose={() => setShowLikes(false)} /> : null}
                </div>
            </div>
        </div>
    );
};

export default RedemptionItem;