import React, { useEffect } from "react";
import './Social.scss';
import APIFetch from "../../Utilities/APIFetch";
import Scrollbar from 'react-scrollbars-custom';
import SocialPostItem from '../../Components/SocialPostItem/SocialPostItem';
import { useStoreState } from "pullstate";
import { FeedStore } from "../../Stores/FeedStore";
import { ActionStore } from "../../Stores/ActionStore";
import { AccountStore } from "../../Stores/AccountStore";

const Social = () => {
    const socialFeed = useStoreState(FeedStore, s => s.socialFeed);

    const getSocialFeed = () => {
        APIFetch('GET', 'social/posts?api-version=2.0')
        .then((result) => {
            if(result && result.ok) {
                FeedStore.update(s => { s.socialFeed = result.data });

                var pendingMandatory = result.data.filter(p => p.mandatory === true);
                AccountStore.update(s => { s.pendingPosts = pendingMandatory }); 
            } else {
                FeedStore.update(s => { s.socialFeed = null });
            }
        })
        .catch(e => {
            FeedStore.update(s => { s.socialFeed = null });
        });
    }

    useEffect(() => {
        getSocialFeed();
    }, []);

    return (
        <div className="activity social">
            <Scrollbar scrollerProps={{tabIndex: 0}} className="dash-scroll">
                <div className="social-feed" role="list" tabIndex="0">
                    { socialFeed && socialFeed.length === 0 ? <div>No activity yet!</div> : null }
                    { socialFeed ? socialFeed.map(p => {
                        return <SocialPostItem key={p.id} post={p} onReadMore={() => { ActionStore.update(s => { s.modals.push({ type: 'SocialPost', data: p }) }); }}/>;
                    }) : <div>Failed to retrieve social feed.</div>}
                </div>
            </Scrollbar>
        </div>
    );
};

export default Social;