import React from "react";
import './GiftCardItem.scss';

const GiftCardItem = React.forwardRef((props, ref) => {
    return (
        <div ref={ref} role="listitem">
            <div className={"gift-card-item box-shadow flex flex-center-both padding-top-1 padding-bottom-1 pointer" + (props.isSelected ? ' active' : '')} onClick={props.onSelected} tabIndex="0">
                <img src={props.giftCard.cards[0].imageURL} alt="" />
                <div className="text-align-center text-sub bold margin-top-1 margin-bottom-1">
                    {props.giftCard.company}
                </div>
            </div>
        </div>
    );
});

export default GiftCardItem;