import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import APIFetch from '../../Utilities/APIFetch';
import Scrollbar from 'react-scrollbars-custom';
import TermsOfUseItem from '../../Components/TermsOfUseItem/TermsOfUseItem';
import CreateTerms from '../CreateTerms/CreateTerms';

const TermsOfUse = (props) => {
    const [terms, setTerms] = useState(null);
    const [editing, setEditing] = useState(null);
    const [creating, setCreating] = useState(null);
    var termsItems = [];

    useEffect(() => {
        getTerms();
    }, []);

    const getTerms = () => {
        APIFetch('GET', `terms`)
        .then((result) => {
            if(result && result.ok) {
                setTerms(result.data);
            }
        })
        .catch(e => {
        });
    }

    if(terms && terms.length > 0) {
        for(let i = 0; i < terms.length; i++) {
            let t = terms[i];
            let onChange = (newValue) => {
                var newArray = [].concat(terms);
                if(newValue === null) {
                    newArray.splice(i, 1);
                } else {
                    newArray[i] = newValue;
                }
                setTerms(newArray);
            };

            termsItems.push(<TermsOfUseItem key={t.id} terms={t} onEdit={() => setEditing(t)} onChange={onChange}/>);
        }
    }

    if(editing || creating) {
        return <CreateTerms onComplete={() => { setCreating(false); setEditing(false); getTerms(); }} onCancel={() => { setCreating(false); setEditing(false); }} terms={editing || null} />;
    }

    return(
        <div className="flex full-height">
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onBack} tabIndex={0} role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <div className="flex-row flex-align-center padding-left-1 margin-bottom-2">
                <div>
                    <div className="text-grey text-lg bold">Usage Guidance</div>
                    <div className="text-grey light margin-bottom-3">Create and modify the app Usage Guidance</div>
                </div>
                <div style={{ marginRight: '35px' }} className="btn btn-positive flex-item-right" onClick={() => { setCreating(true) } } tabIndex={0} role="button">
                    Create Usage Guidance
                </div>
            </div>
            <Scrollbar className="flex flex-grow">
                {termsItems}
            </Scrollbar>
        </div>
    )
}

export default TermsOfUse;