import { EmployeeStore } from "../Stores/EmployeeStore";
import { SessionStore } from "../Stores/SessionStore";

var pendingProfiles = {};

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
};

export const getMyProfileImage = () => {
    var sessionManager = SessionStore.currentState.sessionManager;

    return sessionManager.getGraphToken()
    .then(token => {
        return fetch("https://graph.microsoft.com/v1.0/me/photo/$value", {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.arrayBuffer()
                .then(buffer => {
                    return arrayBufferToBase64(buffer);
                })
                .catch(e => {
                    return null;
                });
                
            } else {
                return null;
            }
        })
        .catch((e) => {
            return null;
        })
    })
    .catch(e => {
        return null;
    });
}

export const getMyProfile = () => {
    var sessionManager = SessionStore.currentState.sessionManager;

    return sessionManager.getGraphToken()
    .then(token => {
        return fetch("https://graph.microsoft.com/v1.0/me?$select=businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,department", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
                .then(result => {
                    return { ok: response.ok, status: response.status, data: result };
                })
                .catch(e => {
                    return e;
                });
                
            } else {
                return { ok: response.ok, status: response.status, data: response };
            }
        })
        .catch((e) => {
            return e;
        })
    })
    .catch(e => {
        return null;
    });
}

export const searchPeople = (searchTerm, myId) => {
    var sessionManager = SessionStore.currentState.sessionManager;

    return sessionManager.getGraphToken()
    .then(token => {
        return fetch(`https://graph.microsoft.com/v1.0/users?$count=true&$filter=(endsWith(userPrincipalName, '@tfwrail.wales') or endsWith(userPrincipalName, '@tfwr.wales') or endsWith(userPrincipalName, '@alt-labs.co.uk')) and userType eq 'Member' and accountEnabled eq true and (startswith(displayName,'${searchTerm}') or startswith(givenName,'${searchTerm}') or startswith(surname,'${searchTerm}') or startswith(mail,'${searchTerm}') or startswith(userPrincipalName,'${searchTerm}'))&$top=10`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'ConsistencyLevel': 'eventual'
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
                .then(result => {
                    var promises = [];
                    result.value = result.value.filter(v => v.id !== myId);
                    var fullData = [];

                    // If person or image is missing, retreive and update store
                    result.value.forEach(e => {
                        if(!EmployeeStore.currentState[e.id]) {
                            promises.push(getProfile(e.id)
                            .then(result => {
                                if(result.data) fullData.push(result.data);
                            }));
                        } else {
                            fullData.push(EmployeeStore.currentState[e.id]);
                        }
                    });

                    return Promise.all(promises)
                    .then(() => {
                        return { ok: response.ok, status: response.status, data: fullData };
                    })
                    .catch(e => {
                        return e;
                    });
                })
                .catch(e => {
                    return e;
                });
                
            } else {
                return { ok: response.ok, status: response.status, data: response };
            }
        })
        .catch((e) => {
            return e;
        })
    })
    .catch(e => {
        return null;
    });
}

// Get profile + image + cache
export const getProfile = (userId, getPhoto = true) => {
    var sessionManager = SessionStore.currentState.sessionManager;

    // Return existing data if available
    if(EmployeeStore.currentState[userId]) {
        return Promise.resolve({ ok: true, status: 200, data: EmployeeStore.currentState[userId] })
    }

    // Prevent multiple requests for the same profile
    if(pendingProfiles[userId]) {
        return pendingProfiles[userId];
    }

    pendingProfiles[userId] = sessionManager.getGraphToken()
    .then(token => {
        return fetch("https://graph.microsoft.com/v1.0/users/" + userId + "?&$select=businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,department", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
                .then(result => {
                    if(getPhoto) {
                        return getProfileImage(userId)
                        .then(image => {
                            result.image = image;
                            EmployeeStore.update(s => { s[userId] = result });
                            delete pendingProfiles[userId];
                            return { ok: response.ok, status: response.status, data: result };
                        });
                    } else {
                        result.image = null;
                        EmployeeStore.update(s => { s[userId] = result });
                        delete pendingProfiles[userId];
                        return { ok: response.ok, status: response.status, data: result };
                    }
                })
                .catch(e => {
                    return e;
                });
                
            } else {
                delete pendingProfiles[userId];
                EmployeeStore.update(s => { s[userId] = { id: userId } });
                return { ok: response.ok, status: response.status, data: response };
            }
        })
        .catch((e) => {
            return e;
        })
    })
    .catch(e => {
        return null;
    });

    return pendingProfiles[userId];
}

const getProfileImage = (userId) => {
    var sessionManager = SessionStore.currentState.sessionManager;

    return sessionManager.getGraphToken()
    .then(token => {
        return fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photos/120x120/$value`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.arrayBuffer()
                .then(buffer => {
                    return "data:image/png;base64, " + arrayBufferToBase64(buffer);
                })
                .catch(e => {
                    return null;
                });
                
            } else {
                return null;
            }
        })
        .catch((e) => {
            return null;
        })
    })
    .catch(e => {
        return null;
    });
}

export const getProfileBatch = (users) => {
    if(!users || users.length === 0) {
        return Promise.resolve();
    }

    var sessionManager = SessionStore.currentState.sessionManager;

    return sessionManager.getGraphToken()
    .then(token => {
        var requestBody = {
            requests: []
        };

        for(var i = 0; i < users.length; i++) {
            requestBody.requests.push({
                "id": users[i],
                "method": "GET",
                "url": "/users/" + users[i] + "?&$select=businessPhones,displayName,givenName,id,jobTitle,mail,mobilePhone,officeLocation,preferredLanguage,surname,userPrincipalName,department"
            });
        }

        return fetch(`https://graph.microsoft.com/v1.0/$batch`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
        .then((response) => {
            if(response.ok) {
                return response.json()
                .then(result => {
                    var d = result.responses;

                    for(var a = 0; a < d.length; a++) {
                        if(d[a].status === 200) {
                            EmployeeStore.update(s => { s[d[a].id] = d[a].body });
                        }
                    }

                    return null;
                })
                .catch(e => {
                    return null;
                });
            } else {
                return null;
            }
        });
    })
    .catch(e => {
        return null;
    });
}