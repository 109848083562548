import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import WalletItem from "../../Components/WalletItem/WalletItem";
import { RewardsStore } from "../../Stores/RewardsStore";
import APIFetch from "../../Utilities/APIFetch";
import './Wallet.scss';

const Wallet = (props) => {
    const giftCards = useStoreState(RewardsStore, s => s.rewards);
    const [walletItems, setWalletItems] = useState(null);
    const [failedRequest, setFailedRequest] = useState(false);
    var content = [];

    const getMyCards = () => {
        APIFetch('GET', 'rewards/mycards')
        .then((result) => {
            if(result && result.ok) {
                setWalletItems(result.data);
            } else {
                setFailedRequest(true);
            }
        })
        .catch(e => {
            setFailedRequest(true);
        });
    }

    useEffect(() => {
        getMyCards();
    }, []);

    if(walletItems && walletItems.length > 0 && giftCards) {
        content = <div className="wallet-list flex flex-grow">
            <div className="heading-row">
                <div className="image-space" />
                <div className="title-space" />
                <div className="heading-block">
                    <p>Date Redeemed</p>
                    <p>Valid Until</p>
                    <p>Status</p>
                </div>
            </div>
            <Scrollbar scrollerProps={{tabIndex: 0}}>
                {walletItems.map(wI => {
                    return <WalletItem key={wI.id} item={wI}/>;
                })}
            </Scrollbar>
        </div>
    }

    return (
        <div className="wallet-modal flex full-width">
            <div className="text-align-left light text-grey margin-bottom-2">
                Keep track of all your redemptions here.
            </div>
            <div className="content flex-row flex-grow full-height">
                {(!giftCards || !walletItems) && !failedRequest ? <LoadingOverlay /> : null }
                { failedRequest ? "Failed to retreive wallet contents." : null}
                { walletItems && walletItems.length === 0 ? "Nothing to display here yet!" : null}
                {content}
            </div>
        </div>
    );
};

export default Wallet;