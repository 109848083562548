import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/analytics";
import APIFetch from './APIFetch';
import { NotificationStore } from "../Stores/NotificationStore";
import { FeedStore } from "../Stores/FeedStore";
import { AccountStore } from "../Stores/AccountStore";

const firebaseConfig = {
    apiKey: "AIzaSyAFpLY1ge-Vz8SAgO40hwbwvcuRI-oob1Y",
    authDomain: "tfw-hub.firebaseapp.com",
    databaseURL: "https://tfw-hub.firebaseio.com",
    projectId: "tfw-hub",
    storageBucket: "tfw-hub.appspot.com",
    messagingSenderId: "852888128633",
    appId: "1:852888128633:web:13460bd5840a4aa4cb98a8",
    measurementId: "G-B44NE9VFP3"
};

firebase.initializeApp(firebaseConfig);

firebase.analytics();

var messaging = null;
if(firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
    messaging.usePublicVapidKey("BBoUzAe-8fwFFkHAXX0DdEImdXzGLvb5ugDY4E8HE03kn5vZCR_XDkgga3BkuMkfRey3vfk6ssHOwtVWakYSOeU");
}


// Prevent token being set twice on start
var setToken = null;

export const setUpNotifications = () => {
    loadNotifications();

    if(messaging) {
        messaging.getToken().then((currentToken) => {
            if (currentToken && setToken !== currentToken) {
                setToken = currentToken;
                APIFetch('POST', 'notifications', { newToken: currentToken })
                .catch(e => {
                    //TODO: Error
                    console.warn(e);
                })
            }
        }).catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
        });

        messaging.onTokenRefresh(() => {
            messaging.getToken().then((refreshedToken) => {
                if(setToken !== refreshedToken) {
                    setToken = refreshedToken;
                    APIFetch('POST', 'notifications', { newToken: refreshedToken })
                    .catch(e => {
                        //TODO: Error
                        console.warn(e);
                    });
                }
            }).catch((err) => {
                console.log('Unable to retrieve refreshed token ', err);
            });
        });

        // Use serviceWorker event listener instead of message to catch both foreground and background notifications
        navigator.serviceWorker.addEventListener('message', function (event) {
            if(event.data === 'onPushTfWHub') {
                NotificationStore.update(s => {
                    s.count += 1;
                });
                loadNotifications();
            }
        });
    }
}

export const loadNotifications = (optional = false) => {
    // Load historical notifications
    APIFetch('GET', 'notifications')
    .then((result) => {
        if(result && result.ok) {
            // Get new notifications and update appropriate data
            var oldNotifications = NotificationStore.currentState.notifications;

            if(oldNotifications) {
                var newNotifications = result.data;
                var diffNotifications = [];
                var gotStatus = false;

                newNotifications.forEach(n => {
                    var exists = oldNotifications.some(oN => oN.id === n.id);
                    if(!exists) {
                        diffNotifications.push(n);
                    }
                });

                if(optional && diffNotifications.length === 0) {
                    return null;
                }

                // Always update feed
                var date = new Date();
                APIFetch('GET', `feed?from=0&to=100&date=${date.toISOString()}`)
                .then((result) => {
                    if(result && result.ok) {
                        FeedStore.update(s => { s.feed = result.data.feed });
                    } else {
                        FeedStore.update(s => { s.feed = null });
                    }
                })
                .catch(e => {
                    FeedStore.update(s => { s.feed = null });
                });

                // Update badges on current user getting a badge
                if(AccountStore.currentState.profile && diffNotifications.some(n => (n.type === 'badge' || n.type === 'award') && n.userId === AccountStore.currentState.profile.id)) {
                    // Get badges
                    APIFetch('GET', 'badges')
                    .then((result) => {
                        if(result && result.ok) {
                            AccountStore.update(s => { s.badges = result.data });
                        }
                    })
                    .catch(e => {
                    });

                    // Update points incase points were awarded
                    APIFetch('GET', 'user/status/web')
                    .then((result) => {
                        if(result && result.ok) {
                            AccountStore.update(s => { s.status = result.data });
                        }
                    })
                    .catch(e => {
                    });

                    gotStatus = true;
                }

                // Update social posts on social post
                if(diffNotifications.some(n => n.type === 'socialPost')) {
                    // Get social posts
                    APIFetch('GET', 'social/posts?api-version=2.0')
                    .then((result) => {
                        if(result && result.ok) {
                            FeedStore.update(s => { s.socialFeed = result.data });

                            var pendingMandatory = result.data.filter(p => p.mandatory === true);

                            AccountStore.update(s => s.pendingPosts = pendingMandatory); 
                        } else {
                            FeedStore.update(s => { s.socialFeed = null });
                        }
                    })
                    .catch(e => {
                        FeedStore.update(s => { s.socialFeed = null });
                    });
                }

                // Update user points on receieving kudos with points
                if(!gotStatus && AccountStore.currentState.profile && diffNotifications.some(n => n.type === 'kudos' && n.userId === AccountStore.currentState.profile.id)) {
                    // If userId is current user and body contains '# coins'
                    APIFetch('GET', 'user/status/web')
                    .then((result) => {
                        if(result && result.ok) {
                            AccountStore.update(s => { s.status = result.data });
                        }
                    })
                    .catch(e => {
                    });
                }
            }

            NotificationStore.update(s => { s.notifications = result.data });
        } else {
            NotificationStore.update(s => { s.notifications = null });
        }
    })
    .catch(e => {
        NotificationStore.update(s => { s.notifications = null });
    });
}