import React from "react";
import Loader from "react-loader-spinner";
import './LoadingOverlay.scss';

const LoadingOverlay = (props) => {
    return (
        <div className="loading-overlay">
            <Loader
                type="Oval"
                color="#FF0000"
                height={props.height || 80}
                width={props.width || 80}
                className="loader"
            />
        </div>
    );
};

export default LoadingOverlay;