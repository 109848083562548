import React, { useState } from "react";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../SocialPostItemAdmin/SocialPostItemAdmin.scss';
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { faEye, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPencilAlt, faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import TermsOfUseModal from "../TermsOfUseModal/TermsOfUseModal";
import APIFetch from "../../Utilities/APIFetch";
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";

const TermsOfUseItem = (props) => {
    const t = props.terms;
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [viewing, setViewing] = useState(false);
    const [busy, setBusy] = useState(false);

    const onDelete = () => {
        if(!busy) {
            setBusy(true);
            APIFetch('DELETE', `terms/delete/${t.id}`)
            .then((result) => {
                if(result && result.ok) {
                    props.onChange(null);
                } else {
                    setConfirmDelete(false);
                    setErrorModal(true);
                }
                setBusy(false);
            })
            .catch(e => {
                setBusy(false);
                setConfirmDelete(false);
                setErrorModal(true);
            });
        }
    }

    const onToggle = () => {
        if(!busy) {
            setBusy(true);
            APIFetch('POST', `terms/toggle/${t.id}/${!t.active}`)
            .then((result) => {
                if(result && result.ok) {
                    var newTerms = Object.assign({}, t);
                    newTerms.active = !t.active;
                    props.onChange(newTerms);
                } else {
                    setErrorModal(true);
                }

                setBusy(false);
            })
            .catch(e => {
                setBusy(false);
                setErrorModal(true);
            });
        }
    }

    const onView = () => {
        setViewing(true);
    }

    const onEdit = () => {
        props.onEdit();
    }

    return (
        <div className="social-item-admin margin-top-1 margin-left-1 flex-row flex-center-both" role="group" tabIndex="0">
            {errorModal ? <div className="full-width full-height fixed top-left z-index-1"><DetailsPopOver onClose={() => { setErrorModal(false); }} title="Oops, something went wrong!" content={"An error occurred with your request, please try again later."}/></div> : null }
            {viewing ? <div className="full-width full-height fixed top-left z-index-1"><TermsOfUseModal pendingTerms={[t]} termsCallback={() => setViewing(false)} previewOnly={true}/></div> : null }
            { confirmDelete ? <ConfirmModal title={"Delete '" + t.title + "'?"} content={"Are you sure you wish to delete these terms? This action cannot be reversed!"} onClose={() => setConfirmDelete(false) } onConfirm={onDelete} fixedPosition={true}/> : null }
            <div className="title-text margin-right-1 text-align-left text-md flex-grow">{t.title}</div>
            <p className="date-text margin-0 light text-sm text-dark margin-left-2">{moment(t.updatedAt).format('DD/MM/YYYY (HH:mm)')}</p>
            <div className="flex-row flex-align-center">
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={onView} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faEye} className="text-md text-alert margin-right-1"/>
                    <p>View</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={onToggle} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={t.active ? faToggleOn : faToggleOff} className="text-md text-alert margin-right-1"/>
                    <p>{t.active ? "Active" : "Inactive"}</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={onEdit} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faPencilAlt} className="text-md text-alert margin-right-1"/>
                    <p>Edit</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={() => setConfirmDelete(true)} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faTrashAlt} className="text-md text-alert margin-right-1"/>
                    <p>Delete</p>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUseItem;