import React, { useEffect, useState } from "react";
import { useStoreState } from "pullstate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import APIFetch from "../../Utilities/APIFetch";
import Scrollbar from 'react-scrollbars-custom';
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from "../../Stores/ActionStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import './BadgeModal.scss';
import Badge from "../Badge/Badge";
import BadgeCard from "./BadgeCard";

const BadgeModal = (props) => {
    const details = props.profile;
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const myBadges = useStoreState(AccountStore, s => s.badges);
    const [badges, setBadges] = useState([]);
    var badgesRecent = badges.concat().sort((a, b) => b.createdAt - a.createdAt);
    var rarestRarity = badges && badges.length > 0 && badges[0].firstEarnedUser === badges[0].owner;
    var recentRarity = badgesRecent && badgesRecent.length > 0 && badgesRecent[0].firstEarnedUser === badgesRecent[0].owner;

    const onClose = () => {
        props.onClose();
    }

    const onProfileClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: details }) });
    }
    useEffect(() => {
        if(details.badges) {
            setBadges(details.badges);
        } else if(myId === details.id && myBadges) {
            setBadges(myBadges);
        } else {
            APIFetch('GET', `badges/${details.id}`)
            .then((result) => {
                if(result && result.ok) {
                    setBadges(result.data);
                }
            })
            .catch(e => {
            });
        }
    }, [details, myBadges, myId]);

    return (
        <div className="modal badge-modal">
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={onClose} tabIndex={0} role="button"/>
                    </div>
                </div>
                <div className="badge-header flex-row flex-align-center padding-4">
                    <img src={details && details.image ? details.image : defaultProfileImage} className="profile-image-lg pointer" onClick={onProfileClick} alt=""/>
                    <div className="flex flex-justify-center margin-left-2">
                        <p className="text-xlg bold margin-0 pointer" onClick={onProfileClick}>{details.displayName}</p>
                        <p className="text-md light margin-0 text-dark">{details.jobTitle}</p>
                    </div>
                    <div className="flex flex-center-both margin-left-4 stat-circle white-background box-shadow">
                        <p className="bold margin-0 value">{badges.length}</p>
                        <p className="title text-sm margin-0 text-dark">Badges</p>
                    </div>
                    {badges && badges.length > 0 ? <div className={"flex flex-center-both margin-left-4 stat-circle white-background box-shadow " + (rarestRarity ? 'legendary' : '')}>
                        <Badge badge={badges[0]} className="value"/>
                        <p className="title text-sm margin-0 text-dark">Rarest</p>
                    </div> : null }
                    {badges && badges.length > 0 ? <div className={"flex flex-center-both margin-left-4 stat-circle white-background box-shadow " + (recentRarity ? 'legendary' : '')}>
                        <Badge badge={badgesRecent[0]} className="value"/>
                        <p className="title text-sm margin-0 text-dark">Newest</p>
                    </div> : null }
                </div>
                <div className="content flex-row flex-grow">
                    <div className="scroll-background flex flex-grow modal-list">
                        <Scrollbar scrollerProps={{tabIndex: 0}}>
                            <div className="badge-grid">
                                {badgesRecent.map(b => <BadgeCard badge={b} key={b.name + b.createdAt}/>)}
                            </div>
                        </Scrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BadgeModal;