import { useStoreState } from "pullstate";
import React, { useEffect } from "react";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { getProfile } from "../../Utilities/GraphAPI";

const Tag = (props) => {
    const employee = useStoreState(EmployeeStore, s => { return s[props.id] || null });

    useEffect(() => {
        if(employee === null) {
            getProfile(props.id)
            .catch(e => {});
        }
    }, [props.id]);

    return <div className="bold pointer inline-block" onClick={props.onClick}>{employee ? employee.displayName || 'Someone' : 'Someone'}</div>;
};

export default Tag;