import React, { useEffect, useRef, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import Success from '../../Assets/images/Success.svg';
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import MultiEmployeeLookup from "../../Components/EmployeeLookup/MultiEmployeeLookup";
import LimitedTextArea from "../../Components/LimitedTextArea/LimitedTextArea";
import appConfig from "../../config.json";
import APIFetch from '../../Utilities/APIFetch';
import ConfirmAwardModal from "../../Components/ConfirmRecognitionModal/ConfirmAwardModal";
import './SendAward.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { AccountStore } from "../../Stores/AccountStore";
import { useStoreState } from "pullstate";
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import { Tooltip } from 'react-tippy';
import { EmployeeStore } from "../../Stores/EmployeeStore";

const SendAward = (props) => {
    const [sendingAward, setSendingAward] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [valueId, setValueId] = useState(null);
    const [awardTypes, setAwardTypes] = useState(null);
    const [confirmSpendModal, setConfirmSpendModal] = useState(false);
    const [confirmSendModal, setConfirmSendModal] = useState(false);
    const [amountValue, setAmountValue] = useState(null);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const sharePoints = useStoreState(AccountStore, s => s.status.sharePoints);
    const spendPoints = useStoreState(AccountStore, s => s.status.availablePoints);
    var content = null;

    // Form elements
    const employeeRef = useRef(null);
    const commentRef = useRef(null);

    useEffect(() => {
        APIFetch('GET', `awards`)
        .then((result) => {
            if(result && result.ok) {
                setAwardTypes(result.data);
            }
        })
        .catch(e => {
        });
    }, []);

    const onClose = () => {
        setSuccess(false);
    }

    const countLetters = (s) => {
        var count = 0;

        for(var i = 0; i < s.length; i++) {
            // If character has seperate upper and lowercase then it is a latin letter
            if(s[i].toLowerCase() != s[i].toUpperCase()) {
                count++;
            }
        }

        return count;
    }

    const sendAward = (recipient, coins, comment, awardId, confirmed, verified) => {
        if(!sendingAward) {
            if(!recipient || (!recipient.id && recipient.length < 1)) {
                setErrorText("Please select a colleague to send an award to.");
                setErrorModal(true);
                return null;
            }

            if(!awardId) {
                setErrorText("Please select an award type.");
                setErrorModal(true);
                return null;
            }

            if(coins < 0) {
                setErrorText("Awarded coins cannot be a negative value.");
                setErrorModal(true);
                return null;
            }

            if(!comment || comment.length === 0) {
                setErrorText("Please enter a comment.");
                setErrorModal(true);
                return null;
            }

            if(comment.length > 500) {
                setErrorText("Comment cannot exceed 500 characters in length.");
                setErrorModal(true);
                return null;
            }

            if(countLetters(comment) < 25) {
                setErrorText("Comment must contain a minimum of 25 letters (A-z).");
                setErrorModal(true);
                return null;
            }

            // Default to 0 else parse
            coins = coins ? parseInt(coins) : 0;

            if(isNaN(coins)) {
                setErrorText("Invalid coin value entered.");
                setErrorModal(true);
                return null;
            }

            if(coins < 0) {
                setErrorText("Awarded coins cannot be a negative value.");
                setErrorModal(true);
                return null;
            }

            if((coins * recipient.length) > sharePoints) {
                if(coins <= (sharePoints + spendPoints)) {
                    if(!confirmed) {
                        setConfirmSpendModal([recipient, coins, comment, awardId, true]);
                        return null;
                    }
                } else {
                    setErrorText("You do not have enough coins left to award this many coins.");
                    setErrorModal(true);
                    return null;
                }
            }

            if(!verified) {
                setConfirmSendModal([recipient, coins, comment, awardId, confirmed, true]);
                return null;
            }

            setSendingAward(true);

            var isBatch = recipient.length > 1;

            APIFetch('POST', isBatch ? `awards/batch` : `awards`, isBatch ? {
                recipientIds: recipient.map(r => r.id),
                coins: coins,
                comment: comment,
                awardId: awardId,
                confirmed: confirmed
            } : {
                recipientId: recipient[0].id,
                coins: coins,
                comment: comment,
                awardId: awardId,
                confirmed: confirmed
            })
            .then((result) => {
                if(result && result.ok) {
                    var failures = [];

                    Object.keys(result.data).forEach(k => {
                        if(result.data[k] === false) {
                            failures.push(k);
                        }
                    });

                    var finalAmount = isBatch ? coins * (recipient.length - failures.length) : coins;
                    var shareDeduction = finalAmount - sharePoints;

                    if(finalAmount > sharePoints) {
                        AccountStore.update(s =>  { s.status.sharePoints = 0 });
                    } else {
                        AccountStore.update(s =>  { s.status.sharePoints -= finalAmount });
                    }

                    if(shareDeduction > 0) {
                        AccountStore.update(s =>  { s.status.availablePoints -= shareDeduction });
                    }

                    if(failures.length > 0) {
                        var text = "Awards MAY have failed to send to the following people: ";
                        failures.forEach(f => {
                            text += EmployeeStore.currentState[f].displayName + ', ';
                        });
                        setErrorText(text.substring(0, text.length - 2));
                        setErrorModal(true);
                    }

                    setSendingAward(false);
                    setConfirmSendModal(false);
                    setSuccess(true);
                    setValueId(null);
                }  else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                } else {
                    setErrorModal(true);
                }

                setSendingAward(false);
                setConfirmSendModal(false);
            })
            .catch(e => {
                setErrorModal(true);
                setSendingAward(false);
                setConfirmSendModal(false);
            });
        }
    }

    if(success) {
        content = (
            <div className="content flex flex-grow min-height-0 padding-3">
                <div className="flex flex-center-both full-height flex-space-evenly padding-top-2">
                    <div className="redeem-medal">
                        <img src={Success} alt=""/>
                    </div>
                    <div className="flex flex-center-both">
                        <div className="text-align-center">
                            <p className="text-xxlg bold margin-bottom-1">Success!</p>
                            <p className="text-md text-grey">Way to go! Your award is on it's way to make someones day</p>
                        </div>
                        <div className="send-btn btn btn-positive margin-top-2" onClick={onClose} tabIndex={0} role="button">
                            Close
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        content = (
            <Scrollbar scrollerProps={{tabIndex: 0}}>
                <div className="content flex min-height-0 padding-3 padding-left-0 margin-right-4">
                    <div className="flex-row margin-top-2 margin-bottom-2">
                        <div className="employee-section flex-grow flex margin-right-1">
                            <label className="text-md margin-bottom-1">Who do you want to send an award to?</label>
                            <MultiEmployeeLookup className="margin-bottom-2" target={props.target} ref={employeeRef} onChange={(employees) => { setTotalEmployees(employees.length) }}/>
                        </div>
                        <div className="coin-section flex-grow flex margin-left-1">
                            <label htmlFor="coins" className="text-md margin-bottom-1">Do you want to send a coin reward? If yes please enter amount below</label>
                            <input id="coins" type="number" min="0" placeholder="Please enter amount of coins you wish to reward (optional)" className="margin-bottom-2" value={amountValue} onChange={e => setAmountValue(e.target.value)} />
                            {totalEmployees > 1 ? <div className="margin-left-1">Total Coins: {amountValue * totalEmployees}</div> : null}
                        </div>
                    </div>
                    <div className="flex margin-bottom-2">
                        <label htmlFor="comment" className="text-md margin-bottom-1">Include a publicly visible comment.</label>
                        <LimitedTextArea id="comment" placeholder="Comment" className="margin-bottom-2" ref={commentRef} maxLength={500}/>
                    </div>
                    <div className="flex">
                        <label htmlFor="company-value" className="text-md margin-bottom-1">Please select the award type from the list below:</label>
                        <div id="company-value" className="flex-row company-values-list">
                            {awardTypes ? awardTypes.map(v => {
                                if(v.description) {
                                    return <Tooltip
                                        html={(
                                            <div style={{ top: 10, width: 260 }}>
                                                <div className="tooltip-text">{v.description || "No Description"}</div>
                                            </div>
                                        )}
                                        position="top"
                                        className={"badge"}
                                        style={{ display: 'flex' }}
                                        key={v.id}>
                                        <div tabIndex={0} className={"company-value-card box-shadow flex-row flex-align-center pointer padding-1" + (valueId === v.id ? ' active' : '')} onClick={() => setValueId(v.id)}>
                                            <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + v.name + '.png'} className="margin-left-1 margin-right-1" style={{ width: 60, height: 60}} alt=""/>
                                            <p className="text-align-center margin-0 flex-grow bold">{v.displayName}</p>
                                        </div>
                                    </Tooltip>;
                                } else {
                                    return <div key={v.id} tabIndex={0} className={"company-value-card box-shadow flex-row flex-align-center pointer padding-1" + (valueId === v.id ? ' active' : '')} onClick={() => setValueId(v.id)}>
                                        <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + v.name + '.png'} className="margin-left-1 margin-right-1" style={{ width: 60, height: 60}} alt=""/>
                                        <p className="text-align-center margin-0 flex-grow bold">{v.displayName}</p>
                                    </div>;
                                }

                            }) : null}
                        </div>
                    </div>
                    <div className="flex-row flex-item-bottom margin-bottom-1 margin-top-4">
                        <div tabIndex={0} role="button" className="send-btn btn btn-positive bold" onClick={() => { sendAward(employeeRef.current.getSelected(), amountValue, commentRef.current.value, valueId) }}>
                            Send
                        </div>
                    </div>
                </div>
            </Scrollbar>
        )
    }

    return (
        <div className="flex full-width full-height recognition">
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onBack} tabIndex={0} role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <div className="text-grey text-lg bold">Grant Award</div>
            <div className="text-grey light">Send someone a special award.</div>
            {content}
            {confirmSendModal ? <ConfirmAwardModal awards={awardTypes} data={{ recipient: confirmSendModal[0], awardId: confirmSendModal[3], amount: confirmSendModal[1] }} onConfirm={() => { sendAward( ...confirmSendModal ); }} onClose={() => setConfirmSendModal(false) }/> : null }
            {confirmSpendModal ? <ConfirmModal title={"Use SPENDABLE coins?"} content={`You don't have enough share coins left to send this recognition.

Would you like to use ${(confirmSpendModal[1] * confirmSpendModal[0].length) - sharePoints} of your SPEND coins?`} onClose={() => setConfirmSpendModal(false) } onConfirm={() => { sendAward( ...confirmSpendModal ); setConfirmSpendModal(false); }} /> : null }
            {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
        </div>
    );
};

export default SendAward;