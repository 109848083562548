import { faBars, faBell, faBook, faBullseye, faGift, faHome, faMedal, faSearch, faSignOutAlt, faThumbsUp, faTimes, faUserCog, faWallet } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "@gatsbyjs/reach-router";
import { useStoreState } from "pullstate";
import React, { useState } from "react";
import FlipMove from 'react-flip-move';
import Coins from '../../Assets/images/Coins.svg';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import HubLogo from '../../Assets/images/HubLogoSmall.png';
import TfWLogoTall from '../../Assets/images/TfWLogoTall.svg';
import BadgeModal from "../../Components/BadgeModal/BadgeModal";
import ConfirmDetailsModal from "../../Components/ConfirmDetailsModal/ConfirmDetailsModal";
import EmployeeLookup from "../../Components/EmployeeLookup/EmployeeLookup";
import FullPost from "../../Components/FullPost/FullPost";
import MandatoryPostModal from "../../Components/MandatoryPostModal/MandatoryPostModal";
import NotificationModal from '../../Components/NotificationModal/NotificationModal';
import ProfileModal from '../../Components/ProfileModal/ProfileModal';
import SocialPostModal from "../../Components/SocialPostModal/SocialPostModal";
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from "../../Stores/ActionStore";
import { NotificationStore } from "../../Stores/NotificationStore";
import { SessionStore } from "../../Stores/SessionStore";
import APIFetch from "../../Utilities/APIFetch";
import Activity from "../Activity/Activity";
import Admin from "../Admin/Admin";
import Recognition from "../Recognition/Recognition";
import Milestones from "../Milestones/Milestones";
import Redeem from "../Redeem/Redeem";
import Social from "../Social/Social";
import Wallet from "../Wallet/Wallet";
import './Dashboard.scss';

const PAGES = [
    { 
        icon: faHome,
        title: 'Dashboard',
        content: Activity
    },
    { 
        icon: faBullseye,
        title: 'Milestones',
        content: Milestones
    },
    { 
        icon: faMedal,
        title: 'Recognition',
        content: Recognition
    },
    { 
        icon: faGift,
        title: 'Redeem',
        content: Redeem
    },
    { 
        icon: faThumbsUp,
        title: 'Social',
        content: Social
    },
    { 
        icon: faWallet,
        title: 'Wallet',
        content: Wallet
    },
    {
        icon: faBook,
        title: 'Tutorial',
        content: <div></div>,
        onClick: () => { navigate('tutorial'); }
    }
];

const ieWarning = window.navigator.userAgent.indexOf("MSIE ") > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);

const Dashboard = () => {
    const [currentPage, setCurrentPage] = useState(0);
    const [signingOut, setSigningOut] = useState(false);
    const [target, setTarget] = useState(null);
    const profileImage = useStoreState(AccountStore, s => { return s.profile ? s.profile.image : null });
    const userProfile = useStoreState(AccountStore, s => s.profile);
    const spendPoints = useStoreState(AccountStore, s => s.status.availablePoints);
    const sharePoints = useStoreState(AccountStore, s => s.status.sharePoints);
    const openModals = useStoreState(ActionStore, s => s.modals);
    const sessionManager = useStoreState(SessionStore, s => s.sessionManager);
    const [menuOpen, setMenuOpen] = useState(false);
    const role = useStoreState(AccountStore, s => s.status.role);
    const badgeCount = useStoreState(NotificationStore, s => s.count);
    const pendingPosts = useStoreState(AccountStore, s => s.pendingPosts);

    const PageComponent = PAGES[currentPage].content;

    // Details verification
    const [verificationSeen, setVerificationSeen] = useState(false);
    const detailsVerified = useStoreState(AccountStore, s => s.status.detailsVerified);

    var realModal = false;
    var hideBackground = false;
    var modal = null;
    var modalTitle = null;
    var pages = [];

    const openProfile = (user) => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: user }) });
    }

    const openMyProfile = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: userProfile }) });
    }

    const openNotifications = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Notifications' }) });
    }

    const openAdmin = () => {
        setMenuOpen(false);
        ActionStore.update(s => { s.modals = [{ type: 'Admin', key: Math.random() }] });
    }

    const signOut = () => {
        if(!signingOut) {
            setSigningOut(true);
            sessionManager.endSession();
        }
    }

    const acknowledgePost = () => {
        AccountStore.update(s => { s.pendingPosts = s.pendingPosts.slice(1, s.pendingPosts.length)});
        APIFetch('POST', 'social/acknowledge/' + pendingPosts[0].id); // Ignore success/failure, try next session if required
    }

    if(openModals && openModals.length > 0) {
        var m = openModals[openModals.length - 1];
        let index = openModals.length - 1;

        let onClose = () => {
            ActionStore.update(s => {
                var newArray = openModals.concat();
                newArray.splice(index, 1);
                s.modals = newArray;
            });
        };

        hideBackground = false;

        switch(m.type) {
            case 'SocialPost':
                modal = <SocialPostModal post={m.data} onClose={() => { onClose(); }}/>;
                modalTitle = "Social";
                realModal = false;
                break;
            case 'Profile':
                modal = <ProfileModal profile={m.data} onClose={onClose} sendRecognition={(details) => { setTarget(details); ActionStore.update(s => { s.modals = [] }); setCurrentPage(2); }}/>;
                modalTitle = "Profile";
                realModal = false;
                break;
            case 'Notifications':
                modal = <NotificationModal onClose={onClose}/>;
                modalTitle = "Your Notifications";
                realModal = false;
                break;
            case 'Badges':
                modal = <BadgeModal profile={m.data} onClose={onClose} />;
                modalTitle = "";
                realModal = true;
                hideBackground = true;
                break;
            case 'FullPost':
                modal = <FullPost postId={m.data.postId} commentId={m.data.commentId} onClose={onClose} />;
                modalTitle = "";
                realModal = true;
                hideBackground = true;
                break;
            case 'Admin':
                modal = <Admin key={m.key || 'Admin'}/>;
                modalTitle = "Admin";
                realModal = false;
                break;
            default:
                break;
        }
    }

    for(let i = 0; i < PAGES.length; i++) {
        if(PAGES[i].onClick) {
            pages.push(
                <div key={i} tabIndex={0} className={"flex-row flex-align-center action"} onClick={PAGES[i].onClick}>
                    <div className="action-icon-block"><FontAwesomeIcon icon={PAGES[i].icon} className="text-lg margin-right-2"/></div>
                    <p className="action-title">{PAGES[i].title}</p>
                </div>
            );
        } else {
            pages.push(
                <div key={i} tabIndex={0} aria-current={(!modal && currentPage === i ? "page" : false)} className={"flex-row flex-align-center action" + (!modal && currentPage === i ? " active" : "")} onClick={() => { ActionStore.update(s => { s.modals = [] }); setCurrentPage(i); setTarget(null); }}>
                    <div className="action-icon-block"><FontAwesomeIcon icon={PAGES[i].icon} className="text-lg margin-right-2"/></div>
                    <p className="action-title">{PAGES[i].title}</p>
                </div>
            );
        }
    }

    return (
        <div className="dashboard">
            { detailsVerified || verificationSeen ? null : <ConfirmDetailsModal onClose={() => setVerificationSeen(true) } onComplete={null}/>}
            { pendingPosts && pendingPosts.length > 0 ? <MandatoryPostModal post={pendingPosts[0]} onClose={acknowledgePost} /> : null }
            <nav role="navigation" className="nav-bar">
                <img className="hub-logo" src={HubLogo} alt=""/>
                <div className="action-bar">
                    <div className="actions">
                        {pages}
                    </div>
                </div>
                <div className="tfw-logo-block flex-row">
                    <img src={TfWLogoTall} alt=""/>
                    <p className="flex-item-right text-grey text-sm margin-right-3">v{process.env.REACT_APP_VERSION}{(process.env.REACT_APP_ENV || process.env.NODE_ENV || 'production') != 'production' ? ' BETA' : ''}</p>
                </div>
            </nav>
            <div className="dash-main">
                {realModal && modal ? modal : null}
                {ieWarning ? <div className="alert-bar browser-alert">
                    Internet Explorer is not supported - Cwtsh will not work correctly. Please use an up-to-date browser such as: Edge, Chrome or Firefox.
                </div> : null }
                <header role="banner" className={ieWarning ? "dash-header alert-margin" : "dash-header"}>
                    <h1>{modalTitle || PAGES[currentPage].title}</h1>
                    <div className="header-content">
                        <EmployeeLookup onSelected={openProfile} placeholder="Search" className="text-sm margin-right-1 flex-item-right"/>
                        <FontAwesomeIcon className="margin-right-2 search-icon" icon={faSearch} />
                        <div className="stats-pill">
                            <div className="stats-coins">
                                <img src={Coins} className="coins-icon" alt="coins"/>
                                <div>
                                    <p className="value">{sharePoints === null ? '-' : sharePoints }</p>
                                    <p className="type">Share</p>
                                </div>
                                <div>
                                    <p className="value">{spendPoints === null ? '-' : spendPoints}</p>
                                    <p className="type">Spend</p>
                                </div>
                            </div>
                            <div className="sub-pill" role="group">
                                <img tabIndex={0} aria-label="Open My Profile" src={profileImage || defaultProfileImage} className="profile-image-x-sm pointer" onClick={openMyProfile} alt="My Profile" role="button"/>
                                <div className="badged-icon">
                                    <FontAwesomeIcon aria-label="Show Notifications" tabIndex={0} className="pointer" icon={faBell} onClick={openNotifications} role="button"/>
                                    { badgeCount > 0 ? <div className="badge">
                                        <span>{badgeCount > 9 ? '9+' : badgeCount}</span>
                                    </div> : null }
                                </div>
                                <FontAwesomeIcon aria-label={menuOpen ? "Close Menu" : "Open Menu" } tabIndex={0} style={{ width: "17px" }} className="pointer" icon={menuOpen ? faTimes : faBars} onClick={() => { setMenuOpen(!menuOpen); }} role="button"/>
                                <FlipMove
                                    typeName={null}
                                    duration={100}
                                    enterAnimation="accordionVertical"
                                    leaveAnimation="accordionVertical">
                                        {menuOpen ? <div className="pill-menu" role="group">
                                            { role === 'Admin' || role === 'SeniorManager' || role === 'Reporting' || role === 'CustomerRelations' ? <div tabIndex={0} className="flex-row padding-top-1 padding-bottom-1 pointer" onClick={openAdmin} role="button">
                                                <FontAwesomeIcon className="margin-right-1" icon={faUserCog} />
                                                <p>Admin</p>
                                            </div> : null }
                                            <div tabIndex={0} className="flex-row padding-top-1 padding-bottom-1 pointer" onClick={signOut} role="button">
                                                <FontAwesomeIcon className="margin-right-1" icon={faSignOutAlt} />
                                                <p>Sign Out</p>
                                            </div>
                                        </div> : null }
                                </FlipMove>
                            </div>
                        </div>
                    </div>
                </header>
                <main role="main" className={!realModal && modal ? "dash-content hide-content" : "dash-content"}>
                    {hideBackground ? null : <PageComponent navigate={(p) => {  setCurrentPage(p); setTarget(null); }} target={target}/> }
                    { !realModal && modal ? modal : null }
                </main>
            </div>
        </div>
    );
};

export default Dashboard;