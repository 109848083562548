import React, { useState, useRef } from "react";
import { faChevronDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../DetailsPopOver/DetailsPopOver.scss';
import EmployeeLookup from "../EmployeeLookup/EmployeeLookup";

const ROLES = [
    "Blocked",
    "User",
    "Manager",
    "SeniorManager",
    "CustomerRelations",
    "Reporting",
    "Admin"
]

const RoleAssignmentModal = (props) => {
    const [selectedEmployee, setSelectedEmployee] = useState(props.edit.user || null);
    const [selectedRole, setSelectedRole] = useState(props.edit.roleName || "User");
    const employeeRef = useRef(null);

    const onDataChange = (e) => {
        setSelectedEmployee(e);
    }

    const updateSelect = (event) => {
        var value = event.target.value;

        setSelectedRole(value);
    }

    return (
        <div className={"modal confirm-modal popover-modal"}>
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={props.onClose} tabIndex={0} role="button"/>
                    </div>
                </div>
                <div className="details-popover">
                    <p className="text-xlg margin-top-1">{props.edit && props.edit.user ? "Change a role" : "Assign a role"}</p>
                    <p className="margin-bottom-1">Employee</p>
                    <EmployeeLookup className="margin-bottom-1" ref={employeeRef} onChange={onDataChange} target={selectedEmployee}/>
                    <p className="margin-bottom-1 margin-top-1">Role</p>
                    <div className="select-wrapper">
                        <select className="select-css" onChange={updateSelect} value={selectedRole}>
                            {ROLES.map(r => {
                                return <option value={r}>
                                    {r}
                                </option>
                            })}
                        </select>
                        <FontAwesomeIcon className="chevron" icon={faChevronDown} />
                    </div>
                    <div className="flex-row margin-top-3">
                        <div className="btn btn-alert margin-left-2 " onClick={() => { props.onConfirm(selectedEmployee, selectedRole) }} tabIndex={0} role="button">
                            <p className="bold margin-top-1 margin-bottom-1">{props.confirmText || "Confirm"}</p>
                        </div>
                        <div className="btn btn-negative margin-left-2" onClick={props.onClose} tabIndex={0} role="button">
                            <p className="bold margin-top-1 margin-bottom-1">{props.cancelText || "Cancel"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default RoleAssignmentModal;