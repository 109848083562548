import React from "react";
import Badge from "../Badge/Badge";
import moment from "moment";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { useStoreState } from "pullstate";
import { ActionStore } from "../../Stores/ActionStore";
import { AccountStore } from "../../Stores/AccountStore";
import { getProfile } from "../../Utilities/GraphAPI";

const BadgeCard = (props) => {
    const b = props.badge;
    var rarity = b && b.firstEarnedUser === b.owner && b.firstEarnedDate === b.createdAt ? ' legendary' : '';

    const matchedUser = useStoreState(EmployeeStore, s => {
        return b.firstEarnedUser ? s[b.firstEarnedUser] : null 
    });

    const myProfile = useStoreState(AccountStore, s => s.profile);

    const onProfileClick = () => {
        if(matchedUser) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: matchedUser }) });
        }
    }

    if(matchedUser == null && b.firstEarnedUser) {
        getProfile(b.firstEarnedUser)
        .catch(e => {});
    }

    return (
        <div className={"badge-card box-shadow flex-row " + rarity}>
            <Badge badge={b} size={128}/>
            <div className="flex">
                <div className={"text-lg text-alt margin-top-1 " + rarity}>{b.title}{ rarity ? <span className={"text-alt normal " + rarity}> - 1st</span> : null}</div>
                <div className="text-base light text-dark">Earned by <span className="normal">{b.percentageEarned.toFixed(2)}%</span> of users</div>
                <div className={"description margin-top-1 light text-alt margin-bottom-1 " + rarity}>{b.description}</div>
                <div className="text-sm light text-dark flex-item-bottom margin-bottom-1">{moment(b.createdAt).format('DD/MM/YYYY')} - First earned by <span className="underline pointer" onClick={onProfileClick} tabIndex={0} role="button">{matchedUser && matchedUser.displayName ? (matchedUser.id === myProfile.id ? 'You' : matchedUser.displayName || "Someone") : 'Someone'}</span> on {moment(b.firstEarnedDate).format('DD/MM/YYYY')}</div>
            </div>
        </div>
    );
};

export default BadgeCard;