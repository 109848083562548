import React, { useEffect, useRef, useState } from "react";
import APIFetch from "../../Utilities/APIFetch";
import { getProfile, searchPeople } from "../../Utilities/GraphAPI";
import { MentionsInput, Mention } from 'react-mentions';
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { useStoreState } from "pullstate";
import { AccountStore } from "../../Stores/AccountStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { ActionStore } from "../../Stores/ActionStore";
import moment from "moment";
import './Comment.scss';
import { faArrowAltCircleRight, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Tag from "./Tag";
import LikeList from "../LikeList/LikeList";
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";
import Loader from "react-loader-spinner";

const Comment = (props) => {
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const role = useStoreState(AccountStore, s => s.status.role);
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const [reply, setReply] = useState(`<@${props.comment.user}> `);
    const [replying, setReplying] = useState(false);
    const [editing, setEditing] = useState(false);
    const employee = useStoreState(EmployeeStore, s => { return s[props.comment.user] || null });
    const [comment, setComment] = useState('');
    const [updatedComment, setUpdatedComment] = useState('');
    const [replies, setReplies] = useState(props.comment ? props.comment.replies || [] : []);
    const [replacementComment, setReplacementComment] = useState(null);
    const [likedOverride, setLikedOverride] = useState(null);
    const [showLikes, setShowLikes] = useState(false);
    const [menu, setMenu] = useState(false);
    const [newEditing, setNewEditing] = useState(false);
    const [newReplying, setNewReplying] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [sending, setSending] = useState(false);
    const wrapperRef = useRef(null);
    const replyRef = useRef(null);

    const onProfileClick = (profile) => {
        if(profile) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
        }
    }

    const onTaggedClick = (id) => {
        var profile = EmployeeStore.currentState[id];

        if(profile) {
            onProfileClick(profile);
        }
    }

    const onDelete = (i) => {
        setReplies(replies.filter((ele, idx) => idx !== i));
    }

    const toggleMenu = () => {
        setMenu(!menu);
    }

    const moveCaretAtEnd = (e, resetFunc) => {
        resetFunc(false);
        var temp_value = e.target.value;
        e.target.value = '';
        e.target.value = temp_value;
    }

    const fetchUsers = (query, callback) => {
        if (!query) return
        searchPeople(query, myId)
        .then(result => {
            if(result.ok) {
                return result.data;
            } else {
                return [];
            }
        })
        .then(items => { return items.map(user => ({ display: user.displayName, id: user.id })) })
        .then(callback)
        .catch(e => {
           return [];
        });
    }

    const sendReply = () => {
        if(!sending && reply) {
            var trimmed = reply.trim();

            if(trimmed.length > 8000) {
                setErrorText('Your comment is over the 8,000 character limit. Please shorten your comment and try again.');
                setErrorModal(true);
                return;
            }

            if(trimmed.length === 0) {
                setErrorText('Your comment cannot be empty.');
                setErrorModal(true);
                return;
            }

            setSending(true);

            APIFetch('POST', 'comment/reply', { postId: props.activityId, comment: trimmed, commentId: props.parentComment ? props.parentComment.id : props.comment.id })
            .then(result => {
                setSending(false);
                if(result && result.ok) {
                    setReply(`<@${props.comment.user}> `);
                    setReplying(false);
                    setReplies([].concat(replies).concat([result.data]));
                } else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                } else {
                    setErrorText(null);
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorText(null);
                setErrorModal(true);
            });
        }
    }

    const startEditing = () => {
        setMenu(false);
        setUpdatedComment(replacementComment || props.comment.comment);
        setNewEditing(true);
        setEditing(true);
    }

    const toggleReplying = () => {
        if(replying) {
            setReplying(false);
        } else {
            if(props.parentComment && props.onReply) {
                // Nested reply, add tag and show at bottom of parent comment list
                props.onReply(`<@${props.comment.user}> `);
            } else {
                setNewReplying(true);
                setReplying(true);
                if(replyRef && replyRef.current) {
                    replyRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "center"
                    });
                }
            }
        }
    }

    const onReplyChild = (defaultText) => {
        setReply(defaultText);
        setNewReplying(true);
        setReplying(true);
        if(replyRef && replyRef.current) {
            replyRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center"
            });
        }
    }

    const editComment = () => {
        if(!sending && updatedComment && updatedComment) {
            var trimmed = updatedComment.trim();

            if(trimmed.length > 8000) {
                setErrorText('Your comment is over the 8,000 character limit. Please shorten your comment and try again.');
                setErrorModal(true);
                return;
            }

            if(trimmed.length === 0) {
                setErrorText('Your comment cannot be empty.');
                setErrorModal(true);
                return;
            }

            if(trimmed === (replacementComment || props.comment.comment)) {
                setEditing(false);
                return;
            }

            setSending(true);

            APIFetch('POST', 'comment/edit', { commentId: props.comment.id, comment: trimmed })
            .then(result => {
                setSending(false);
                if(result && result.ok) {
                    setReplacementComment(trimmed);
                    setUpdatedComment(trimmed);
                    setEditing(false);
                }  else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                } else {
                    setErrorText(null);
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorText(null);
                setErrorModal(true);
            });
        }
    }

    const deleteComment = () => {
        if(!sending) {
            setSending(true);
            setMenu(false);
            APIFetch('DELETE', `comment/${props.comment.id}`)
            .then(result => {
                setSending(false);
                if(result.ok) {
                    props.onDelete();
                } else {
                    setErrorText(null);
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorText(null);
                setErrorModal(true);
            });
        }
    }

    const toggleLike = () => {
        if(!sending) {
            var liked = likedOverride !== null ? likedOverride : props.comment.userLiked;
            setSending(true);

            APIFetch('POST', `comment/${liked ? 'unlike' : 'like'}/${props.comment.id}`)
            .then(result => {
                setSending(false);
                if(result.ok) {
                    setLikedOverride(!liked);
                } else {
                    setErrorText(null);
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorText(null);
                setErrorModal(true);
            });
        }
    }

    const renderUserSuggestion = (entry) => {
        var data = EmployeeStore.currentState[entry.id];
        return <div className="flex-row flex-align-center">
            <img src={data && data.image ? data.image : defaultProfileImage} className="profile-image-x-sm pointer margin-right-1 flex-shrink-0" alt="" />
            <div className="flex suggestion-text">
                <p>{entry.display}</p>
                <p className="light text-sm">{data && data.jobTitle ? data.jobTitle : ''}</p>
            </div>
        </div>
    }

    useEffect(() => {
        if(employee === null) {
            getProfile(props.comment.user)
            .catch(e => {});
        }
    }, [props.comment.user]);

    useEffect(() => {
        var splitComment = (replacementComment || props.comment.comment).split(/(<@[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}>)/);
        var sections = [];

        splitComment.forEach(c => {
            if(c.match(/(<@[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}>)/)) {
                let id = c.slice(2, 38);
                sections.push(<Tag key={id} id={id} onClick={() => onTaggedClick(id)} />);
            } else {
                sections.push(c);
            }
        });

        setComment(<div className="comment-text">
            {sections}
        </div>);
    }, [props.comment, replacementComment]);

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if(menu && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, menu, setMenu]);

    var replyItems = [];
    if(replies) {
        for(let i = 0; i < replies.length; i++) {
            let r = replies[i];
            replyItems.push(<Comment key={r.id} activityId={props.activityId} comment={r} parentComment={props.parentComment || props.comment} onDelete={() => onDelete(i)} onReply={onReplyChild} targetComment={props.targetComment}/>);
        }
    }

    var likeCount = props.comment.likes || 0;

    if(likedOverride !== null && likedOverride !== (props.comment.userLiked || false)) {
        if(likedOverride) {
            likeCount++;
        } else {
            likeCount--;
        }
    }

    var liked = likedOverride !== null ? likedOverride : (props.comment.userLiked || false)

    return (
        <div className={props.targetComment === props.comment.id ? "comment-item highlighted" : "comment-item"}>
            { errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            { editing ? <div className="add-comment flex-row">
                    <img src={myProfile && myProfile.image ? myProfile.image : defaultProfileImage} className="profile-image-x-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(myProfile)} tabIndex="0" alt=""/>
                    <MentionsInput
                        className="comment-input"
                        value={updatedComment}
                        onChange={e => { setUpdatedComment(e.target.value) }}
                        a11ySuggestionsListLabel={"Suggested colleagues for mention"}
                        autoFocus={true}
                        allowSpaceInQuery={true}
                        onFocus={newEditing ? (e) => { moveCaretAtEnd(e, setNewEditing) } : null }
                    >
                        <Mention
                        displayTransform={id => `@${EmployeeStore.currentState[id] ? EmployeeStore.currentState[id].displayName : 'Someone'}`}
                        trigger="@"
                        data={fetchUsers}
                        markup={'<@__id__>'}
                        renderSuggestion={renderUserSuggestion}
                        />
                    </MentionsInput>
                    { sending ? <Loader
                        type="Oval"
                        color="#FF0000"
                        height={30}
                        width={30}
                        className="flex flex-center-both margin-left-1"
                    /> : <FontAwesomeIcon icon={faArrowAltCircleRight} onClick={editComment} className="send-icon" alt="Send Comment"/> }
            </div> : 
            <div className="flex">
                <div className="flex-row flex-center-both">
                    <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-x-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employee)} tabIndex="0" alt=""/>
                    <div className="flex-row comment relative">
                        <div className="flex flex-grow">
                            <div className="bold pointer" onClick={() => onProfileClick(employee)}>{employee ? employee.displayName : 'Someone'}</div>
                            {comment}
                        </div>
                        { myId === props.comment.user || role === 'Admin' ? <FontAwesomeIcon style={{ alignSelf: 'center' }} className={"menu-icon"} icon={faEllipsisH} onClick={toggleMenu} alt="Comment Options" /> : null }
                        {menu ? <div className="card-menu" tabIndex={0} onBlur={() => setMenu(false)} ref={wrapperRef}>
                            <div className="pointer bold" onClick={deleteComment}>
                                Delete
                            </div>
                            { myId === props.comment.user ? 
                                <div className="pointer bold" onClick={startEditing}>
                                    Edit
                                </div>
                            : null }
                        </div> : null}
                    </div>
                </div>
                <div className="flex-row action-row relative">
                    <div className="pointer bold" onClick={toggleLike}>{liked ? 'Unlike' : 'Like'}</div>
                    <div className="pointer bold" onClick={likeCount > 0 ? () => { setShowLikes(!showLikes) } : null}>{likeCount === 1 ? '1 Like' : `${likeCount} Likes`}</div>
                    {showLikes ? <LikeList id={props.comment.id} onClose={() => setShowLikes(false)} endPoint={`comment/${props.comment.id}/likes`}/> : null}
                    <div className="pointer bold" onClick={toggleReplying}>Reply</div>
                    <div>{moment(props.comment.createdAt).fromNow()}{props.comment.createdAt !== props.comment.updatedAt ? '*' : ''}</div>
                </div> 
            </div> }
            <div className={(props.parentComment ? "" : " padding-left-3")}>
                {replyItems}
            </div>
            {replying ? <div className={"add-comment flex-row" + (props.parentComment ? "" : " padding-left-3")} ref={replyRef}>
                <img src={myProfile && myProfile.image ? myProfile.image : defaultProfileImage} className="profile-image-x-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(myProfile)} tabIndex="0" alt=""/>
                <MentionsInput
                    className="comment-input"
                    value={reply}
                    onChange={e => { setReply(e.target.value) }}
                    a11ySuggestionsListLabel={"Suggested colleagues for mention"}
                    autoFocus={true}
                    allowSpaceInQuery={true}
                    onFocus={newReplying ? (e) => { moveCaretAtEnd(e, setNewReplying) } : null }
                >
                    <Mention
                    displayTransform={id => `@${EmployeeStore.currentState[id] ? EmployeeStore.currentState[id].displayName : 'Someone'}`}
                    trigger="@"
                    data={fetchUsers}
                    markup={'<@__id__>'}
                    renderSuggestion={renderUserSuggestion}
                    />
                </MentionsInput>
                { sending ? <Loader
                        type="Oval"
                        color="#FF0000"
                        height={30}
                        width={30}
                        className="flex flex-center-both margin-left-1"
                    /> : <FontAwesomeIcon icon={faArrowAltCircleRight} onClick={sendReply} className="send-icon" alt="Send Comment"/> }
            </div> : null }
        </div>
    );
};

export default Comment;