import { useStoreState } from "pullstate";
import React from "react";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { ActionStore } from "../../Stores/ActionStore";
import { getProfile } from "../../Utilities/GraphAPI";

const LikeItem = (props) => {
    const employee = useStoreState(EmployeeStore, s => { return s[props.value] || null });

    const onProfileClick = () => {
        if(employee) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: employee }) });
        }
    }

    if(employee === null) {
        getProfile(props.value)
        .catch(e => {});
    }

    return (
        <div className="like-item pointer" onMouseDown={onProfileClick}>
            <img src={employee ? (employee.image || defaultProfileImage) : defaultProfileImage} className="profile-image-x-sm margin-1" alt=""/>
            <div>{employee && employee.displayName ? employee.displayName : "Someone"}</div>
        </div>
    );
};

export default LikeItem;