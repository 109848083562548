import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import FlipMove from 'react-flip-move';
import Scrollbar from "react-scrollbars-custom";
import Success from '../../Assets/images/Success.svg';
import CategoryItem from "../../Components/GiftCardItem/CategoryItem";
import GiftCardItem from "../../Components/GiftCardItem/GiftCardItem";
import LoadingOverlay from "../../Components/LoadingOverlay/LoadingOverlay";
import { RewardsStore } from '../../Stores/RewardsStore';
import './Redeem.scss';
import SelectedItem from "./SelectedItem";

const Redeem = (props) => {
    const giftCards = useStoreState(RewardsStore, s => s.rewards);
    const [currentCategory, setCurrentCategory] = useState(null);
    const [currentCard, setCurrentCard] = useState(null);
    const [complete, setComplete] = useState(false);

    var categories = [];
    var cards = [];
    var cardDetails = null;

    const onCardSelected = (card) => {
        setCurrentCard(card);
    }

    const onComplete = (card) => {
        setComplete(card);
    }

    const onWallet = () => {
        props.navigate(5);
    }

    useEffect(() => {
        if(currentCard && complete) {
            setComplete(false);
        }
    }, [currentCard])

    if(giftCards && giftCards.length > 0) {
        var added = {};

        giftCards.forEach(card => {
            if(added[card.category]) {
                added[card.category] += 1;
            } else {
                categories.push(<CategoryItem key={card.category} category={card.category} onSelected={() => setCurrentCategory(card.category)} isSelected={card.category === currentCategory}/>);
                added[card.category] = 1;
            }
        });

        if(currentCategory) {
            var items = giftCards.filter(gC => gC.category === currentCategory);
            
            cards = items.map(i => {
                return <GiftCardItem key={i.company} giftCard={i} onSelected={() => onCardSelected(i) } isSelected={currentCard === i}/>
            });
        } else {
            cards = giftCards.map(i => {
                return <GiftCardItem key={i.company} giftCard={i} onSelected={() => onCardSelected(i) } isSelected={currentCard === i}/>
            });
        }

        if(complete) {
            cardDetails = <div className="selected-item box-shadow margin-left-1 margin-bottom-2 relative flex-row flex-align-center">
                    <div className="redeem-medal">
                        <img src={Success} alt=""/>
                    </div>
                    <div className="flex-grow success">
                        <div className="text-align-center margin-bottom-1">
                            <p className="text-xlg bold margin-bottom-2 margin-top-2">Success!</p>
                            <p className="text-md text-grey">Congratulations, your gift card is now available in your wallet!</p>
                        </div>
                        <div className="flex-row">
                            <div className="send-btn btn btn-positive margin-right-1" onClick={onWallet}>
                                Take me to my wallet
                            </div>
                            <div className="send-btn btn btn-negative margin-left-1" onClick={() => { setCurrentCard(null); setComplete(null); }}>
                                Close
                            </div>
                        </div>
                    </div>
                </div>;
        } else if(currentCard) {
            cardDetails = <FlipMove
                typeName={null}
                duration={200}
                appearAnimation="accordionVertical"
                enterAnimation="none"
                leaveAnimation="none">
                <SelectedItem key={currentCard.company} giftCard={currentCard} onComplete={onComplete} onCancel={() => { setCurrentCard(null); }}/>
            </FlipMove>
        }
    }

    return (
        <div className="redeem flex full-width">
            <div className="text-align-left light text-grey margin-bottom-2">
                Exchange your hard earned coins for well deserved prizes.
            </div>
            <div className="flex-row flex-grow flex-align-start flex-space-evenly min-height-0 full-height">
                {!giftCards ? <LoadingOverlay /> : null }
                <div className="margin-right-2 margin-top-1" role="group" tabIndex="0">
                    {categories}
                </div>
                <div className="flex-grow full-height min-width-0 flex relative">
                    {cardDetails}
                    <Scrollbar>
                        <div className="reward-grid relative" role="list" tabIndex="0">
                            <FlipMove 
                                typeName={null}
                                staggerDurationBy={20}
                                appearAnimation="elevator">
                                {cards}
                            </FlipMove>
                        </div>
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
};

export default Redeem;