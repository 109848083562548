import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import APIFetch from "../../Utilities/APIFetch";
import Comment from "./Comment";
import { ActionStore } from "../../Stores/ActionStore";
import { useStoreState } from "pullstate";
import { AccountStore } from "../../Stores/AccountStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { searchPeople } from "../../Utilities/GraphAPI";
import { Mention, MentionsInput } from "react-mentions";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";
import Loader from "react-loader-spinner";

const CommentsSection = (props) => {
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const [comments, setComments] = useState(null);
    const [userComment, setUserComment] = useState("");
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [sending, setSending] = useState(false);

    const onProfileClick = (profile) => {
        if(profile) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
        }
    }

    const onDelete = (i) => {
        // Delete comment
        setComments(comments.filter((ele, idx) => idx !== i));
    }

    const fetchUsers = (query, callback) => {
        if (!query) return
        searchPeople(query, myProfile.id)
        .then(result => {
            if(result.ok) {
                return result.data;
            } else {
                return [];
            }
        })
        .then(items => { return items.map(user => ({ display: user.displayName, id: user.id })) })
        .then(callback)
        .catch(e => {
           return [];
        });
    }

    useEffect(() => {
        APIFetch('GET', `comment/${props.activityId}`)
        .then(result => {
            if(result && result.ok) {
                setComments(result.data);
            } else {
                setErrorText("An error occurred when attempting to load comments.");
                setErrorModal(true);
            }
        })
        .catch(e => {
            setErrorText("An error occurred when attempting to load comments.");
            setErrorModal(true);
        });
    }, [props.activityId]);

    const sendMessage = () => {
        if(!sending && userComment) {
            var trimmed = userComment.trim();

            if(trimmed.length > 8000) {
                setErrorText('Your comment is over the 8,000 character limit. Please shorten your comment and try again.');
                setErrorModal(true);
                return;
            }

            if(trimmed.length === 0) {
                setErrorText('Your comment cannot be empty.');
                setErrorModal(true);
                return;
            }

            setSending(true);

            APIFetch('POST', 'comment', { postId: props.activityId, comment: trimmed })
            .then((result) => {
                setSending(false);
                if(result && result.ok) {
                    setUserComment("");
                    setComments([].concat(comments).concat(result.data));
                } else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                } else {
                    setErrorText(null);
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setSending(false);
                setErrorText(null);
                setErrorModal(true);
            });
        }
    }

    const renderUserSuggestion = (entry) => {
        var data = EmployeeStore.currentState[entry.id];
        return <div className="flex-row flex-align-center">
            <img src={data && data.image ? data.image : defaultProfileImage} className="profile-image-x-sm pointer margin-right-1 flex-shrink-0" alt="" />
            <div className="flex suggestion-text">
                <p>{entry.display}</p>
                <p className="light text-sm">{data && data.jobTitle ? data.jobTitle : ''}</p>
            </div>
        </div>
    }

    var commentItems = [];

    if(!comments) {
        // Not loaded or failed to load
        commentItems = <LoadingOverlay width={40} height={40} />;
    } else if(comments.length === 0) {
        // No comments yet
    } else {
        for(let i = 0; i < comments.length; i++) {
            var c = comments[i];
            commentItems.push(<Comment key={c.id} comment={c} activityId={props.activityId} onDelete={() => onDelete(i)} targetComment={props.targetComment}/>);
        }
    }

    return (
        <div className="comment-section">
            { errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            <div className="relative">
                <div className="edit-comment flex-row">
                    <img src={myProfile && myProfile.image ? myProfile.image : defaultProfileImage} className="profile-image-x-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(myProfile)} tabIndex="0" alt=""/>
                    <MentionsInput
                        className="comment-input"
                        value={userComment}
                        onChange={e => { setUserComment(e.target.value)}}
                        a11ySuggestionsListLabel={"Suggested colleagues for mention"}
                        placeholder={'Write a comment...'}
                        allowSpaceInQuery={true}
                    >
                        <Mention
                        displayTransform={id => `@${EmployeeStore.currentState[id] ? EmployeeStore.currentState[id].displayName : 'Someone'}`}
                        trigger="@"
                        data={fetchUsers}
                        markup={'<@__id__>'}
                        renderSuggestion={renderUserSuggestion}
                        />
                    </MentionsInput>
                    { sending ? <Loader
                        type="Oval"
                        color="#FF0000"
                        height={30}
                        width={30}
                        className="flex flex-center-both margin-left-1"
                    /> : <FontAwesomeIcon icon={faArrowAltCircleRight} onClick={sendMessage} className="send-icon" alt="Send Comment"/> }
                </div>
                <div>
                    {commentItems}
                </div>
            </div>
        </div>
    );
};

export default CommentsSection;