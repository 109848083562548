import React, { useState } from "react";
import moment from 'moment';
import appConfig from "../../config.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as faHeartRegular } from '@fortawesome/free-regular-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import APIFetch from "../../Utilities/APIFetch";
import { FeedStore } from "../../Stores/FeedStore";
import './SocialPostItem.scss';
import RichTextEditor from "react-rte";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const SocialPostItem = (props) => {
    const p = props.post;
    const [liking, setLiking] = useState(false);
    const editorValue = RichTextEditor.createValueFromString(p.body, 'markdown');
    const [confirmDelete, setConfirmDelete] = useState(false);

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(p.userLiked) {
                // Unlike
                APIFetch("POST", `social/posts/unlike/${p.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        FeedStore.update(s => { 
                            var match = s.socialFeed.find(a => a.id === p.id);
                            if(match) {
                                match.likes -= 1;
                                match.userLiked = false;
                            }
                        });
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/posts/like/${p.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        FeedStore.update(s => { 
                            var match = s.socialFeed.find(a => a.id === p.id);
                            if(match) {
                                match.likes += 1;
                                match.userLiked = true;
                            }
                        });
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const onDelete = () => {
        setConfirmDelete(false);
        props.onDelete();
    }

    return (
        <div className="social-item margin-top-1 padding-1 flex" role="listitem">
            { confirmDelete ? <ConfirmModal title={"Delete '" + p.title + "'?"} content={"Are you sure you wish to delete this post? This action cannot be reversed!"} onClose={() => setConfirmDelete(false) } onConfirm={onDelete} fixedPosition={true}/> : null }
            { p.imagePath ? <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + p.imagePath} alt=""/> : null }
            <p className="date-text margin-0 margin-top-1 light text-sm text-dark margin-left-2">{moment(p.createdAt).format("Do MMMM yyyy")}</p>
            <div className="margin-left-2 margin-right-1 margin-top-1 text-align-left post-title">{p.title}</div>
            <div className="margin-left-1 margin-right-1 text-align-left flex flex-grow">
                <RichTextEditor
                    value={editorValue}
                    readOnly={true}
                    className={"rte"}
                    editorClassName="editor"
                />
            </div>
            <div className="flex-row flex-align-center margin-1">
                <div className="btn btn-positive padding-left-3 padding-right-3" onClick={props.onReadMore} role="button" tabIndex="0">
                    <p className="bold margin-top-0 margin-bottom-0">Read More</p>
                </div>
                { props.adminView ? <div className="btn btn-negative margin-left-1 " onClick={() => setConfirmDelete(true)} role="button" tabIndex="0">
                        <FontAwesomeIcon icon={faTrash} className="text-md text-alert"/>
                    </div> : <div className="flex-row flex-align-center flex-item-right">
                    <p className="text-sm margin-left-2 text-sub">{p.likes === 1 ? "1 Like" : `${p.likes} Likes`}</p>
                    <div className={"pointer text-md profile-image-x-sm flex flex-center-both margin-left-2 flex-shrink-0" + (p.userLiked ? " primary-background" : " negative-background")} onClick={onLike} role="button" tabIndex="0">
                        <FontAwesomeIcon icon={faHeartRegular} className="text-md text-alt"/>
                    </div>
                </div> }
            </div>
        </div>
    );
};

export default SocialPostItem;