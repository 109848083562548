import { useStoreState } from "pullstate";
import { useEffect, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import Badge from "../../Components/Badge/Badge";
import MilestoneProgress from "../../Components/MilestoneProgress/MilestoneProgress";
import MilestoneStep from "../../Components/MilestoneProgress/MilestoneStep";
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from "../../Stores/ActionStore";
import APIFetch from "../../Utilities/APIFetch";
import './Milestones.scss';

const Milestones = () => {
    const [milestones, setMilestones] = useState();
    const myBadges = useStoreState(AccountStore, s => s.badges);
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const gotStarted = milestones && milestones.gettingStarted && milestones.gettingStarted.recognitionReceived && milestones.gettingStarted.recognitionSent && milestones.gettingStarted.commentMade && milestones.gettingStarted.likeMade && milestones.gettingStarted.likeReceived;

    useEffect(() => {
        APIFetch('GET', 'badges/milestones')
        .then((result) => {
            if(result && result.ok) {
                setMilestones(result.data);
            }
        })
        .catch(e => {
        })
    }, []);

    const openBadges = () => {
        var badgeDetails = Object.assign({}, myProfile);
        badgeDetails.badges = myBadges;

        ActionStore.update(s => { s.modals.push({ type: 'Badges', data: badgeDetails })  });
    }
    
    return <div className="milestones">
        <div className="flex-row flex-space-between">
            <h1>{new Date().getFullYear()} Progress</h1>
            <div className="flex flex-justify-center">
                <div className="flex-row flex-align-center">
                    {myBadges.filter(b => new Date(b.createdAt) >= new Date(new Date().getFullYear(), 0, 1)).slice(0, 6).map(b => <Badge badge={b} key={b.name + b.createdAt}/>)}
                    { myBadges && myBadges.length > 0 ? <div className="btn btn-positive flex flex-center-both margin-left-2" onClick={openBadges} tabIndex={0} role="button">
                        View All Badges
                    </div> : null }
                </div>
            </div>
        </div>
        <Scrollbar>
            <div className="flex-row">
                {!gotStarted && milestones && milestones.gettingStarted ? <div className="getting-started-card card padding-1 margin-1">
                    <div className="flex-row padding-left-2 margin-bottom-1">
                        <Badge badge={{ name: 'GettingStarted', title: 'Getting Started' }} />
                        <h2 className="margin-left-1 margin-right-3">Getting Started Checklist</h2>
                    </div>
                    <div className="margin-left-3 margin-right-3 margin-bottom-2 text-grey-2">Complete each of the following activities to unlock the <strong className="no-wrap">'Getting Started'</strong> badge.</div>
                    <div className="getting-started-grid">
                        <MilestoneStep milestoneStep={{ title: 'Send Recognition', target: 1, value: milestones.gettingStarted.recognitionReceived ? 1 : 0 }} />
                        <MilestoneStep milestoneStep={{ title: 'Receive Recognition', target: 1, value: milestones.gettingStarted.recognitionSent ? 1 : 0 }} />
                        <MilestoneStep milestoneStep={{ title: 'Make A Comment', target: 1, value: milestones.gettingStarted.commentMade ? 1 : 0 }} />
                        <MilestoneStep milestoneStep={{ title: 'Give A Like', target: 1, value: milestones.gettingStarted.likeMade ? 1 : 0 }} />
                        <MilestoneStep milestoneStep={{ title: 'Receive A Like', target: 1, value: milestones.gettingStarted.likeReceived ? 1 : 0 }} />
                    </div>
                </div> : null }
                <div className="milestone-grid-wrapper">
                    <div className="milestone-grid">
                        {milestones && milestones.milestones ? milestones.milestones.filter(m => m.value < m.target).sort((a, b) => { return (b.value/b.target) - (a.value/a.target) }).map(m => {
                            return <MilestoneProgress milestone={m} key={m.name}/>
                        }) : null}
                        {milestones && milestones.milestones ? milestones.milestones.filter(m => m.value >= m.target).map(m => {
                            return <MilestoneProgress milestone={m} key={m.name}/>
                        }) : null}
                    </div>
                </div>
            </div>
        </Scrollbar>
    </div>
}

export default Milestones;