import { faPencilAlt, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import { ActionStore } from "../../Stores/ActionStore";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { getProfile } from "../../Utilities/GraphAPI";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import APIFetch from "../../Utilities/APIFetch";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";

const RoleAssignmentItem = (props) => {
    const a = props.assignment;
    const employeeUser = useStoreState(EmployeeStore, s => { return s[a.userId] || null });
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [errorText, setErrorText] = useState(null);

    useEffect(() => {
        if(employeeUser === null) {
            getProfile(a.userId)
            .catch(e => {});
        }
    }, [a]);

    const onProfileClick = (profile) => {
        if(profile) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
        }
    }

    const onDelete = () => {
        APIFetch('DELETE', `roles/${a.userRoleOverrideID}`)
        .then((result) => {
            if(result && result.ok) {
                if(props.onDelete) { props.onDelete() }
            } else {
                setErrorText("An error occurred with your request, please try again later.");
            }
        })
        .catch(e => {
            setErrorText("An error occurred with your request, please try again later.");
        });
    }

    const onEdit = () => {
        if(props.onEdit) { props.onEdit(employeeUser, a.roleName) };
    }

    return (
        <div className="card margin-top-1 margin-left-1 flex-row flex-align-center" role="group" tabIndex="0">
            { confirmDelete ? <ConfirmModal title={`Delete ${employeeUser.displayName}'s role`} content={"Are you sure you wish to delete this role assignment?"} onClose={() => setConfirmDelete(false) } onConfirm={onDelete} fixedPosition={true}/> : null }
            { errorText ? <DetailsPopOver onClose={() => { setErrorText(null); }} title="Oops, something went wrong!" content={errorText}/> : null }
            <img src={employeeUser && employeeUser.image ? employeeUser.image : defaultProfileImage} className="profile-image-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employeeUser)} tabIndex="0" alt=""/>
            <div className="flex text-align-left flex-shrink-1 margin-right-1">
                <p className="margin-0 margin-left-2 bold"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employeeUser)}>{employeeUser && employeeUser.displayName ? employeeUser.displayName : employeeUser ? employeeUser.id : 'Someone'}</span> - {a.roleName}</p>
                <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{employeeUser ? employeeUser.jobTitle : ''}</p>
            </div>
            <div className="flex-row flex-align-center margin-left-auto margin-right-2">
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={() => { onEdit() }} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faPencilAlt} className="text-md text-alert margin-right-1"/>
                    <p>Edit</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={() => { setConfirmDelete(true) }} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faTrashAlt} className="text-md text-alert margin-right-1"/>
                    <p>Delete</p>
                </div>
            </div>
        </div>
    );
};

export default RoleAssignmentItem;