import React, { useState, useEffect } from "react";
import RichTextEditor from "react-rte";
import Scrollbar from "react-scrollbars-custom";
import APIFetch from "../../Utilities/APIFetch";
import '../ConfirmDetailsModal/ConfirmDetailsModal.scss';
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";
import './TermsOfUseModal.scss';

const TermsOfUseModal = (props) => {
    const preview = props.previewOnly;
    const terms = props.pendingTerms;
    const [currentTerms, setCurrentTerms] = useState(0);
    const [acceptDisabled, setAcceptDisabled] = useState(!preview);
    const [errorModal, setErrorModal] = useState(false);
    const callback = props.termsCallback;

    const onClose = () => {
        if(preview) {
            callback();
            return;
        }

        if(!acceptDisabled) {
            APIFetch('POST', 'terms/acknowledge/' + terms[currentTerms].id)
            .then((result) => {
                if(result && result.ok) {
                    if(terms.length > (currentTerms + 1)) {
                        setCurrentTerms(currentTerms + 1);
                        setAcceptDisabled(true);
                        // For additional Usage Guidance simply use 2s timer since handleUpdate won't fire if the size doesn't change
                        setTimeout(() => { setAcceptDisabled(false); }, 2000);
                    } else {
                        callback();
                    }
                } else {
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setErrorModal(true);
            });
        }
    }

    const handleScroll = (scrollValues) => {
        //const bottom = scrollValues.scrollHeight - scrollValues.scrollTop === scrollValues.clientHeight;
        const bottom = (scrollValues.scrollHeight - scrollValues.scrollTop) - scrollValues.clientHeight < 50;
        if (bottom && acceptDisabled) {
            setAcceptDisabled(false);
        }
    }

    const handleUpdate = (scrollValues) => {
        //const bottom = scrollValues.scrollHeight - scrollValues.scrollTop === scrollValues.clientHeight;
        const bottom = (scrollValues.scrollHeight - scrollValues.scrollTop) - scrollValues.clientHeight < 50;
        if (bottom && acceptDisabled) {
            setTimeout(() => { setAcceptDisabled(false); }, 2000);
        }
    }

    const editorValue = RichTextEditor.createValueFromString(terms[currentTerms].body, 'markdown');

    return (
        <div className="modal confirm-details modal-white terms-modal">
            { errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); }} title="Oops, something went wrong!" content={"An error occurred with your request, please try again later."}/> : null }
            <div className="modal-box no-close flex flex-align-center" style={{ overflowY: 'auto' }}>
                <div className="flex padding-2 flex-grow">
                    <h1 className="margin-bottom-4">{terms[currentTerms].title}</h1>
                    <Scrollbar className="terms-content" onScroll={handleScroll} onUpdate={handleUpdate}>
                        <RichTextEditor
                            value={editorValue}
                            readOnly={true}
                            className={"rte"}
                            editorClassName="editor"
                        />
                    </Scrollbar>
                </div>
                <div className="button-box flex-row flex-item-bottom padding-3">
                    <div className="flex-grow flex-row flex-center-both">
                        <div className={"btn btn-flex bold margin-right-2 continue-btn " + (acceptDisabled ? " btn-negative" : " btn-positive")} onClick={onClose} tabIndex="0" role="button">
                            Continue
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TermsOfUseModal;