import React from "react";
import { faLongArrowAltRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../DetailsPopOver/DetailsPopOver.scss';
import './ConfirmRecognitionModal.scss';
import { useStoreState } from "pullstate";
import { AccountStore } from "../../Stores/AccountStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { CompanyValuesStore } from "../../Stores/CompanyValuesStore";
import Scrollbar from "react-scrollbars-custom";

const ConfirmRecognitionModal = (props) => {
    const details = useStoreState(AccountStore, s => s.profile);
    const companyValues = useStoreState(CompanyValuesStore, s => s.values);
    var data = props.data;
    var value =  companyValues.find(v => v.id === data.valueId);

    return (
        <div className={"modal confirm-modal confirm-rec-modal popover-modal"}>
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={props.onClose}/>
                    </div>
                </div>
                <div className="details-popover">
                    <Scrollbar>
                        <div className="details-block flex flex-align-center">
                            <div className="flex-row flex-align-center">
                                <img src={details && details.image ? details.image : defaultProfileImage} className="profile-image-lg margin-right-1" alt=""/>
                                <FontAwesomeIcon className="arrow-icon" icon={faLongArrowAltRight} />
                                <img src={data.recipient && data.recipient.image ? data.recipient.image : defaultProfileImage} className="profile-image-lg margin-right-1" alt=""/>
                            </div>
                            <div className="flex flex-align-center margin-top-2">
                                <div className="bold margin-top-1">You are about to send</div>
                                <div className="value-text text-align-center margin-bottom-1">
                                    {Array.isArray(data.recipient) ? data.recipient.map(r => { return <div key={r.id}>{r.displayName}</div> }) : data.recipient.displayName}
                                </div>
                            </div>
                            <div className="flex flex-align-center margin-bottom-1">
                                <div className="bold margin-top-1">Recognition for</div>
                                <div className="value-text">{value.welshName} / {value.name}</div>
                            </div>
                            <div className="flex flex-align-center margin-bottom-2">
                                <div className="bold margin-top-1">With the coin value</div>
                                <div className="value-text">{data.amount} coins</div>
                            </div>
                            <div className="bold margin-top-1">Please click to confirm or edit your choices</div>
                        </div>
                        <div className="flex-row flex-justify-center margin-bottom-1">
                            <div className="btn btn-alert margin-left-2 " onClick={props.onConfirm} role="button" tabIndex="0">
                                <p className="bold margin-top-1 margin-bottom-1">Confirm</p>
                            </div>
                            <div className="btn btn-negative margin-left-2" onClick={props.onClose} role="button" tabIndex="0">
                                <p className="bold margin-top-1 margin-bottom-1">Edit</p>
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            </div>
        </div>
        
    );
};

export default ConfirmRecognitionModal;