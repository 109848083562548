import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../DetailsPopOver/DetailsPopOver.scss';

const ConfirmModal = (props) => {
    return (
        <div className={"modal confirm-modal popover-modal" + (props.fixedPosition ? " fixed" : "")}>
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={props.onClose} tabIndex={0} role="button"/>
                    </div>
                </div>
                <div className="details-popover">
                    <p className="text-xlg margin-top-1">{props.title}</p>
                    <p className="text-md">{props.content}</p>
                    <div className="flex-row">
                        <div className="btn btn-alert margin-left-2 " onClick={props.onConfirm} tabIndex={0} role="button">
                            <p className="bold margin-top-1 margin-bottom-1">{props.confirmText || "Confirm"}</p>
                        </div>
                        <div className="btn btn-negative margin-left-2" onClick={props.onClose} tabIndex={0} role="button">
                            <p className="bold margin-top-1 margin-bottom-1">{props.cancelText || "Cancel"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default ConfirmModal;