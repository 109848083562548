import React, { useState, useEffect } from "react";
import './Activity.scss';
import KudosItem from "../../Components/KudosItem/KudosItem";
import RedemptionItem from "../../Components/RedemptionItem/RedemptionItem";
import BadgeItem from "../../Components/BadgeItem/BadgeItem";
import APIFetch from "../../Utilities/APIFetch";
import Scrollbar from 'react-scrollbars-custom';

import { useStoreState } from "pullstate";
import { FeedStore } from "../../Stores/FeedStore";

import Loader from "react-loader-spinner";
import AwardItem from "../../Components/AwardItem/AwardItem";

const ROW_HEIGHT = 75;

const Activity = () => {
    const feed = useStoreState(FeedStore, s => s.feed);

    // Feed List
    const [isLoading, setIsLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);
    const [feedDate, setFeedDate] = useState(null);

    const getFeed = (from, to, date = feedDate) => {
        return APIFetch('GET', `feed?from=${from}&to=${to}&date=${date}`)
        .then((result) => {
            if(result && result.ok) {
                if(from === 0) {
                    FeedStore.update(s => { s.feed = result.data.feed });
                } else {
                    FeedStore.update(s => { s.feed = s.feed.concat(result.data.feed) });
                }

                setTotalItems(result.data.totalItems);
                setIsLoading(false);
            }
        })
        .catch(e => {
        });
    }

    const onScroll = (scrollValues) => {
        if(!isLoading && totalItems > feed.length && (scrollValues.scrollTop + scrollValues.clientHeight) > scrollValues.scrollHeight - (0.5 * ROW_HEIGHT)) {
            setIsLoading(true);
            getFeed(feed.length, feed.length + 100);
        }
    }

    const onLike = (type, id, value) => {
        FeedStore.update(s => { 
            var match = s.feed.find(a => a[type] && a[type].id === id);
            if(match) {
                if(value) {
                    match[type].likes += 1;
                } else {
                    match[type].likes -= 1;
                }
                match[type].userLiked = value;
            }
        });
    }

    useEffect(() => {
        // On mount
        var newDate = new Date().toISOString();
        setFeedDate(newDate);
        getFeed(0, 100, newDate);
    }, []);

    return (
        <div className="activity">
            <h2 className="margin-top-0">Activity</h2>
            <Scrollbar scrollerProps={{tabIndex: 0, role: "group"}} className="dash-scroll" onScroll={onScroll}>
                { feed ? feed.map(fI => {
                        if(fI.kudos) {
                            return <KudosItem key={fI.kudos.id} kudos={fI.kudos} onLike={(value) => { onLike("kudos", fI.kudos.id, value) } }/>;
                        } else if(fI.redemption) {
                            return <RedemptionItem key={fI.redemption.id} redemption={fI.redemption} onLike={(value) => { onLike("redemption", fI.redemption.id, value) } }/>;
                        } else if(fI.badge) {
                            return <BadgeItem key={fI.badge.id} badge={fI.badge} onLike={(value) => { onLike("badge", fI.badge.id, value) } }/>;
                        } else if(fI.award) {
                            return <AwardItem key={fI.award.id} award={fI.award} onLike={(value) => { onLike("award", fI.award.id, value) } }/>;
                        } else {
                            return null;
                        }
                    }) : <div>Failed to retrieve activity feed.</div>}
                    {feed.length < totalItems ? <div>
                        <Loader
                            type="Oval"
                            color="#FF0000"
                            height={40}
                            width={40}
                            className="list-loader"
                        />
                    </div> : <div className="text-align-center padding-4">No more activity to show!</div>}
            </Scrollbar>
        </div>
    );
};

export default Activity;