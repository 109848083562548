import appConfig from "../../config.json";
import "./MilestoneProgress.scss";

const MilestoneStep = (props) => {
    const milestoneStep = props.milestoneStep;

    return (
        <div className="milestone-progress step-progress padding-1 margin-1">
            <div className="milestone-content">
                <div className="progress-row">
                    <div className="progress-text margin-right-1">{milestoneStep.value}/{milestoneStep.target}</div>
                    <div className="progress-bar-wrapper">
                        <div className="step-title">{milestoneStep.title}</div>
                        <div className="progress-bar">
                            <div className="progress" style={{ width: `${(milestoneStep.value/milestoneStep.target) * 100 }%` }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default MilestoneStep;