import React from "react";
import moment from 'moment';
import { useStoreState } from "pullstate";
import { RewardsStore } from "../../Stores/RewardsStore";
import './WalletItem.scss';
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const statuses = {
    "-3": "Errored",
    "-2": "Errored",
    "-1": "Cancelled",
    0: "Errored",
    1: "Unclaimed",
    2: "Claimed",
    3: "Expired"
}

const WalletItem = (props) => {
    const giftCards = useStoreState(RewardsStore, s => s.rewards);
    const item = props.item;
    var card = null;
    var company = null;

    if(item.status === 0) {
        return null;
    }

    if(item && giftCards) {
        for(var a = 0; a < giftCards.length; a++) {
            for(var b = 0; b < giftCards[a].cards.length; b++) {
                if(giftCards[a].cards[b].id === item.cardId) {
                    card = giftCards[a].cards[b];
                    company = giftCards[a].company;
                    break;
                }
            }
            if(card) {
                break
            }
        }
    }

    return (
        <div className="wallet-item card box-shadow flex-row flex-align-center">
            <img className="" src={card ? card.imageURL : null} alt={company} />
            <div className="row-block">
                <p>{card ? card.title : "Unknown GiftCard"}</p>
            </div>
            <div className="row-block">
                <p>{moment(item.createdAt).format('L')}</p>
                <p>{item.status > 0 ? moment(item.expiry).format('L') : "-"}</p>
                <div>
                    <a className={item.eGiftURL ? "pointer underline bold" : "bold"} href={item.eGiftURL} target="_blank" rel="noopener noreferrer" role={item.eGiftURL ? "button" : null } tabIndex={item.eGiftURL ? "0" : null }>{statuses[item.status] || "Errored"}</a>
                    {item.eGiftURL ? <FontAwesomeIcon icon={faExternalLinkAlt} className="text-md"/> : null }
                </div>
            </div>
        </div>
    );
};

export default WalletItem;