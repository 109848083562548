import React, { useState } from "react";
import RichTextEditor from "react-rte";
import Scrollbar from "react-scrollbars-custom";
import appConfig from "../../config.json";
import '../ConfirmDetailsModal/ConfirmDetailsModal.scss';
import './MandatoryPostModal.scss';

const MandatoryPostModal = (props) => {
    const p = props.post;
    const [editorValue, setEditorValue] = useState(RichTextEditor.createValueFromString(p.body, 'markdown'));

    return (
        <div className="modal confirm-details modal-white terms-modal mandatory-post-modal">
            <div className="modal-box no-close flex flex-align-center" style={{ overflowY: 'auto' }}>
                <div className="flex padding-2 full-height">
                    <Scrollbar scrollerProps={{tabIndex: 0}} className={(p && p.imagePath ? "post-content with-image" : "post-content")}>
                        {p && p.imagePath ? <img className="mandatory-post-img flex-item-right flex-item-left flex" src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + p.imagePath} alt=""/> : null }
                        <h1>{p.title}</h1>
                        <div className="text-content margin-top-1 text-align-left">
                            <RichTextEditor
                                value={editorValue}
                                readOnly={true}
                                className="rte"
                                editorClassName="editor"
                            />
                        </div>
                    </Scrollbar>
                </div>
                <div className="button-box flex-row flex-item-bottom padding-3">
                    <div className="flex-grow flex-row flex-center-both">
                        <div className="btn btn-flex btn-positive bold margin-right-2" onClick={props.onClose} tabIndex="0" role="button">
                            Close
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MandatoryPostModal;