import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import EmployeeLookup from '../../Components/EmployeeLookup/EmployeeLookup';
import APIFetch from '../../Utilities/APIFetch';
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import Success from '../../Assets/images/Success.svg';
import Scrollbar from 'react-scrollbars-custom';
import { Tooltip } from 'react-tippy';

const SystemManagement = (props) => {
    const [selectedGroup, setSelectedGroup] = useState(0);
    const [distributionDetails, setDistributionDetails] = useState(null);
    const [amount, setAmount] = useState(0);
    const [role, setRole] = useState(null);
    const [isTopUp, setIsTopUp] = useState(true);
    const [isSpendable, setIsSpendable] = useState(false);
    const [coinEstimate, setCoinEstimate] = useState(null);
    const [pendingEstimate, setPendingEstimate] = useState(0);
    const [currentTimeout, setCurrentTimeout] = useState(null);
    const employeeRef = useRef(null);
    const stateRef = useRef();
    stateRef.current = pendingEstimate;

    const [success, setSuccess] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [warningModal, setWarningModal] = useState(false);
    const [errorText, setErrorText] = useState(null);

    const onClose = () => {
        setSelectedGroup(0);
        setAmount('');
        if(distributionDetails && distributionDetails.usersPerRole) setRole(Object.keys(distributionDetails.usersPerRole)[0]);
        setSuccess(false);
    }
    
    useEffect(() => {
        onDataChange();
    }, [selectedGroup, role, isTopUp, amount])

    const onDataChange = () => {
        var recipient = selectedGroup === 2 ? employeeRef.current.getSelected() : null;
        var formattedAmount = amount ? parseInt(amount) : 0;
        var newPending = pendingEstimate + 1;

        if(selectedGroup === 2 && !recipient) {
            setCoinEstimate(null);
            return;
        }

        if(isNaN(formattedAmount)) {
            setCoinEstimate(null);
            return;
        }

        if(formattedAmount === 0) {
            setCoinEstimate(null);
            return;
        }

        getCoinEstimate(recipient, formattedAmount, newPending);
    }

    const getCoinEstimate = (recipient, formattedAmount, newPending) => {
        setPendingEstimate(newPending);

        if(currentTimeout) {
            clearTimeout(currentTimeout);
        }

        setCurrentTimeout(setTimeout(() => {
            APIFetch('POST', `system/distribute/estimate`, { type: selectedGroup, value: formattedAmount, identifier: recipient ? recipient.id : role.charAt(0).toUpperCase() + role.slice(1), isTopUp: isTopUp })
            .then((result) => {
                if(result && result.ok && newPending === stateRef.current) {
                    setCoinEstimate(result.data.value);
                }
            })
            .catch(e => {
            });
        }, 500));
        
    }

    const sendCoins = () => {
        var recipient = selectedGroup === 2 ? employeeRef.current.getSelected() : null;
        var formattedAmount = amount ? parseInt(amount) : 0;

        if(selectedGroup === 2 && !recipient) {
            setErrorText("A recipient must be selected!");
            setErrorModal(true);
            return;
        }

        if(isNaN(formattedAmount)) {
            setErrorText("Amount of coins is not a valid number!");
            setErrorModal(true);
            return;
        }

        if(formattedAmount === 0) {
            setErrorText("Amount of coins cannot be 0!");
            setErrorModal(true);
            return;
        }

        APIFetch('POST', `system/distribute`, { type: selectedGroup, value: formattedAmount, identifier: recipient ? recipient.id : role.charAt(0).toUpperCase() + role.slice(1), isTopUp: isTopUp, isSpendable: isSpendable })
        .then((result) => {
            if(result && result.ok) {
                setSuccess(true);
            } else {
                setErrorModal(true);
                return;
            }
        })
        .catch(e => {
            setErrorModal(true);
        });
    }

    useEffect(() => {
        APIFetch('GET', `system/distribute`)
        .then((result) => {
            if(result && result.ok) {
                setDistributionDetails(result.data);
                setRole(Object.keys(result.data.usersPerRole)[0]);
            }
        })
        .catch(e => {
        });
    }, []);

    if(success) {
        return <div className="flex full-height">
            {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onBack} tabIndex={0} role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <div className="flex flex-center-both full-height flex-space-evenly padding-top-2 full-width"  style={{ maxHeight: "calc(100% - 45px)" }}>
                <div className="redeem-medal flex min-height-0 full-height">
                    <img src={Success} alt="" className="full-height"/>
                </div>
                <div className="flex flex-center-both">
                    <div className="text-align-center">
                        <p className="text-xxlg bold margin-bottom-1">Success!</p>
                        <p className="text-md text-grey">The distribution is ready to be verified by another Admin user!</p>
                    </div>
                    <div className="send-btn btn btn-positive margin-top-2" onClick={onClose} tabIndex={0} role="button">
                        Close
                    </div>
                </div>
            </div>
        </div>
    }

    return(
        <div className="flex full-height">
            {warningModal ? <DetailsPopOver onClose={() => { setWarningModal(false); }} title="Warning." content={"Coins which are added to the SPEND pot can be immediately spent by users without being shared."}/> : null }
            {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onBack} tabIndex={0} role="button">
                <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                <p className="margin-0 margin-left-1 text-md">Back</p>
            </div>
            <Scrollbar>
                <div className="text-grey text-lg bold">Allocate Coins</div>
                <div className="text-grey light margin-bottom-3">Allocate new coins into the system</div>
                <div className="margin-bottom-2 flex">
                    <label className="text-md">Who do you want to allocate coins to?</label>
                    <div style={{ marginRight: 'auto' }} className="selector box-shadow flex-row flex-item-left">
                        <div className={"selector-item text-md " + (selectedGroup === 0 ? " active" : "")} onClick={() => { setSelectedGroup(0); }} tabIndex={0} role="button">
                            Everyone
                        </div>
                        <div className={"selector-item text-md " + (selectedGroup === 1 ? " active" : "")} onClick={() => { setSelectedGroup(1); }} tabIndex={0} role="button">
                            Role
                        </div>
                        <div className={"selector-item text-md " + (selectedGroup === 2 ? " active" : "")} onClick={() => { setSelectedGroup(2); }} tabIndex={0} role="button">
                            Individual
                        </div>
                    </div>
                    { selectedGroup === 2 ? <EmployeeLookup className="margin-bottom-1" ref={employeeRef} onChange={() => { onDataChange(); }}/> : null }
                    { selectedGroup === 1 && distributionDetails && distributionDetails.usersPerRole ? <select  className="margin-bottom-1" onChange={(e) => { setRole(e.currentTarget.value); }}>
                        {
                            Object.keys(distributionDetails.usersPerRole).map(i => <option value={i}>{i.charAt(0).toUpperCase() + i.slice(1)} ({distributionDetails.usersPerRole[i]})</option>)
                        }
                    </select> : null }
                </div>
                <div className="flex-row flex-align-center tooltipped-label">
                    <label className="text-md">Which allocation method do you want to use?</label>
                    <Tooltip
                        html={(
                            <div>
                                <div className="italic margin-bottom-1"><strong>Share Top-up:</strong> Increase the share pots of the selected users' to the value provided.</div>
                                <div className="italic margin-bottom-1"><strong>Share Award:</strong> Add the provided value of coins to every selected users' share pot.</div>
                                <div className="italic"><strong>Spend Award:</strong> Add the provided value of coins to every selected users' SPEND pot.</div>
                            </div>
                        )}
                        position="top"
                        className={"badge " + props.className}
                        style={{ display: 'flex' }}>
                            <FontAwesomeIcon className="info-icon margin-left-1" icon={faInfoCircle} />
                    </Tooltip>
                </div> 
                <div style={{ marginRight: 'auto' }} className="selector box-shadow flex-row flex-item-left">
                    <div className={"selector-item text-md " + (isTopUp === true ? " active" : "")} onClick={() => { setIsTopUp(true); setIsSpendable(false); }} tabIndex={0} role="button">
                        Share Top-up
                    </div>
                    <div className={"selector-item text-md " + (isTopUp === false && isSpendable === false ? " active" : "")} onClick={() => { setIsTopUp(false); setIsSpendable(false); }} tabIndex={0} role="button">
                        Share Award
                    </div>
                    <div className={"selector-item text-md " + (isSpendable === true ? " active" : "")} onClick={() => { setWarningModal(true); setIsSpendable(true); setIsTopUp(false); }} tabIndex={0} role="button">
                        Spend Award
                    </div>
                </div>
                <div className="flex">
                    <label htmlFor="coins" className="text-md margin-bottom-1">How many coins do you want to allocate (per user)?</label>
                    <input id="coins" type="number" min="0" placeholder="Number of coins" className="margin-bottom-2" value={amount} onChange={(e) => { setAmount(e.currentTarget.value); }}/>
                </div>
                { coinEstimate !== null ? <div>Estimated Total: {coinEstimate} Coins (£{coinEstimate/100})</div> : null }
                <div className="flex-row flex-item-bottom margin-bottom-1 margin-top-4">
                    <div tabIndex={0} className={"send-btn btn btn-positive bold" + (amount && amount > 0 ? '' : ' btn-negative')} onClick={amount && amount > 0 ? sendCoins : null }>
                        Allocate Coins
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
}

export default SystemManagement;