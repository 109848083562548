import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {enableES5} from "immer";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import moment from 'moment';
import 'react-dates/initialize';

enableES5();

// if (process.env.NODE_ENV !== 'production') {
//     var axe = require('react-axe');
//     axe(React, ReactDOM, 1000);
// }

moment.updateLocale('en-gb', {
    calendar : {
        lastDay : '[Yesterday]',
        sameDay : '[Today]',
        nextDay : '[Tomorrow]',
        lastWeek : 'dddd',
        nextWeek : '[Next] dddd',
        sameElse : 'DD/MM/YYYY'
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();