import React, { useState, forwardRef, useImperativeHandle } from "react";
import './EmployeeLookup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';

import { AccountStore } from "../../Stores/AccountStore";
import { searchPeople } from "../../Utilities/GraphAPI";
import { useStoreState } from "pullstate";

var currentTimeout = null;

const EmployeeLookup = (props, ref) => {
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const [selectedEmployee, setSelectedEmployee] = useState(props.target);
    const [matchingEmployees, setMatchingEmployees] = useState([]);
    const [focused, setFocused] = useState(false);
    var employeeItems = [];


    // Set ref
    useImperativeHandle(ref, () => ({
        getSelected: () => {
            return selectedEmployee;
        },
        reset: () => {
            setSelectedEmployee(null);
            setMatchingEmployees([]);
        }
    }));

    var getList = (searchTerm) => {
        searchPeople(searchTerm, myId)
        .then(result => {
            if(result.ok) {
                setMatchingEmployees(result.data);
            } else {
                setMatchingEmployees([]); 
            }
        })
        .catch(e => {
            setMatchingEmployees([]);
        });
    }

    matchingEmployees.forEach(e => {
        let handleClick = () => {
            if(props.onChange) {
                props.onChange(e);
            }
            
            if(props.onSelected) {
                props.onSelected(e);
            } else {
                setSelectedEmployee(e);
            }

            setMatchingEmployees([]);
        };

        employeeItems.push(
            <div key={e.id} className="flex-row employee-lookup-item pointer" onMouseDown={handleClick} role="option" tabIndex="0">
                <img src={e.image || defaultProfileImage} className="profile-image-sm margin-1" alt=""/>
                <div className="flex flex-justify-center margin-left-1">
                    <p className="margin-0 text-md bold">{e.displayName}</p>
                    <p className="margin-0 text-md">{e.jobTitle} - {e.department}</p>
                </div>
            </div>
        );
    });

    return (
        <div className={"employee-lookup relative input " + props.className}
            onBlur={(event) => { if (!event.currentTarget.contains(event.relatedTarget)) {
                setFocused(false);
            } }}
            onFocus={() => { setFocused(true) }}>
            { selectedEmployee ? 
                <div className="flex-row flex-align-center">
                    <img src={selectedEmployee.image || defaultProfileImage} className="profile-image-sm margin-1" alt=""/>
                    <div className="flex flex-justify-center">
                        <p className="margin-0 text-lg">{selectedEmployee.displayName}</p>
                        <p className="margin-0 text-md">{selectedEmployee.jobTitle} - {selectedEmployee.department}</p>
                    </div>
                    <FontAwesomeIcon icon={faTimes} className="pointer margin-left-2 text-md text-alert" onClick={() => { setSelectedEmployee(null); props.onChange(null); }} tabIndex="0" role="button"/>
                </div> 
                : <input type="text" placeholder={props.placeholder || "Employee name"}
                    className="full-width"
                    aria-label="Search Employees"
                    onChange={e => { 
                        clearTimeout(currentTimeout);
                        if(!e.target.value || e.target.value.length === 0) {
                            setMatchingEmployees([]);
                        } else {
                            currentTimeout = setTimeout((value) => {
                                getList(value);
                            }, 500, e.target.value);
                        }
                    }}/>
            }
            <div className="employee-lookup-list absolute full-width box-shadow" role="listbox" tabIndex="0">
                {focused ? employeeItems : null}
            </div>
        </div>
    );
};

export default forwardRef(EmployeeLookup);