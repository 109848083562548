import React from "react";
import { Tooltip } from 'react-tippy';
import appConfig from "../../config.json";

const Badge = (props) => {
    const b = props.badge;

    if(!b) {
        return null;
    }

    if(b.percentageEarned === undefined) {
        return (
            <div className={"badge " + props.className}>
                <img className={props.imageClassName} src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + b.name + '.png'} height={props.size ? props.size + 'px' : '64px'} alt="Badge"/>
                {props.withTitle ? <div className="bold margin-left-1 underline">{b.title}</div> : null }
            </div>
        )
    }

    return (
        <Tooltip
        html={(
            <div>
                <div className="tooltip-title">{b.title}</div>
                <div className="tooltip-text">Earned by <span className="bold">{b.percentageEarned.toFixed(2)}%</span> of users</div>
            </div>
        )}
        position="top"
        className={"badge " + props.className}
        style={{ display: 'flex' }}>
            <img className={props.imageClassName} src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + b.name + '.png'} height={props.size ? props.size + 'px' : '64px'} alt="Badge"/>
            {props.withTitle ? <div className="bold margin-left-1 underline">{b.title}</div> : null }
        </Tooltip>
    );
};

export default Badge;