import appConfig from "../../config.json";
import "./MilestoneProgress.scss";

const MilestoneProgress = (props) => {
    const milestone = props.milestone;

    if(milestone.value >= milestone.target) {
        // Reached max milestone level
        return (
            <div className="milestone-progress card padding-1 margin-1">
                <img className="margin-right-1" src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + milestone.name + '.png'} height={'100px'} alt="Badge"/>
                <div className="milestone-content">
                    <div className="milestone-title">{milestone.badge.title}</div>
                    <div className="complete">
                        Complete!
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="milestone-progress card padding-1 margin-1">
            <img className="margin-right-1" src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + milestone.name + '.png'} height={'100px'} alt="Badge"/>
            <div className="milestone-content">
                <div className="milestone-title">{milestone.badge.title}</div>
                <div className="progress-row">
                    <div className="progress-text margin-right-1">{milestone.value}/{milestone.target}</div>
                    <div className="progress-bar">
                        <div className="progress" style={{ width: `${(milestone.value/milestone.target) * 100 }%` }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MilestoneProgress;