import React from "react";
import './GiftCardItem.scss';

import fashionWhite from '../../Assets/images/categories/Fashion.svg';
import foodAndDrinkWhite from '../../Assets/images/categories/FoodAndDrink.svg';
import goodsWhite from '../../Assets/images/categories/Goods.svg';
import newCategoryWhite from '../../Assets/images/categories/New.svg';
import thingsToDoWhite from '../../Assets/images/categories/ThingsToDo.svg';
import charityWhite from '../../Assets/images/categories/Charity.svg';

import fashion from '../../Assets/images/categories/FashionGrey.svg';
import foodAndDrink from '../../Assets/images/categories/FoodAndDrinkGrey.svg';
import goods from '../../Assets/images/categories/GoodsGrey.svg';
import newCategory from '../../Assets/images/categories/NewGrey.svg';
import thingsToDo from '../../Assets/images/categories/ThingsToDoGrey.svg';
import charity from '../../Assets/images/categories/CharityGrey.svg';

const categoryIcons = {
    "Goods": goods,
    "Food & Drink": foodAndDrink,
    "Things to Do": thingsToDo,
    "New": newCategory,
    "Fashion": fashion,
    "Charity": charity
}

const categoryIconsWhite = {
    "Goods": goodsWhite,
    "Food & Drink": foodAndDrinkWhite,
    "Things to Do": thingsToDoWhite,
    "New": newCategoryWhite,
    "Fashion": fashionWhite,
    "Charity": charityWhite
}

const CategoryItem = (props) => {
    return (
        <div tabIndex={0} className={"gift-card-category box-shadow flex-row flex-center-both pointer margin-bottom-2" + (props.isSelected ? ' active' : '')} onClick={props.onSelected}>
            <div>
                <img src={props.isSelected ? categoryIconsWhite[props.category] : categoryIcons[props.category]} alt={props.category} className=""/>
            </div>
            <div>
                <div className="margin-top-1 margin-bottom-1">{props.category}</div>
            </div>
        </div>
    );
};

export default CategoryItem;