import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import './CreatePost.scss';
import APIFetch from '../../Utilities/APIFetch';
import { faChevronLeft, faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import Scrollbar from 'react-scrollbars-custom';
import { Tooltip } from 'react-tippy';
import { SingleDatePicker } from 'react-dates';
import appConfig from "../../config.json";
import moment from 'moment';

const CreatePost = (props) => {
    var existingPost = props.post || {};
    const [title, setTitle] = useState(existingPost.title || null);
    const [file, setFile] = useState(existingPost.imagePath ? appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + existingPost.imagePath : null);
    const [rawFile, setRawFile] = useState(null);
    const [editorValue, setEditorValue] = useState(existingPost.body ? RichTextEditor.createValueFromString(existingPost.body, 'markdown') : RichTextEditor.createEmptyValue());
    const [errorModal, setErrorModal] = useState(false);
    const [mandatory, setMandatory] = useState(existingPost.wasMandatory || false);
    const [endDate, setEndDate] = useState(existingPost.mandatoryEndDate && existingPost.mandatoryEndDate !== '0001-01-01T00:00:00Z' ? moment(existingPost.mandatoryEndDate) : null);
    const [dateFocused, setDateFocused] = useState(null);

    const handleChange = (event) => {
        setRawFile(event.target.files[0]);
        setFile(URL.createObjectURL(event.target.files[0]));
    }

    const onChange = (value) => {
        setEditorValue(value);
    };

    const titleChange = (e) => {
        setTitle(e.target.value);
    };

    const onSave = () => {
        if(title && title.length > 0) {
            var formData = new FormData();
            formData.append("title", title);
            formData.append("image", rawFile);
            formData.append("body", editorValue.toString('markdown'));
            formData.append("mandatory", mandatory);

            if(endDate) {
                formData.append("mandatoryEndDate", endDate.utc().startOf('day').toISOString());
            }

            if(file !== null && rawFile === null) {
                formData.append("useOldImage", true);
            }

            APIFetch('POST', existingPost.id ? `social/posts/${existingPost.id}` : 'social/posts', null, formData)
            .then((result) => {
                if(result && result.ok) {
                    if(props.onComplete) {
                        props.onComplete();
                    }
                } else {
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setErrorModal(true);
            });
        }
    }

    return(
        <div className="create-post-wrapper">
            <Scrollbar>
                {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); }} title="Oops, something went wrong!" content={"An error occurred with your request, please try again later."}/> : null }
                <div className="create-post full-height flex">
                    <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onCancel}>
                        <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                        <p className="margin-0 margin-left-1 text-md">Back</p>
                    </div>
                    <div className="text-grey text-lg bold margin-bottom-2">Create a Post</div>
                    <label className="text-md">Post Title</label>
                    <input type="text" placeholder="Enter Post Title" value={title} onChange={titleChange}/>
                    <label className="text-md">Post Image</label>
                    {file ? <div className="flex-row">
                        <img src={file} className="image-preview" alt=""/>
                        <FontAwesomeIcon className="pointer text-xlg margin-left-1 margin-top-1" icon={faTimes} onClick={() => { setFile(null); setRawFile(null); }} />
                    </div> : <div class="upload-btn-wrapper">
                        <div class="btn">Browse</div>
                        <input type="file" id="file" onChange={handleChange} accept="image/x-png,image/jpeg" aria-label="Attach Image"/>
                    </div> }
                    <label className="text-md">Post Content</label>
                    <RichTextEditor
                        value={editorValue}
                        onChange={onChange}
                        className="rte"
                        editorClassName="editor"
                        placeholder="Write Post Here"
                    />
                    <div className="flex-row flex-align-center tooltipped-label">
                        <label className="text-md margin-0">Post Type</label>
                        <Tooltip
                            html={(
                                <div className="italic">Mandatory posts will be automatically displayed to users on log-in.</div>
                            )}
                            position="top"
                            className={"badge " + props.className}
                            style={{ display: 'flex' }}>
                                <FontAwesomeIcon className="info-icon margin-left-1" icon={faInfoCircle} />
                        </Tooltip>
                    </div>
                    <div className="selector box-shadow flex-row post-type-selector">
                        <div className={"selector-item text-md " + (!mandatory ? " active" : "")} onClick={() => { setMandatory(false) }} tabIndex={0} role="button">
                            Regular
                        </div>
                        <div className={"selector-item text-md " + (mandatory ? " active" : "")} onClick={() => { setMandatory(true) }} tabIndex={0} role="button">
                            Mandatory
                        </div>
                    </div>
                    { mandatory ? <div className="flex-row flex-align-center tooltipped-label">
                        <label className="text-md margin-0">Mandatory Expiry Date</label>
                        <Tooltip
                            html={(
                                <div className="italic">The date at which the post will no longer be mandatory.</div>
                            )}
                            position="top"
                            className={"badge " + props.className}
                            style={{ display: 'flex' }}>
                                <FontAwesomeIcon className="info-icon margin-left-1" icon={faInfoCircle} />
                        </Tooltip>
                    </div> : null }
                    {mandatory ? <SingleDatePicker
                        date={endDate} // momentPropTypes.momentObj or null
                        onDateChange={date => setEndDate(date)} // PropTypes.func.isRequired
                        focused={dateFocused} // PropTypes.bool
                        onFocusChange={({ focused }) => setDateFocused(focused)} // PropTypes.func.isRequired
                        id="your_unique_id" // PropTypes.string.isRequired,
                        openDirection="up"
                        /> : null }
                    <div className="flex-row margin-top-1">
                        <div className={title && title.length > 0 && (!mandatory || (mandatory && endDate)) ? "btn btn-positive" : "btn btn-negative"} onClick={onSave} role="button" tabIndex="0">
                            {existingPost.id ? 'Update' : 'Post'}
                        </div>
                        <div className={"btn btn-negative-alt margin-left-2"} onClick={props.onCancel} role="button" tabIndex="0">
                            Cancel
                        </div>
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
}

export default CreatePost;