import { useStoreState } from "pullstate";
import React, { useRef, useState } from "react";
import Scrollbar from "react-scrollbars-custom";
import Success from '../../Assets/images/Success.svg';
import Customer from '../../Assets/images/values/Customer.png';
import Engagement from '../../Assets/images/values/Engagement.png';
import Operational from '../../Assets/images/values/Operational.png';
import Partnering from '../../Assets/images/values/Partnering.png';
import Performance from '../../Assets/images/values/Performance.png';
import Responsibility from '../../Assets/images/values/Responsibility.png';
import Safety from '../../Assets/images/values/Safety.png';
import ConfirmModal from "../../Components/ConfirmModal/ConfirmModal";
import ConfirmRecognitionModal from "../../Components/ConfirmRecognitionModal/ConfirmRecognitionModal";
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import MultiEmployeeLookup from "../../Components/EmployeeLookup/MultiEmployeeLookup";
import LimitedTextArea from "../../Components/LimitedTextArea/LimitedTextArea";
import { AccountStore } from "../../Stores/AccountStore";
import { CompanyValuesStore } from "../../Stores/CompanyValuesStore";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import APIFetch from '../../Utilities/APIFetch';
import './Recognition.scss';

const valueLookup = {
    "b0dcb8e0-d1de-4bd7-9e87-e9457b7ca705": Partnering,
    "76085e52-af11-4787-a66e-36cf9bbc241c": Customer,
    "3a4ab1e0-259b-479c-a9e6-3b604400d9e3": Operational,
    "b9915816-4466-4157-bc21-ce03dfd468fd": Engagement,
    "3d6b8439-1a7c-4e6c-a23a-442110a4d793": Safety,
    "1effd07f-d4f8-4ec9-932e-d983069a1e13": Responsibility,
    "886234fe-fa2a-4e67-ae09-8a476cbde82d": Performance
}

const Recognition = (props) => {
    const [sendingRecognition, setSendingRecognition] = useState(false);
    const companyValues = useStoreState(CompanyValuesStore, s => s.values);
    const [success, setSuccess] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const [confirmSpendModal, setConfirmSpendModal] = useState(false);
    const [confirmSendModal, setConfirmSendModal] = useState(false);
    const [valueId, setValueId] = useState(null);
    const [amountValue, setAmountValue] = useState(null);
    const [totalEmployees, setTotalEmployees] = useState(0);
    const sharePoints = useStoreState(AccountStore, s => s.status.sharePoints);
    const spendPoints = useStoreState(AccountStore, s => s.status.availablePoints);
    const role = useStoreState(AccountStore, s => s.status.role);

    let coinLimit = 200;
    var content = null;

    if(role === "CustomerRelations") {
        coinLimit = 500;
    } else if(role === "Admin") {
        // Admins have no limit so just use £1000
        coinLimit = 100000;
    }

    // Form elements
    const employeeRef = useRef(null);
    const commentRef = useRef(null);

    const onClose = () => {
        setSuccess(false);
    }

    const countLetters = (s) => {
        var count = 0;

        for(var i = 0; i < s.length; i++) {
            // If character has seperate upper and lowercase then it is a latin letter
            if(s[i].toLowerCase() != s[i].toUpperCase()) {
                count++;
            }
        }

        return count;
    }

    const sendRecognition = (recipient, amount, comment, valueId, confirmed, verified) => {
        if(!sendingRecognition) {
            if(!recipient || (!recipient.id && recipient.length < 1)) {
                setErrorText("Please select a colleague to send recognition to.");
                setErrorModal(true);
                return null;
            }

            if(!comment || comment.length === 0) {
                setErrorText("Please enter a comment.");
                setErrorModal(true);
                return null;
            }

            if(comment.length > 500) {
                setErrorText("Comment cannot exceed 500 characters in length.");
                setErrorModal(true);
                return null;
            }

            if(countLetters(comment) < 25) {
                setErrorText("Comment must contain a minimum of 25 letters (A-z).");
                setErrorModal(true);
                return null;
            }
            
            if(!valueId) {
                setErrorText("Please select a core value.");
                setErrorModal(true);
                return null;
            }

            // Default to 0 else parse
            amount = amount ? parseInt(amount) : 0;

            if(isNaN(amount)) {
                setErrorText("Awarded coins must be a valid number.");
                setErrorModal(true);
                return null;
            }

            if(amount < 0) {
                setErrorText("Awarded coins cannot be a negative value.");
                setErrorModal(true);
                return null;
            } else if(amount < 5 && amount > 0) {
                setErrorText("A minimum value of 5 coins must be awarded (or exactly 0).");
                setErrorModal(true);
                return null;
            }
            else if(amount > coinLimit) {
                setErrorText(`A maximum value of ${coinLimit} coins can be awarded.`);
                setErrorModal(true);
                return null;
            }
            

            if(amount > sharePoints) {
                if(amount <= (sharePoints + spendPoints)) {
                    if(!confirmed) {
                        setConfirmSpendModal([recipient, amount, comment, valueId, true]);
                        return null;
                    }
                } else {
                    setErrorText("You do not have enough coins left to award this many coins.");
                    setErrorModal(true);
                    return null;
                }
            }

            if(!verified) {
                setConfirmSendModal([recipient, amount, comment, valueId, confirmed, true]);
                return null;
            }

            setSendingRecognition(true);

            var isBatch = recipient.length > 1;

            APIFetch('POST', isBatch ? 'kudos/give/batch' : 'kudos/give', isBatch ? { recipientIds: recipient.map(r => r.id), amount, comment, valueId, confirmed } : { recipientId: recipient[0].id, amount, comment, valueId, confirmed })
            .then((result) => {
                if(result && result.ok) {
                    var failures = [];

                    Object.keys(result.data).forEach(k => {
                        if(result.data[k] === false) {
                            failures.push(k);
                        }
                    });

                    var finalAmount = isBatch ? amount * (recipient.length - failures.length) : amount;
                    var shareDeduction = finalAmount - sharePoints;

                    if(finalAmount > sharePoints) {
                        AccountStore.update(s =>  { s.status.sharePoints = 0 });
                    } else {
                        AccountStore.update(s =>  { s.status.sharePoints -= finalAmount });
                    }

                    if(shareDeduction > 0) {
                        AccountStore.update(s =>  { s.status.availablePoints -= shareDeduction });
                    }

                    if(failures.length > 0) {
                        var text = "Recognition MAY have failed to send to the following people: ";
                        failures.forEach(f => {
                            text += EmployeeStore.currentState[f].displayName + ', ';
                        });
                        setErrorText(text.substring(0, text.length - 2));
                        setErrorModal(true);
                        setSuccess({ recipient: recipient.filter(r => result.data[r.id] === true), amount, comment, valueId });
                        setValueId(null);
                        setAmountValue(0);
                    } else {
                        setSuccess({ recipient, amount, comment, valueId });
                        setValueId(null);
                        setAmountValue(0);
                    }
                }  else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                } else {
                    setErrorModal(true);
                }

                setSendingRecognition(false);
                setConfirmSendModal(false);
            })
            .catch((e) => {
                setConfirmSendModal(false);
                setErrorModal(true);
                setSendingRecognition(false);
            });
        }
    }

    if(success) {
        content = (
            <div className="content flex flex-grow min-height-0 padding-3">
                <div className="flex flex-center-both full-height flex-space-evenly padding-top-2">
                    <div className="redeem-medal">
                        <img src={Success} alt=""/>
                    </div>
                    <div className="flex flex-center-both">
                        <div className="text-align-center">
                            <p className="text-xxlg bold margin-bottom-1">Success!</p>
                            <p className="text-md text-grey">Way to go! Your recognition is on it's way to make someones day</p>
                        </div>
                        <div className="send-btn btn btn-positive margin-top-2" onClick={onClose} tabIndex="0" role="button">
                            Close
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        content = (
            <Scrollbar scrollerProps={{tabIndex: 0}}>
                <div className="content flex min-height-0 padding-3 padding-left-0 margin-right-4">
                    <div className="flex-row margin-top-2 margin-bottom-2">
                        <div className="employee-section flex-grow flex margin-right-1">
                            <label className="text-md margin-bottom-1">Who do you want to send recognition to?</label>
                            <MultiEmployeeLookup className="margin-bottom-2" target={props.target} ref={employeeRef} onChange={(employees) => { setTotalEmployees(employees.length) }}/>
                        </div>
                        <div className="coin-section flex-grow flex margin-left-1">
                            <label htmlFor="coins" className="text-md margin-bottom-1">{"Amount of share coins to award" + (role !== "Admin" ? ` (max ${coinLimit}).` : '.')}</label>
                            <div className="flex-row margin-bottom-1">
                                <div className={"btn btn-alt margin-right-1" + (amountValue === "0" ? " btn-positive" : "")} onClick={() => { setAmountValue("0") }}>0</div>
                                <div className={"btn btn-alt margin-right-1" + (amountValue === "20" ? " btn-positive" : "")} onClick={() => { setAmountValue("20") }}>20</div>
                                <div className={"btn btn-alt margin-right-1" + (amountValue === "50" ? " btn-positive" : "")} onClick={() => { setAmountValue("50") }}>50</div>
                                <div className={"btn btn-alt margin-right-1" + (amountValue === "100" ? " btn-positive" : "")} onClick={() => { setAmountValue("100") }}>100</div>
                                <div className={"btn btn-alt margin-right-1" + (amountValue === "200" ? " btn-positive" : "")} onClick={() => { setAmountValue("200") }}>200</div>
                            </div>
                            <input id="coins" type="number" min="0" max={coinLimit} className="margin-bottom-1 padding-right-2" defaultValue={0} value={amountValue} onChange={e => setAmountValue(e.target.value)} name="coins" />
                            {totalEmployees > 1 ? <div className="margin-left-1">Total Coins: {amountValue * totalEmployees}</div> : null}
                        </div>
                    </div>
                    <div className="flex margin-bottom-2">
                        <label htmlFor="comment" className="text-md margin-bottom-1">Include a publicly visible comment.</label>
                        <LimitedTextArea id="comment" placeholder="Comment" className="margin-bottom-2" ref={commentRef} maxLength={500}/>
                    </div>
                    <div className="flex">
                        <label htmlFor="company-value" className="text-md margin-bottom-1">Please select one of the TfW Rail Limited's core values from the list below that you want to give recognition for.</label>
                        <div id="company-value" className="flex-row company-values-list">
                            {companyValues.map(v => {
                                if(v.disabled === true) return null;

                                return <div key={v.id} tabIndex={0} role="button" className={"company-value-card box-shadow flex-row flex-align-center pointer padding-1" + (valueId === v.id ? ' active' : '')} onClick={() => setValueId(v.id)}>
                                    <img src={valueLookup[v.id]} className="margin-right-2" alt=""/>
                                    <div className="flex flex-center-both flex-grow">
                                        {v.welshName ? <p className="text-align-center margin-0 flex-grow bold">{v.welshName}</p> : null }
                                        <p className="text-align-center margin-0 flex-grow bold">{v.name}</p>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                    <div className="flex-row flex-item-bottom margin-bottom-1 margin-top-4">
                        <div tabIndex={0} className="send-btn btn btn-positive bold" onClick={() => { sendRecognition(employeeRef.current.getSelected(), amountValue, commentRef.current.value, valueId) }}>
                            Send
                        </div>
                    </div>
                </div>
            </Scrollbar>
        )
    }

    return (
        <div className="flex full-width recognition">
            <div className="text-align-left light text-grey">
                Send someone recognition for a job well done.
            </div>
            {content}
            {confirmSendModal ? <ConfirmRecognitionModal data={{ recipient: confirmSendModal[0], valueId: confirmSendModal[3], amount: confirmSendModal[1] }} onConfirm={() => { sendRecognition( ...confirmSendModal ); }} onClose={() => setConfirmSendModal(false) }/> : null }
            {confirmSpendModal ? <ConfirmModal title={"Use SPENDABLE coins?"} content={`You don't have enough share coins left to send this recognition.

Would you like to use ${(Array.isArray(confirmSpendModal[0]) ? confirmSpendModal[1] * confirmSpendModal[0].length : confirmSpendModal[1]) - sharePoints} of your SPEND coins?`} onClose={() => setConfirmSpendModal(false) } onConfirm={() => { sendRecognition( ...confirmSpendModal ); setConfirmSpendModal(false); }} /> : null }
            {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
        </div>
    );
};

export default Recognition;