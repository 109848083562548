import React, { useState } from 'react';
import RichTextEditor from 'react-rte';
import '../CreatePost/CreatePost.scss';
import APIFetch from '../../Utilities/APIFetch';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DetailsPopOver from "../../Components/DetailsPopOver/DetailsPopOver";
import Scrollbar from 'react-scrollbars-custom';

const CreateTerms = (props) => {
    var existingTerms = props.terms || {};
    const [title, setTitle] = useState(existingTerms.title || null);
    const [editorValue, setEditorValue] = useState(existingTerms.body ? RichTextEditor.createValueFromString(existingTerms.body, 'markdown') : RichTextEditor.createEmptyValue());
    const [errorModal, setErrorModal] = useState(false);
    const [active, setActive] = useState(existingTerms.active || false);

    const onChange = (value) => {
        setEditorValue(value);
    };

    const titleChange = (e) => {
        setTitle(e.target.value);
    };

    const onSave = () => {
        if(title && title.length > 0) {
            APIFetch('POST', existingTerms.id ? `terms/edit/${existingTerms.id}` : 'terms/create', { title: title, body: editorValue.toString('markdown'), active: active})
            .then((result) => {
                if(result && result.ok) {
                    if(props.onComplete) {
                        props.onComplete();
                    }
                } else {
                    setErrorModal(true);
                }
            })
            .catch(e => {
                setErrorModal(true);
            });
        }
    }

    return(
        <div className="create-post-wrapper">
            <Scrollbar>
                {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); }} title="Oops, something went wrong!" content={"An error occurred with your request, please try again later."}/> : null }
                <div className="create-post full-height flex">
                    <div className="pointer flex-row flex-align-center padding-left-1 margin-bottom-2" onClick={props.onCancel}>
                        <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                        <p className="margin-0 margin-left-1 text-md">Back</p>
                    </div>
                    <div className="text-grey text-lg bold margin-bottom-2">{existingTerms.id ? "Edit Usage Guidance" : "Create Usage Guidance"}</div>
                    <label className="text-md">Title</label>
                    <input type="text" placeholder="Enter Title" value={title} onChange={titleChange}/>
                    <label className="text-md">Content</label>
                    <RichTextEditor
                        value={editorValue}
                        onChange={onChange}
                        className="rte"
                        editorClassName="editor"
                        placeholder="Write Content Here"
                    />
                    <div className="selector box-shadow flex-row post-type-selector">
                        <div className={"selector-item text-md " + (!active ? " active" : "")} onClick={() => { setActive(false) }} tabIndex={0} role="button">
                            Inactive
                        </div>
                        <div className={"selector-item text-md " + (active ? " active" : "")} onClick={() => { setActive(true) }} tabIndex={0} role="button">
                            Active
                        </div>
                    </div>
                    <div className="flex-row margin-top-1">
                        <div className={title && title.length > 0 ? "btn btn-positive" : "btn btn-negative"} onClick={onSave} role="button" tabIndex="0">
                            {existingTerms.title ? 'Update' : 'Save'}
                        </div>
                        <div className={"btn btn-negative-alt margin-left-2"} onClick={props.onCancel} role="button" tabIndex="0">
                            Cancel
                        </div>
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
}

export default CreateTerms;