import React, { useState } from "react";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SocialPostItemAdmin.scss';
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import { faEye, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faHeart, faPencilAlt } from "@fortawesome/free-solid-svg-icons";

const SocialPostItemAdmin = (props) => {
    const p = props.post;
    const [confirmDelete, setConfirmDelete] = useState(false);

    const onDelete = () => {
        setConfirmDelete(false);
        props.onDelete();
    }

    return (
        <div className="social-item-admin margin-top-1 flex-row flex-center-both" role="group" tabIndex="0">
            { confirmDelete ? <ConfirmModal title={"Delete '" + p.title + "'?"} content={"Are you sure you wish to delete this post? This action cannot be reversed!"} onClose={() => setConfirmDelete(false) } onConfirm={onDelete} fixedPosition={true}/> : null }
            <div className="title-text margin-right-1 text-align-left text-md flex-grow">{p.title}</div>
            <p className="date-text margin-0 light text-sm text-dark margin-left-2">{moment(p.createdAt).calendar()}</p>
            <div className="flex-row flex-align-center">
                <div className="margin-left-1 flex-row flex-center-both margin-left-2" onClick={props.onReadMore} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faHeart} className="text-md text-alert margin-right-1"/>
                    <p className="text-grey">{p.likes}</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={props.onReadMore} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faEye} className="text-md text-alert margin-right-1"/>
                    <p>View</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={props.onEdit} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faPencilAlt} className="text-md text-alert margin-right-1"/>
                    <p>Edit</p>
                </div>
                <div className="margin-left-1 flex-row flex-center-both margin-left-2 pointer" onClick={() => setConfirmDelete(true)} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faTrashAlt} className="text-md text-alert margin-right-1"/>
                    <p>Delete</p>
                </div>
            </div>
        </div>
    );
};

export default SocialPostItemAdmin;