import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import APIFetch from '../../Utilities/APIFetch';
import { getProfile } from '../../Utilities/GraphAPI';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const MostActiveBar = (props) => {
    const [data, setData] = useState(null);
    const [startDate, setStartDate] = useState(moment(new Date('01/12/2020')).startOf('day'))
    const [endDate, setEndDate] = useState(moment(new Date()).startOf('day'));
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectValue, setSelectValue] = useState("0");

    useEffect(() => {
        getData();
    }, []);

    const getData = (from, to) => {
        if((!from && !startDate) || (!to && !endDate)) {
            return null;
        }

        APIFetch('POST', `reporting/${props.least ? 'least' : 'most'}active`, { from: from ? from.toISOString() : startDate.toISOString(), to: to ? to.toISOString() : endDate.toISOString() })
        .then((result) => {
            if(result && result.ok) {
                var promises = [];
                result.data.forEach(u => promises.push(getProfile(u.userId)
                .then(user => {
                    u.displayName = user.data.displayName;
                })));

                Promise.all(promises)
                .then(() => {
                    setData(result.data.map(d => ({
                        ...d,
                        'Recognition Sent': d.totalKudos,
                        'Activity Likes': d.totalActivityLikes,
                        'Social Likes': d.totalSocialLikes,
                        'Comments': d.totalComments,
                        'Comment Likes': d.totalCommentLikes,
                        'Redemptions': d.totalRedemptions
                    })));
                })
                .catch(() => {});
            }
        })
        .catch(e => {
        });
    }

    if(!data) {
        return <div>Loading...</div>;
    }

    const updateDates = (from, to) => {
        getData(from, to);
    }

    const updateSelect = (event) => {
        var value = event.target.value;

        setSelectValue(value);

        switch(value) {
            case "1":
                setStartDate(moment(new Date("2020-12-01T00:00:00Z")));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'years'), moment(new Date()));
                break;
            case "2":
                setStartDate(moment(new Date()).subtract(1, 'years').startOf('month'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'years'), moment(new Date()));
                break;
            case "3":
                setStartDate(moment(new Date()).subtract(1, 'months'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'months'), moment(new Date()));
                break;
            case "4":
                setStartDate(moment(new Date()).subtract(7, 'days'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(7, 'days'), moment(new Date()));
                break;
            default:
                break;
        }
    }

    return <div className="full-width full-height">
        <div className="flex-row flex-align-center">
            <DateRangePicker
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="most-active-start-date" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="most-active-end-date" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => { setSelectValue("0"); setStartDate(startDate); setEndDate(endDate); updateDates(startDate, endDate); }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(newFocusedInput) => { setFocusedInput(newFocusedInput); }} // PropTypes.func.isRequired,
                isOutsideRange={(day) => {
                    if(day.startOf('day').isAfter(moment(new Date()).startOf('day'))) {
                        return true;
                    }

                    if(focusedInput == "endDate" && startDate) {
                        var dateDiff = startDate.startOf('day').diff(day.startOf('day'), 'days');

                        if(dateDiff > 0) {
                            return true;
                        }
                    }

                    return false;
                }}
                displayFormat="DD/MM/YYYY"
                minimumNights={1}
            />
            <div className="select-wrapper">
                <select className="select-css" onChange={updateSelect} value={selectValue}>
                    <option value="0">
                        Custom
                    </option>
                    <option value="1">
                        All Time
                    </option>
                    <option value="2">
                        Past Year
                    </option>
                    <option value="3">
                        Past Month
                    </option>
                    <option value="4">
                        Past Week
                    </option>
                </select>
                <FontAwesomeIcon className="chevron" icon={faChevronDown} />
            </div>
        </div>
        <ResponsiveBar 
            data={data}
            keys={['Recognition Sent', 'Activity Likes', 'Social Likes', 'Comments', 'Comment Likes', 'Redemptions']}
            indexBy="displayName"
            margin={{ top: 25, right: 140, bottom: 75, left: 60 }}
            padding={0.3}
            colors={{ scheme: 'category10' }}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 25,
                legend: 'User',
                legendPosition: 'middle',
                legendOffset: 60
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `Total Activity Count`,
                legendPosition: 'middle',
                legendOffset: -45
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20
                }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
        />
    </div>
}

export default MostActiveBar;