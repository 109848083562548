import React from "react";
import { faLongArrowAltRight, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../DetailsPopOver/DetailsPopOver.scss';
import './ConfirmRecognitionModal.scss';
import { useStoreState } from "pullstate";
import { AccountStore } from "../../Stores/AccountStore";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import Scrollbar from "react-scrollbars-custom";
import { Tooltip } from "react-tippy";
import appConfig from "../../config.json";

const ConfirmAwardModal = (props) => {
    const details = useStoreState(AccountStore, s => s.profile);
    var data = props.data;
    var award = props.awards.find(a => a.id === data.awardId);

    return (
        <div className={"modal confirm-modal confirm-rec-modal popover-modal"}>
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={props.onClose}/>
                    </div>
                </div>
                <div className="details-popover">
                    <Scrollbar>
                        <div className="details-block flex flex-align-center">
                            <div className="flex-row flex-align-center">
                                <img src={details && details.image ? details.image : defaultProfileImage} className="profile-image-lg margin-right-1" alt=""/>
                                <FontAwesomeIcon className="arrow-icon" icon={faLongArrowAltRight} />
                                <img src={data.recipient && data.recipient.image ? data.recipient.image : defaultProfileImage} className="profile-image-lg margin-right-1" alt=""/>
                            </div>
                            <div className="flex flex-align-center margin-top-2">
                                <div className="bold margin-top-1">You are about to send</div>
                                <div className="value-text text-align-center margin-bottom-1">
                                    {Array.isArray(data.recipient) ? data.recipient.map(r => { return <div key={r.id}>{r.displayName}</div> }) : data.recipient.displayName}
                                </div>
                            </div>
                            <div className="flex flex-align-center margin-bottom-1">
                                <div className="bold margin-top-1">The award</div>
                                {award.description ?
                                    <Tooltip
                                        html={(
                                            <div style={{ top: 10, width: 260 }}>
                                                <div className="tooltip-text">{award.description || "No Description"}</div>
                                            </div>
                                        )}
                                        position="top"
                                        className={"badge"}
                                        style={{ display: 'flex' }}
                                        key={award.id}>
                                        <div tabIndex={0} className={"company-value-card box-shadow flex-row flex-align-center padding-1"}>
                                            <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + award.name + '.png'} className="margin-left-1 margin-right-1" style={{ width: 60, height: 60}} alt=""/>
                                            <p className="text-align-center margin-0 flex-grow bold">{award.displayName}</p>
                                        </div>
                                    </Tooltip>
                                :
                                    <div key={award.id} tabIndex={0} className={"company-value-card box-shadow flex-row flex-align-center padding-1"}>
                                        <img src={appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_ASSET_PATH + 'assets/badges/' + award.name + '.png'} className="margin-left-1 margin-right-1" style={{ width: 60, height: 60}} alt=""/>
                                        <p className="text-align-center margin-0 flex-grow bold">{award.displayName}</p>
                                    </div>
                                }
                            </div>
                            <div className="flex flex-align-center margin-bottom-2">
                                <div className="bold margin-top-1">With the coin value</div>
                                <div className="value-text">{data.amount} coins</div>
                                {data.recipient.length > 1 ? <div className="value-text">({data.recipient.length * data.amount} total)</div> : null}
                            </div>
                            <div className="bold margin-top-1">Please click to confirm or edit your choices</div>
                        </div>
                        <div className="flex-row flex-justify-center margin-bottom-1">
                            <div className="btn btn-alert margin-left-2 " onClick={props.onConfirm} role="button" tabIndex="0">
                                <p className="bold margin-top-1 margin-bottom-1">Confirm</p>
                            </div>
                            <div className="btn btn-negative margin-left-2" onClick={props.onClose} role="button" tabIndex="0">
                                <p className="bold margin-top-1 margin-bottom-1">Edit</p>
                            </div>
                        </div>
                    </Scrollbar>
                </div>
            </div>
        </div>
        
    );
};

export default ConfirmAwardModal;