import React, { useState } from "react";
import { useStoreState } from "pullstate";
import { AccountStore } from '../../Stores/AccountStore';
import './ConfirmDetailsModal.scss';
import APIFetch from "../../Utilities/APIFetch";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';

const ConfirmDetailsModal = (props) => {
    const [isVerifying, setIsVerifying] = useState(false);
    const details = useStoreState(AccountStore, s => s.profile) || {};

    const verifyDetails = () => {
        if(!isVerifying) {
            setIsVerifying(true);
            APIFetch('POST', 'user/verifydetails')
            .then((result) => {
            if(result && result.ok) {
                AccountStore.update(s => { s.status.detailsVerified = true });
            }

            props.onClose();
            })
            .catch(e => {
                props.onClose();
            });
        }
    }

    const inaccurateDetails = () => {
        if(!isVerifying) {
            setIsVerifying(true);
            APIFetch('POST', 'user/inaccuratedetails')
            .then(() => {
                props.onClose();
            })
            .catch(e => {
                props.onClose();
            });
        }
    }

    return (
        <div className="modal confirm-details modal-white">
            <div className="modal-box no-close flex flex-align-center" style={{ overflowY: 'auto' }}>
                <div className="flex-row flex-grow padding-6">
                    <div className="flex margin-right-5 flex-grow flex-basis-33">
                        <div className="text-lg margin-bottom-2">Please check your details and confirm if they are correct.</div>
                        <div className="text-sub">If any details are not correct, please contact your system administrator to rectify the issue.</div>
                        <img className="profile-image-xlg" src={details.image || defaultProfileImage } alt="Avatar"/>
                    </div>
                    <div className="flex flex-grow flex-basis-66">
                        <div className="detail-row margin-top-2">
                            <span className="text-sub">Name: </span>
                            <span className="text-md">{details.displayName}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Job Title: </span>
                            <span className="text-md">{details.jobTitle}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Department: </span>
                            <span className="text-md">{details.department}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Location: </span>
                            <span className="text-md">{details.officeLocation}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Email: </span>
                            <span className="text-md">{details.mail}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Work Phone Number: </span>
                            <span className="text-md">{details.businessPhones ? details.businessPhones[0] : 'None'}</span>
                        </div>
                        <div className="detail-row">
                            <span className="text-sub">Work Mobile Number: </span>
                            <span className="text-md">{details.mobilePhone}</span>
                        </div>
                    </div>
                </div>
                <div className="button-box flex-row flex-item-bottom padding-4">
                    <div className="flex flex-grow text-sub bold flex-justify-center">Are your details above correct?</div>
                    <div className="flex-grow flex-row flex-center-both">
                        <div className="btn btn-flex btn-positive bold margin-right-2" onClick={verifyDetails} tabIndex="0" role="button">
                            Yes
                        </div>
                        <div className="btn btn-flex btn-negative btn-negative" onClick={inaccurateDetails} tabIndex="0" role="button">
                            No
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmDetailsModal;