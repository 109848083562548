import Carousel from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { navigate } from "@gatsbyjs/reach-router";
import React, { useState } from 'react';
import hubLogo from '../../Assets/images/HubLogo.png';
import DashImg from '../../Assets/images/tutorial/dashboard.png';
import IconsImg from '../../Assets/images/tutorial/icons.png';
import RecognitionImg from '../../Assets/images/tutorial/recognition.png';
import RedeemImg1 from '../../Assets/images/tutorial/redeem1.png';
import RedeemImg2 from '../../Assets/images/tutorial/redeem2.png';
import RedeemImg3 from '../../Assets/images/tutorial/redeem3.png';
import RedeemImg4 from '../../Assets/images/tutorial/redeem4.png';
import CreateSocialPost1 from '../../Assets/images/tutorial/CreateSocialPost1.png';
import CreateSocialPost2 from '../../Assets/images/tutorial/CreateSocialPost2.png';
import Admin1 from '../../Assets/images/tutorial/Admin1.png';
import Awards1 from '../../Assets/images/tutorial/Awards1.png';
import Reporting1 from '../../Assets/images/tutorial/Reporting1.png';
import AllocateCoins1 from '../../Assets/images/tutorial/AllocateCoins1.png';
import AllocateCoins2 from '../../Assets/images/tutorial/AllocateCoins2.png';
import './Tutorial.scss';
import { useStoreState } from 'pullstate';
import { AccountStore } from '../../Stores/AccountStore';

const Tutorial = () => {
    const [landing, setLanding] = useState(true);
    const role = useStoreState(AccountStore, s => s.status.role);
    var pages = [];

    const onClose = () => {
        navigate('dashboard');
    }

    if(landing) {
        return (
            <div className="tutorial landing">
                <p className="title">Welcome to</p>
                <img className="logo" src={hubLogo} alt="Cwtsh Logo" />
                <p className="logo-text">Cwtsh</p>
                <p className="content">The Cwtsh app allows you to send recognition to colleagues for a job well done and receive recognition for demonstrating excellence across the TfW Rail Limited's core values.</p>
                <p className="content">This recognition is sent and received in the form of coins, which can be exchanged to redeem gift cards.</p>
                <p className="content">100 coins = £1</p>
                <div className="btn btn-alert inline-block margin-top-2 margin-bottom-2 flex-item-right flex-item-left" onClick={() => setLanding(false)} role="button" tabIndex="0">
                    Lets get started
                </div>
            </div>
        );
    }

    pages.push(<div className="page text-align-center" key={0}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Dashboard</h2>
        </div>
        <img src={DashImg} alt="" />
    </div>);

    pages.push(<div className="page text-align-center" key={1}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Icons</h2>
        </div>
        <img src={IconsImg} alt=""/>
    </div>);

    pages.push(<div className="page text-align-center" key={2}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Recognition</h2>
        </div>
        <img src={RecognitionImg} alt="" />
    </div>);

    pages.push(<div className="page text-align-center" key={3}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Redeeming Rewards</h2>
        </div>
        <img src={RedeemImg1} alt="" />
    </div>);

    pages.push(<div className="page text-align-center" key={4}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Redeeming Rewards</h2>
        </div>
        <img src={RedeemImg2} alt="" />
    </div>);
    
    pages.push(<div className="page text-align-center" key={5}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Redeeming Rewards</h2>
        </div>
        <img src={RedeemImg3} alt="" />
    </div>);

    pages.push(<div className="page text-align-center" key={9}>
        <div className="titles">
            <h1>Tutorial</h1>
            <h2>Redeeming Rewards</h2>
        </div>
        <img src={RedeemImg4} alt="" />
    </div>);

    if(role === "Admin") {
        pages.push(<div className="page text-align-center" key={10}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Admin</h2>
            </div>
            <img src={Admin1} alt="" />
        </div>);

        pages.push(<div className="page text-align-center" key={11}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Reporting</h2>
            </div>
            <img src={Reporting1} alt="" />
        </div>);

        pages.push(<div className="page text-align-center" key={12}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Social Posts</h2>
            </div>
            <img src={CreateSocialPost1} alt="" />
        </div>);

        pages.push(<div className="page text-align-center" key={13}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Create Social Posts</h2>
            </div>
            <img src={CreateSocialPost2} alt="" />
        </div>);

        pages.push(<div className="page text-align-center" key={14}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Allocate Coins</h2>
            </div>
            <img src={AllocateCoins1} alt="" />
        </div>);

        pages.push(<div className="page text-align-center" key={15}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Allocate Coins</h2>
            </div>
            <img src={AllocateCoins2} alt="" />
        </div>);
    }

    if(role === "Admin" || role === "CustomerRelations") {
        pages.push(<div className="page text-align-center" key={16}>
            <div className="titles">
                <h1>Tutorial</h1>
                <h2>Awards</h2>
            </div>
            <img src={Awards1} alt="" />
        </div>);
    }

    return(
        <div className="tutorial">
            <FontAwesomeIcon icon={faTimes} onClick={onClose} className="close-icon" role="button" tabIndex="0"/>
            <Carousel 
                arrows 
                dots>
                {pages}
            </Carousel>
        </div>
    )
}

export default Tutorial;