import React, { useState, useEffect } from "react";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { useStoreState } from "pullstate";
import { getProfile } from "../../Utilities/GraphAPI";
import moment from 'moment';
import { AccountStore } from "../../Stores/AccountStore";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import DetailsPopOver from "../DetailsPopOver/DetailsPopOver";
import APIFetch from "../../Utilities/APIFetch";
import { ActionStore } from "../../Stores/ActionStore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const typeLookup = [
    'Everyone',
    'Role',
    'Individual'
]

const DistributionItem = (props) => {
    var d = props.distribution;
    const initiator = useStoreState(EmployeeStore, s => { return s[d.userId] || null });
    const approver = useStoreState(EmployeeStore, s => { return s[d.reviewedBy] || null });
    const individual = useStoreState(EmployeeStore, s => { return s[d.identifier]|| null });
    const [errorModal, setErrorModal] = useState(false);
    const [errorText, setErrorText] = useState(null);
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const [confirmCallback, setConfirmCallback] = useState(null);
    const [confirmTitle, setConfirmTitle] = useState(null);
    const [confirmContent, setConfirmContent] = useState(null);
    var targetText = null;

    const onProfileClick = (user) => {
        if(user) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: user }) });
        }
    }

    const approve = (d, total, confirmed) => {
        if(confirmed) {
            setConfirmTitle(null);
            setConfirmContent(null);
            setConfirmCallback(null);

            APIFetch('POST', `system/approve/${d.id}`)
            .then((result) => {
                if(result && result.ok) {
                    if(props.onUpdate) {
                        props.onUpdate();
                    }
                } else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                }
            })
            .catch(e => {
            });
        } else {
            setConfirmTitle("Approve Distribution");
            setConfirmContent(`Are you sure you wish to approve this distibution? ${total} (£${total/100}) coins will be allocated.`);
            setConfirmCallback(() => { return () => { approve(d, total, true); }});
        }
    }

    const cancel = (d, confirmed) => {
        if(confirmed) {
            setConfirmTitle(null);
            setConfirmContent(null);
            setConfirmCallback(null);

            APIFetch('DELETE', `system/cancel/${d.id}`)
            .then((result) => {
                if(result && result.ok) {
                    if(props.onUpdate) {
                        props.onUpdate();
                    }
                } else if(result.status === 400) {
                    result.data.text()
                    .then(text => {
                        setErrorText(text);
                        setErrorModal(true);
                    })
                    .catch(() => {
                        setErrorModal(true);
                    });
                }
            })
            .catch(e => {
            });
        } else {
            setConfirmTitle("Cancel Distribution");
            setConfirmContent("Are you sure you wish to cancel this distibution?");
            setConfirmCallback(() => { return () => { cancel(d, true); }});
        }
    }

    if(!initiator) {
        getProfile(d.userId)
        .catch(e => {});
    }

    if(!approver && d.reviewedBy !== '00000000-0000-0000-0000-000000000000') {
        getProfile(d.reviewedBy)
        .catch(e => {});
    }

    if(!individual && d.type === 2) {
        getProfile(d.identifier)
        .catch(e => {});
    }

    switch(d.type) {
        case 0: 
            targetText = <p>{typeLookup[d.type]}</p>;
            break;
        case 1:
            targetText = <p>{d.identifier}</p>;
            break;
        case 2: 
            targetText = <p><span className="underline pointer" onClick={() => onProfileClick(individual)}>{individual ? individual.displayName : d.identifier}</span></p>
            break;
        default: targetText = <p>{d.identifier}</p>;
    }

    return (
        <div className="distribution-item" tabIndex={0} role="group">
            <p><span className="underline pointer" onClick={() => onProfileClick(initiator)} tabIndex={0} role="button">{initiator ? initiator.displayName : d.userId}</span></p>
            <p>{moment(d.createdAt).format('L')}</p>
            {targetText}
            <p style={d.isSpendable ? { fontWeight: 'bold' } : null}>{d.isSpendable ? "Spend Award" : (d.isTopUp ? "Share Top-Up" : "Share Award")}</p>
            <p>{d.amount || '-'}</p>
            <p>{d.totalAmount || '-'}</p>
            <p><span className="underline pointer" onClick={() => onProfileClick(approver)} tabIndex={0} role="button">{d.reviewedBy === '00000000-0000-0000-0000-000000000000' ? '-' : (d.reviewedBy === myId ? 'You' : (approver ? approver.displayName : d.reviewedBy))}</span></p>
            <p>{!d.reviewedAt || d.reviewedAt === "0001-01-01T00:00:00Z" ? '-' : moment(d.reviewedAt).format('L')}</p>
            <p>{d.cancelled ? 'CANCELLED' : (d.approved ? 'COMPLETE' : 'PENDING')}</p>
            <p className="actions">
                <FontAwesomeIcon key="" icon={faThumbsUp} style={{ marginRight: 15 }} className={"pointer" + (!d.cancelled && !d.approved && d.userId !== myId ? "" : " disabled")} onClick={() => { if(!d.cancelled && !d.approved && d.userId !== myId) { approve(d, d.totalAmount) } }} tabIndex={0} role="button"/>
                <FontAwesomeIcon icon={faTrash} className={"pointer" + (!d.cancelled && !d.approved ? "" : " disabled")} key="cancel" onClick={() => { if(!d.cancelled && !d.approved) { cancel(d) }}} tabIndex={0} role="button"/>
            </p>
            {errorModal ? <DetailsPopOver onClose={() => { setErrorModal(false); setErrorText(null); }} title="Oops, something went wrong!" content={errorText || "An error occurred with your request, please try again later."}/> : null }
            {confirmTitle && confirmContent ? <ConfirmModal title={confirmTitle} content={confirmContent} confirmText="Yes" cancelText="No" onClose={() => { setConfirmTitle(null); setConfirmContent(null); setConfirmCallback(null); } } onConfirm={confirmCallback} /> : null }
        </div>
    )
}

export default DistributionItem;