import React, { useState } from 'react';
import './LimitedTextArea.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

const LimitedTextArea = React.forwardRef((props, ref) => {
    const [characterCount, setCharacterCount] = useState(0);
    const [content, setContent] = useState('');

    const onChange = (e) => {
        setCharacterCount(e.target.value.length);
        setContent(e.target.value);
    }

    return <div className={"limited-text-area " + (props.className || "")}>
        <textarea id={props.id} placeholder={props.placeholder || ""} ref={ref} onChange={onChange}/>
        <p className="textarea-backing">{content.length <= props.maxLength ? content : <span>{content.substring(0, props.maxLength)}<span className="highlight">{content.substring(props.maxLength, content.length)}</span></span>}</p>
        <p className={"count " + (characterCount >= props.maxLength ? "max" : "")}>{characterCount}/{props.maxLength}{characterCount > props.maxLength ? <FontAwesomeIcon aria-label="" icon={faExclamationCircle} color='red'/> : null}</p>
    </div>
});

export default LimitedTextArea;