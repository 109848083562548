import { faComment, faHeart, faTimes, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from "moment";
import { useStoreState } from "pullstate";
import React, { useState, useRef, useEffect } from "react";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from '../../Stores/ActionStore';
import { EmployeeStore } from "../../Stores/EmployeeStore";
import APIFetch from "../../Utilities/APIFetch";
import { getProfile } from "../../Utilities/GraphAPI";
import Badge from "../Badge/Badge";
import CommentsSection from '../CommentsSection/CommentsSection';
import LikeList from '../LikeList/LikeList';

const BadgeItem = (props) => {
    const b = props.badge;
    const employee = useStoreState(EmployeeStore, s => { return s[b.userId] || null });
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const [liking, setLiking] = useState(false);
    const [showLikes, setShowLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(!props.badge.muted);
    const [menu, setMenu] = useState(false);
    const [muting, setMuting] = useState(false);
    const wrapperRef = useRef(null);

    if(employee === null) {
        getProfile(b.userId)
        .catch(e => {});
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if(menu && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, menu, setMenu]);

    const onProfileClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: employee }) });
    }

    const onBadgeClick = () => {
        ActionStore.update(s => { s.modals.push({ type: 'Badges', data: employee }) });
    }

    const toggleNotifications = () => {
        if(!muting) {
            setMuting(true);
            setMenu(false);

            if(notificationsEnabled) {
                APIFetch("POST", `comment/mute/${b.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(false);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            } else {
                APIFetch("POST", `comment/unmute/${b.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(true);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            }
        }
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(b.userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${b.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(false);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${b.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(true);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const viewLikes = () => {
        if(b.likes > 0) {
            setShowLikes(!showLikes);
        }
    }

    const viewComments = () => {
        setShowComments(!showComments);
    }

    return (
        <div className="kudos-item margin-top-1 margin-top-first-0">
            <div className={"card flex-row flex-align-center padding-1 card-alert " + (props.className || '')} style={{ borderRadius: 20 }} role="group" tabIndex="0">
                <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-sm pointer margin-1 flex-shrink-0" tabIndex="0" onClick={onProfileClick} alt=""/>
                <div className="flex text-align-left flex-shrink-1 margin-right-1">
                    <p className="margin-0 margin-left-2 bold"><span className="underline pointer" tabIndex="0" onClick={onProfileClick}>{employee ? (employee.id === myProfile.id ? 'You' : employee.displayName || "Someone" ) : "Someone"}</span> earned a new badge!</p>
                    <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{moment(b.createdAt).calendar()}</p>
                </div>
                <div className="pointer" onClick={onBadgeClick}>
                    <Badge badge={b} withTitle={true} className="flex-row flex-align-center" imageClassName="margin-left-2 mini-card"/>
                </div>
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (b.userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex="0" aria-label="Like" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (b.likes > 0 ? " pointer underline bold" : "")} onClick={viewLikes} tabIndex="0" onBlur={() => setShowLikes(false)}>{b.likes}</p>
                    {showLikes ? <LikeList id={b.id} onClose={() => setShowLikes(false)} /> : null}
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={showComments ? faTimes : faComment} className={"text-lg pointer text-grey"} tabIndex="0" onClick={viewComments} aria-label="Comments" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (b.comments > 0 ? " pointer underline bold" : "")} onClick={viewComments} tabIndex="0">{b.comments}</p>
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 absolute" style={{ right: '34px' }}>
                    <FontAwesomeIcon icon={faEllipsisH} className={"text-lg pointer text-grey"} style={{ width: "30px" }} tabIndex="0" onClick={() => setMenu(!menu)} aria-label="Show post options" role="button"/>
                </div>
                {menu ? <div className="card-menu" tabIndex={0} onBlur={() => setMenu(false)} ref={wrapperRef}>
                            <div className="pointer bold" onClick={toggleNotifications}>
                                {notificationsEnabled ? "Turn off notifications for this post" : "Turn on notifications for this post"}
                            </div>
                        </div> : null }
            </div>
            {showComments ? <CommentsSection activityId={b.id} employeeId={b.recipientId}/> : null }
        </div>
    );
};

export default BadgeItem;