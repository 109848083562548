import React, { useState } from 'react';
import { faChevronLeft, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Reporting.scss';
import UserActivityLine from './UserActivityLine';
import UserActivityStream from './UserActivityStream';
import CompanyValuePie from './CompanyValuePie';
import BrandBar from './BrandBar';
import MostActiveBar from './MostActiveBar';
import Scrollbar from 'react-scrollbars-custom';
import DataList from './DataList';
import APIFetch from '../../Utilities/APIFetch';
import LoadingOverlay from '../../Components/LoadingOverlay/LoadingOverlay';
import { useStoreState } from 'pullstate';
import { AccountStore } from '../../Stores/AccountStore';
import 'react-dates/lib/css/_datepicker.css';

const yearOptions = [];

for(var i = 2020; i <= new Date().getFullYear(); i++) {
    yearOptions.push(i.toString());
}

const reports = [
    {
        name: 'Total Recognition Counts',
        component: null
    },
    {
        name: 'Active Users (Daily)',
        component: <UserActivityLine key={'ActiveUsersDaily'} endpoint={"activeusers"} period={"daily"} yLabel={"Active Users"}/>
    },
    {
        name: 'Active Users (Monthly)',
        component: <UserActivityLine key={'ActiveUsersMonthly'} endpoint={"activeusers"} period={"monthly"} yLabel={"Active Users"}/>
    },
    {
        name: 'Total Activity (Daily)',
        component: <UserActivityStream key={'TotalActivityDaily'} endpoint={"totalactivity"} period={"daily"} />
    },
    {
        name: 'Total Activity (Monthly)',
        component: <UserActivityStream key={'TotalActivityMonthly'} endpoint={"totalactivity"} period={"monthly"} />
    },
    {
        name: 'Total Coins Received by Individual',
        component: <DataList key={'ReceivedList'} type="User" endpoint={'reporting/received/list'} fields={['total']}/>
    },
    {
        name: 'Total Coins Sent by Individual',
        component: <DataList key={'SentList'} type="User" endpoint={'reporting/sent/list'} fields={['total']}/>
    },
    {
        name: 'Total Coins Awarded by Company Value',
        component: <CompanyValuePie key={'CompanyValue'} />
    },
    {
        name: 'Total Redemptions by Brand',
        component: <BrandBar key={'RedemptionsByBrand'}/>
    },
    {
        name: 'Most Active Users',
        component: <MostActiveBar key={'MostActive'} least={false}/>
    },
    {
        name: 'Least Active Users',
        component: <MostActiveBar key={'LeastActive'} least={true}/>
    },
    {
        name: 'Departments Sent',
        component: <DataList key={'DeptSent'} type="Department" endpoint={'reporting/department/sent'} fields={['total']} />
    },
    {
        name: 'Departments Received',
        component: <DataList key={'DeptRec'} type="Department" endpoint={'reporting/department/received'} fields={['total']} />
    },
    {
        name: 'User Coin Pots',
        component: <DataList key={'CoinPots'} type="User" endpoint={'reporting/pointtotals'} fields={['sharePot', 'spendPot']} />
    }
]

const Reporting = (props) => {
    const role = useStoreState(AccountStore, s => s.status.role);
    const [currentReport, setCurrentReport] = useState(null);
    const [downloading, setDownloading] = useState(false);
    const [yearSelect, setYearSelect] = useState("2020");
    var reportButtons = [];

    reports[0].component = <div className="download-selector">
        <div className="label">Select a year to download the stats for:</div>
        <div className="selector">
            <select value={yearSelect} onChange={(e) => { setYearSelect(e.target.value) }}>
                {yearOptions.map(y => {
                    return <option value={y}>{y}</option>
                })}
            </select>
            <div className="pointer" onClick={() => { download('export/recognition/counts/' + yearSelect, 'Total Recognition Counts ' + yearSelect) }}>
                <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '15px', fontSize: "24px" }}/>
            </div>
        </div>
    </div>;

    const download = (url, fileName) => {
        if(!downloading) {
            setDownloading(true);
            APIFetch("GET", url)
            .then(result => {
                if(result && result.ok) {
                    // Download
                    var currentDate = new Date();
                    var dateString = ("0" + currentDate.getDate()).slice(-2) + ("0" + (currentDate.getMonth() + 1)).slice(-2) + currentDate.getFullYear();

                    var blob = new Blob([result.data]);
                    var url = URL.createObjectURL(blob);
                    var downloadLink = document.createElement("a");
                    downloadLink.href = url;
                    downloadLink.download = `${fileName} - ${dateString}.csv`;

                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                }

                setDownloading(false);
            })
            .catch(e => {
                setDownloading(false);
            })
        }
    }

    for(let i = 0; i < reports.length; i++) {
        reportButtons.push(<div key={i} className={"report-card box-shadow flex-row flex-align-center pointer padding-1" + (currentReport === i ? ' active' : '')} onClick={() => setCurrentReport(i)} tabIndex={0} role="button">
            <p className="text-align-center margin-0 flex-grow bold">{reports[i].name}</p>
        </div>);
    }

    return <div className="flex full-height reporting">
        <div className="pointer flex-row flex-align-center padding-left-1 flex-item-left" onClick={props.onBack} tabIndex={0} role="button">
            <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
            <p className="margin-0 margin-left-1 text-md">Back</p>
        </div>
        <div className="flex-row report-container">
            <Scrollbar className="button-scroll" scrollerProps={{tabIndex: 0, role: "group" }}>
                { role === 'Admin' ? <div className="report-card box-shadow flex-row flex-align-center pointer padding-1 bold flex-justify-center" onClick={() => { download('export/suspiciousactivity', 'Suspicious Activity Data') }}>Suspicious Activity Data <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }}/></div> : null }
                { role === 'Admin' ? <div className="report-card box-shadow flex-row flex-align-center pointer padding-1 bold flex-justify-center" onClick={() => { download('export/userpots', 'User Pot Data') }}>User Pot Data <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }}/></div> : null }
                { role === 'Admin' ? <div className="report-card box-shadow flex-row flex-align-center pointer padding-1 bold flex-justify-center" onClick={() => { download('export/recognition', 'Recognition Sent Data') }}>Recognition Sent Data <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }}/></div> : null }
                { role === 'Admin' ? <div className="report-card box-shadow flex-row flex-align-center pointer padding-1 bold flex-justify-center" onClick={() => { download('export/awards', 'Awards Sent Data') }}>Awards Sent Data <FontAwesomeIcon icon={faDownload} style={{ marginLeft: '5px' }}/></div> : null }
                {reportButtons}
            </Scrollbar>
            <div className="full-height full-width">
                { currentReport === null ? <div>Select a report to view it.</div> :  reports[currentReport].component }
            </div>
        </div>
        {downloading ? <LoadingOverlay /> : null }
    </div>
}

export default Reporting;