import React, { useEffect, useState } from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { EmployeeStore } from '../../Stores/EmployeeStore';
import APIFetch from '../../Utilities/APIFetch';
import { getProfile, getProfileBatch } from "../../Utilities/GraphAPI";

const DataList = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        APIFetch('GET', props.endpoint)
        .then(async (result) => {
            if(result && result.ok) {
                setData(result.data);
                if(props.type === 'User') {
                    var items = [...result.data];

                    // Batch
                    var users = [];
                    for(let i = 0; i < result.data.length; i++) {
                        var id = result.data[i].key;
                        if(!EmployeeStore.currentState[id]) {
                            users.push(id);
                        }
                    }

                    // Max batch is 20 requests so do 20 at a time until <= 20 remain
                    // TODO: i is index of users not result.data. Means UI will be behind data.
                    var batchSize = 20;
                    var start = 0;
                    while(users.length > batchSize) {
                        var batch = users.splice(0, batchSize);

                        await getProfileBatch(batch)
                        .then(async () => {
                            items = [...items];

                            // Get profiles from cache
                            for(let i = start; i < start + batchSize; i++) {
                                var profile = await getProfile(result.data[i].key, false);
                                if(profile && profile.data && profile.data.displayName) {
                                    items[i].displayName = profile.data.displayName;
                                }
                            }

                            setData(items);
                        })
                        .catch(e => {
                            //
                        });

                        start += batchSize;
                    }

                    // Do remaining <= 20 users
                    getProfileBatch(users)
                    .then(async () => {
                        items = [...items];

                        // Get profiles from cache
                        for(let i = start; i < result.data.length; i++) {
                            var profile = await getProfile(result.data[i].key, false);
                            if(profile && profile.data && profile.data.displayName) {
                                items[i].displayName = profile.data.displayName;
                            }
                        }

                        setData(items);
                    })
                    .catch(e => {
                        //
                    });
                }
            }
        })
        .catch(e => {
        });
    }, []);

    if(!data) {
        return <div>Loading...</div>;
    }

    return <Scrollbar>
        <table className="data-table">
            <thead>
                <tr>
                    <th>{props.type}</th>
                    <th>{props.fields[0].charAt(0).toUpperCase() + props.fields[0].slice(1)}</th>
                    {props.fields.length > 1 ? <th>{props.fields[1].charAt(0).toUpperCase() + props.fields[1].slice(1)}</th> : null}
                </tr>
            </thead>
            <tbody>
                {data.map(d => {
                    return <tr key={d.key}>
                            <td>{d.displayName || d.key || 'Unknown'}</td>
                            <td>{props.formatters && props.formatters.length > 0 ? props.formatters[0](d[props.fields[0]]) : d[props.fields[0]]}</td>
                            {props.fields && props.fields.length > 1 ? <td>{props.formatters && props.formatters.length > 1 ? props.formatters[1](d[props.fields[1]]) : d[props.fields[1]]}</td> : null}
                        </tr>
                })}
            </tbody>
        </table>
    </Scrollbar>
}

export default DataList;