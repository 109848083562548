import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie'
import APIFetch from '../../Utilities/APIFetch';
import { CompanyValuesStore } from '../../Stores/CompanyValuesStore';
import { useStoreState } from 'pullstate';

const CompanyValuePie = (props) => {
    const [data, setData] = useState(null);
    const companyValues = useStoreState(CompanyValuesStore, s => s.values);

    useEffect(() => {
        APIFetch('GET', `reporting/companyvalue`)
        .then((result) => {
            if(result && result.ok) {
                result.data.forEach(cV => {
                    var match = companyValues.find(v => v.id === cV.key);
                    cV.label = match ? match.name : cV.key;
                    cV.id = cV.label;
                    cV.value = cV.total;
                });
                
                setData(result.data);
            }
        })
        .catch(e => {
        });
    }, [companyValues]);

    if(!data) {
        return <div>Loading...</div>;
    }

    return <ResponsivePie
            data={data}
            margin={{ top: 100, right: 80, bottom: 100, left: 80 }}
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors={{ scheme: 'category10' }}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
    />
}

export default CompanyValuePie;