import React, { useState, useRef, useEffect } from "react";
import moment from "moment";

import { useStoreState } from "pullstate";
import { CompanyValuesStore } from "../../Stores/CompanyValuesStore";
import { EmployeeStore } from "../../Stores/EmployeeStore";
import { ActionStore } from '../../Stores/ActionStore';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';

import { getProfile } from "../../Utilities/GraphAPI";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faPlus, faHeart, faTimes, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import APIFetch from "../../Utilities/APIFetch";
import FlipMove from 'react-flip-move';

import './KudosItem.scss';
import { AccountStore } from "../../Stores/AccountStore";
import LikeList from "../LikeList/LikeList";
import Scrollbar from "react-scrollbars-custom";
import CommentsSection from "../CommentsSection/CommentsSection";

const CUSTOM_ENTER_ANIM = {
    from: { transform: 'scaleX(0)', transformOrigin: 'right center' },
    to: { transform: '', transformOrigin: 'right center' },
}

const CUSTOM_LEAVE_ANIM = {
    from: { transform: 'scaleX(1)', transformOrigin: 'right center' },
    to: { transform: 'scaleX(0)', transformOrigin: 'right center' },
}

const KudosItem = (props) => {
    const k = props.kudos;
    const myProfile = useStoreState(AccountStore, s => s.profile);
    const role = useStoreState(AccountStore, s => s.status.role);
    const companyValue = useStoreState(CompanyValuesStore, s => s.values.find(e => e.id === k.valueId) || null);
    const employee = useStoreState(EmployeeStore, s => { return s[k.recipientId] || null });
    const employee2 = useStoreState(EmployeeStore, s => { return s[k.senderId] || null });
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [liking, setLiking] = useState(false);
    const [showLikes, setShowLikes] = useState(false);
    const [showComments, setShowComments] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(!props.kudos.muted);
    const [menu, setMenu] = useState(false);
    const [muting, setMuting] = useState(false);
    const wrapperRef = useRef(null);

    if(employee === null) {
        getProfile(k.recipientId)
        .catch(e => {});
    }

    if(employee2 === null) {
        getProfile(k.senderId)
        .catch(e => {});
    }

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if(menu && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, menu, setMenu]);

    const onProfileClick = (profile) => {
        if(profile) {
            ActionStore.update(s => { s.modals.push({ type: 'Profile', data: profile }) });
        }
    }

    const toggleNotifications = () => {
        if(!muting) {
            setMuting(true);
            setMenu(false);

            if(notificationsEnabled) {
                APIFetch("POST", `comment/mute/${k.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(false);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            } else {
                APIFetch("POST", `comment/unmute/${k.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(true);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            }
        }
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(k.userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${k.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(false);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${k.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        if(props.onLike) {
                            props.onLike(true);
                        }
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const viewLikes = () => {
        if(k.likes > 0) {
            setShowLikes(!showLikes);
        }
    }

    const viewComments = () => {
        setShowComments(!showComments);
    }

    return (
        <div className="kudos-item margin-top-1 margin-top-first-0">
            <div className={"card flex-row flex-align-center padding-1 " + (detailsOpen ? "card-open " : "") + (props.className || '')} role="group" tabIndex="0">
                <img src={employee && employee.image ? employee.image : defaultProfileImage} className="profile-image-sm pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employee)} tabIndex="0" alt=""/>
                <div className="flex text-align-left flex-shrink-1 margin-right-1">
                    <p className="margin-0 margin-left-2 bold"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employee)}>{employee && employee.displayName ? (employee.id === myProfile.id ? 'You' : employee.displayName || "Someone") : "Someone"}</span> received recognition for "{companyValue ? companyValue.name : k.valueId}" from <span className="underline pointer" onClick={() => onProfileClick(employee2)}>{employee2 && employee2.displayName ? (employee2.id === myProfile.id ? 'You' : employee2.displayName) : "Someone"}</span>.</p>
                    {role === 'Admin' ? <p className="margin-0 text-sm text-sub margin-left-2 admin-coin-count"><span className="bold">{k.amount}</span> Coins Received</p> : null }
                    <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{moment(k.createdAt).calendar()}</p>
                </div>
                <div className="like-count flex flex-item-right flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (k.userLiked ? '' : ' text-grey')} tabIndex="0" onClick={onLike} aria-label="Like" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (k.likes > 0 ? " pointer underline bold" : "")} onClick={viewLikes} tabIndex="0" onBlur={() => setShowLikes(false)}>{k.likes}</p>
                    {showLikes ? <LikeList id={k.id} onClose={() => setShowLikes(false)} /> : null}
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
                    <FontAwesomeIcon icon={showComments ? faTimes : faComment} className={"text-lg pointer text-grey"} tabIndex="0" onClick={viewComments} aria-label="Comments" role="button"/>
                    <p className={"margin-0 text-sm text-grey margin-top-1" + (k.comments > 0 ? " pointer underline bold" : "")} onClick={viewComments} tabIndex="0">{k.comments}</p>
                </div>
                <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 absolute" style={{ right: '34px' }}>
                    <FontAwesomeIcon icon={faEllipsisH} className={"text-lg pointer text-grey"} style={{ width: "30px" }} tabIndex="0" onClick={() => setMenu(!menu)} aria-label="Show post options" role="button"/>
                </div>
                {menu ? <div className="card-menu" tabIndex={0} onBlur={() => setMenu(false)} ref={wrapperRef}>
                            <div className="pointer bold" onClick={toggleNotifications}>
                                {notificationsEnabled ? "Turn off notifications for this post" : "Turn on notifications for this post"}
                            </div>
                        </div> : null }
                <FlipMove
                    typeName={null}
                    duration={200}
                    enterAnimation={CUSTOM_ENTER_ANIM}
                    leaveAnimation={CUSTOM_LEAVE_ANIM}>
                    { detailsOpen ? <div className="details-expand flex-row flex-align-center">
                            <Scrollbar className="flex-justify-center margin-right-4">
                                <p className="bold text-md margin-0"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employee2)}>{employee2 ? employee2.displayName || "Someone" : "Someone"}</span> said:</p>
                                <p className="text-md margin-0">‘{k.comment}’</p>
                            </Scrollbar>
                            <img src={employee2 && employee2.image ? employee2.image : defaultProfileImage} className="profile-image-sm pointer margin-1 margin-right-0 margin-left-2" tabIndex="0" onClick={() => onProfileClick(employee2)} alt=""/>
                        </div> : null
                    }
                </FlipMove>
                <div className="expander pointer text-md flex flex-center-both flex-shrink-0" tabIndex="0" role="button" onClick={() => { setDetailsOpen(!detailsOpen); }}>
                    <FontAwesomeIcon icon={detailsOpen ? faTimes : faPlus } className="text-md text-alt"/>
                </div>
            </div>
            {showComments ? <CommentsSection activityId={k.id} employeeId={k.recipientId}/> : null }
        </div>
    );
};

export default KudosItem;