import { faBuilding, faEnvelope, faLocationArrow, faMobile, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useStoreState } from "pullstate";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Scrollbar from 'react-scrollbars-custom';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import { AccountStore } from "../../Stores/AccountStore";
import { ActionStore } from '../../Stores/ActionStore';
import { FeedStore } from "../../Stores/FeedStore";
import APIFetch from "../../Utilities/APIFetch";
import AwardItem from "../AwardItem/AwardItem";
import Badge from "../Badge/Badge";
import BadgeItem from "../BadgeItem/BadgeItem";
import KudosItem from "../KudosItem/KudosItem";
import RedemptionItem from "../RedemptionItem/RedemptionItem";
import './ProfileModal.scss';
const ROW_HEIGHT = 75;

const ProfileModal = (props) => {
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const myBadges = useStoreState(AccountStore, s => s.badges);
    const details = props.profile;
    const [userFeed, setUserFeed] = useState([]);
    const [kudosCount, setKudosCount] = useState('-');
    const [kudosSentCount, setKudosSentCount] = useState('-');
    const [badges, setBadges] = useState([]);
    var badgeItems = [];

    // Feed List
    const [feedDate, setFeedDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [totalItems, setTotalItems] = useState(0);

    const onClose = () => {
        props.onClose();
    }

    const openBadges = () => {
        var badgeDetails = Object.assign({}, details);
        badgeDetails.badges = badges;

        ActionStore.update(s => { s.modals.push({ type: 'Badges', data: badgeDetails })  });
    }

    const onLike = (type, id, value) => {
        // Update profile and main feeds
        var match = userFeed.find(a => a[type] && a[type].id === id);
        if(match) {
            if(value) {
                match[type].likes += 1;
            } else {
                match[type].likes -= 1;
            }
            match[type].userLiked = value;
        }
        setUserFeed(userFeed);

        FeedStore.update(s => { 
            var match = s.feed.find(a => a[type] && a[type].id === id);
            if(match) {
                if(value) {
                    match[type].likes += 1;
                } else {
                    match[type].likes -= 1;
                }
                match[type].userLiked = value;
            }
        });
    }

    const getFeed = (id, from, to, date = feedDate) => {
        APIFetch('GET', `feed/${id}?from=${from}&to=${to}&date=${date}`)
        .then((result) => {
            if(result && result.ok) {
                if(from === 0) {
                    setUserFeed(result.data.feed);
                } else {
                    setUserFeed(userFeed.concat(result.data.feed));
                }

                setTotalItems(result.data.totalItems);
                setIsLoading(false);
            } else {
                setUserFeed(null);
            }
        })
        .catch(e => {
            setUserFeed(null);
        });
    }

    const onScroll = (scrollValues) => {
        if(userFeed && !isLoading && totalItems > userFeed.length && (scrollValues.scrollTop + scrollValues.clientHeight) > scrollValues.scrollHeight - (0.5 * ROW_HEIGHT)) {
            setIsLoading(true);
            getFeed(details.id, userFeed.length, userFeed.length + 100);
        }
    }

    useEffect(() => {
        const getData = () => {
            var newDate = new Date().toISOString();
            setFeedDate(newDate);
            getFeed(details.id, 0, 100, newDate);
    
            APIFetch('GET', `kudos/count/${details.id}?api-version=2.0`)
            .then((result) => {
                if(result && result.ok) {
                    setKudosCount(result.data.received);
                    setKudosSentCount(result.data.sent);
                }
            })
            .catch(e => {
            });
    
            if(myId === details.id && myBadges) {
                setBadges(myBadges);
            } else {
                APIFetch('GET', `badges/${details.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setBadges(result.data);
                    }
                })
                .catch(e => {
                });
            }
        }

        // On user change
        setUserFeed([]);
        getData();
    }, [details, myBadges, myId]);

    if(badges && badges.length > 0) {
        for(var i = 0; i < Math.min(badges.length, 4); i++) {
            badgeItems.push(<Badge badge={badges[i]} key={badges[i].name + badges[i].createdAt}/>);
        }
    }

    return (
        <div className="modal profile-modal">
            <div className="modal-box flex">
                <div className="content flex flex-grow">
                    <div className="underline pointer text-lg text-grey" onClick={onClose} tabIndex={0} role="button">{"< Go back"}</div>
                    <div className="flex-row flex-grow">
                        <div className="scroll-background flex flex-grow modal-list margin-top-3 margin-bottom-2 profile-bar">
                            <Scrollbar scrollerProps={{tabIndex: 0}} className="margin-right-1 margin-top-3 margin-bottom-2">
                                <div className="flex  overflow-y full-width">
                                    <div className="flex-row flex-align-center">
                                        <img src={details && details.image ? details.image : defaultProfileImage} className="profile-image-lg margin-right-1" alt=""/>
                                        <div className="flex margin-left-2 text-align-left">
                                            <p className="text-xlg bold margin-0">{details ? details.displayName || "Someone" : "Someone"}</p>
                                            <p className="text-md light margin-0 text-dark">{details ? details.jobTitle : ''}</p>
                                        </div>
                                    </div>
                                    <div className="flex-row flex-space-between padding-1 margin-top-1 margin-bottom-1 border-box">
                                        <div className="text-align-left margin-right-2">
                                            <div className="text-xlg">{kudosSentCount}</div>
                                            <div className="text-dark text-s">Recognition Sent</div>
                                        </div>
                                        <div className="text-align-left margin-right-2">
                                            <div className="text-xlg">{kudosCount}</div>
                                            <div className="text-dark text-s">Recognition Received</div>
                                        </div>
                                    </div>
                                    { myId !== details.id ? <div className="btn btn-positive flex flex-center-both flex-center-self margin-1" onClick={() => props.sendRecognition(details)} tabIndex={0} role="button">
                                        Send Recognition
                                    </div> : null }
                                    <div className="margin-top-1 padding-1 border-box margin-bottom-1">
                                        <div className="flex-row flex-align-center">
                                            <div className="text-md icon-circle margin-right-2 flex flex-center-both">
                                                <FontAwesomeIcon icon={faBuilding} className="text-sm text-alt" />
                                            </div>
                                            <div>
                                                <div className="text-sub text-sm text-align-left text-grey">Department:</div>
                                                <div className="text-md text-grey">{details.department || '-'}</div>
                                            </div>
                                        </div>
                                        <div className="flex-row flex-align-center margin-top-2">
                                            <div className="text-md icon-circle margin-right-2 flex flex-center-both">
                                                <FontAwesomeIcon icon={faLocationArrow} className="text-sm text-alt" />
                                            </div>
                                            <div>
                                                <div className="text-sub text-sm text-align-left text-grey">Location:</div>
                                                <div className="text-md text-grey">{details.officeLocation || '-'}</div>
                                            </div>
                                        </div>
                                        <div className="flex-row flex-align-center margin-top-2">
                                            <div className="text-md icon-circle margin-right-2 flex flex-center-both">
                                                <FontAwesomeIcon icon={faEnvelope} className="text-sm text-alt" />
                                            </div>
                                            <div>
                                                <div className="text-sub text-sm text-align-left text-grey">Email Address:</div>
                                                <div className="text-md text-grey">{details.mail || '-'}</div>
                                            </div>
                                        </div>
                                        <div className="flex-row flex-align-center margin-top-2">
                                            <div className="text-md icon-circle margin-right-2 flex flex-center-both">
                                                <FontAwesomeIcon icon={faPhone} className="text-sm text-alt" />
                                            </div>
                                            <div>
                                                <div className="text-sub text-sm text-align-left text-grey">Work Phone Number:</div>
                                                <div className="text-md text-grey">{details.businessPhones ? details.businessPhones[0] : '-'}</div>
                                            </div>
                                        </div>
                                        <div className="flex-row flex-align-center margin-top-2">
                                            <div className="text-md icon-circle margin-right-2 flex flex-center-both">
                                                <FontAwesomeIcon icon={faMobile} className="text-sm text-alt" />
                                            </div>
                                            <div>
                                                <div className="text-sub text-sm text-align-left text-grey">Work Mobile Number:</div>
                                                <div className="text-md text-grey">{details.mobilePhone || '-'}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="margin-top-1">
                                        <div className="text-md text-align-left">
                                            Badges Earned
                                        </div>
                                        <div className="flex-row flex-space-between padding-2 full-width flex-align-center">
                                            {badgeItems}
                                            { badges && badges.length > 0 ? <div className="btn btn-positive flex flex-center-both" onClick={openBadges} tabIndex={0} role="button">
                                                View All
                                            </div> : null }
                                        </div>
                                    </div>
                                </div>
                            </Scrollbar>
                        </div>
                        <div className="scroll-background flex flex-grow modal-list margin-top-3 margin-bottom-2">
                            <Scrollbar scrollerProps={{tabIndex: 0, role: "group"}} onScroll={onScroll}>
                                { userFeed && userFeed.length === 0 ? <p className="text-md text-align-center">No activity yet!</p> : null }
                                { userFeed ? userFeed.map(fI => {
                                    if(fI.kudos) {
                                        return <KudosItem key={fI.kudos.id} kudos={fI.kudos} onLike={(value) => { onLike("kudos", fI.kudos.id, value) } } className='white-background'/>;
                                    } else if(fI.redemption) {
                                        return <RedemptionItem key={fI.redemption.id} redemption={fI.redemption} onLike={(value) => { onLike("redemption", fI.redemption.id, value) } }/>
                                    } else if(fI.badge) {
                                        return <BadgeItem key={fI.badge.id} badge={fI.badge} onLike={(value) => { onLike("badge", fI.badge.id, value) } }/>;
                                    } else if(fI.award) {
                                        return <AwardItem key={fI.award.id} award={fI.award} onLike={(value) => { onLike("award", fI.award.id, value) } }/>;
                                    } else {
                                        return null;
                                    }
                                }) : <p>Failed to retrieve activity feed.</p>}
                                {userFeed && userFeed.length < totalItems ? <div>
                                    <Loader
                                        type="Oval"
                                        color="#FF0000"
                                        height={40}
                                        width={40}
                                        className="list-loader margin-bottom-1"
                                    />
                                </div> : null}
                            </Scrollbar>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileModal;