import appConfig from "../config.json";
import { SessionStore } from "../Stores/SessionStore";
import { navigate } from "@gatsbyjs/reach-router";

const APIFetch = (method, url, requestBody, multipartBody) => {
    var sessionManager = SessionStore.currentState.sessionManager;

    if(!sessionManager) {
        return { ok: false, status: 0 , data: null };
    }

    return sessionManager.getToken()
    .then(token => {
        var headers = {
            'Authorization': 'Bearer ' + token
        };

        if(requestBody) {
            headers['Content-Type'] = 'application/json';
        }

        return fetch(appConfig[process.env.REACT_APP_ENV || process.env.NODE_ENV || 'development' ].API_DOMAIN + url, {
            method: method,
            mode: 'cors',
            headers: headers,
            body: multipartBody ? multipartBody : (requestBody ? JSON.stringify(requestBody) : null)
        })
        .then((response) => {
            if(response.ok) {
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json()
                    .then(result => {
                        return { ok: response.ok, status: response.status, data: result };
                    })
                    .catch(e => {
                        return e;
                    });
                } else if (contentType && contentType.indexOf("text/plain") !== -1) {
                    return response.text()
                    .then(result => {
                        return { ok: response.ok, status: response.status, data: result };
                    })
                    .catch(e => {
                        return e;
                    });
                } else {
                    return { ok: response.ok, status: response.status, data: null };
                }
            } else {
                if(response.status === 403) {
                    navigate('/');
                    return { ok: response.ok, status: response.status, data: response };
                } else {
                    return { ok: response.ok, status: response.status, data: response };
                }
            }
        })
        .catch((e) => {
            return e;
        })
    })
    .catch(e => {
        // Auth redirect should happen automatically
        return { ok: false, status: 401, data: null };
    })
}

export default APIFetch;