import React, { useState, forwardRef, useImperativeHandle } from "react";
import './EmployeeLookup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';

import { AccountStore } from "../../Stores/AccountStore";
import { searchPeople } from "../../Utilities/GraphAPI";
import { useStoreState } from "pullstate";
import { useRef } from "react";

var currentTimeout = null;

const MultiEmployeeLookup = (props, ref) => {
    const myId = useStoreState(AccountStore, s => s.profile.id);
    const [selectedEmployees, setSelectedEmployees] = useState(props.target ? [props.target] : []);
    const [matchingEmployees, setMatchingEmployees] = useState([]);
    const [focused, setFocused] = useState(false);
    const inputRef = useRef(null);
    var employeeItems = [];

    // Set ref
    useImperativeHandle(ref, () => ({
        getSelected: () => {
            return selectedEmployees;
        },
        reset: () => {
            setSelectedEmployees([]);
            setMatchingEmployees([]);
            if(inputRef.current) {
                inputRef.current.value = '';
            }
        }
    }));

    const getList = (searchTerm) => {
        searchPeople(searchTerm, myId)
        .then(result => {
            if(result.ok) {
                setMatchingEmployees(result.data);
            } else {
                setMatchingEmployees([]); 
            }
        })
        .catch(e => {
            setMatchingEmployees([]);
        });
    }

    const deselectEmployee = (employee) => {
        setSelectedEmployees(selectedEmployees.filter(e => e !== employee));
        if(props.onChange) {
            props.onChange(selectedEmployees.filter(e => e !== employee));
        }
    }

    matchingEmployees.filter(e => { return !selectedEmployees.includes(e) }).forEach(e => {
        let handleClick = () => {
            var newEmployees = [].concat(selectedEmployees);
            newEmployees.push(e);
            setSelectedEmployees(newEmployees);
            setMatchingEmployees([]);
            if(inputRef.current) {
                inputRef.current.value = '';
            }

            if(props.onChange) {
                props.onChange(newEmployees);
            }
        };

        employeeItems.push(
            <div key={e.id} className="flex-row employee-lookup-item pointer" onMouseDown={handleClick} role="option" tabIndex="0">
                <img src={e.image || defaultProfileImage} className="profile-image-sm margin-1" alt=""/>
                <div className="flex flex-justify-center margin-left-1">
                    <p className="margin-0 text-md bold">{e.displayName}</p>
                    <p className="margin-0 text-md">{e.jobTitle} - {e.department}</p>
                </div>
            </div>
        );
    });

    return (
        <div className={"employee-lookup multi-employee-lookup relative input " + props.className}
            onBlur={(event) => { if (!event.currentTarget.contains(event.relatedTarget)) {
                setFocused(false);
            } }}
            onFocus={() => { setFocused(true) }}>
                <div className="flex-row flex-wrap">
                { selectedEmployees && selectedEmployees.length > 0 ? selectedEmployees.map(e => { 
                    return <div className="flex-row flex-align-center selected-item" key={e.id}>
                        <img src={e.image || defaultProfileImage} className="profile-image-x-sm margin-1" alt=""/>
                        <div className="flex-row flex-center-both">
                            <p className="margin-0 text-sm">{e.displayName}</p>
                        </div>
                        <FontAwesomeIcon icon={faTimes} className="pointer margin-left-1 text-sm text-alert" onClick={() => { deselectEmployee(e) }} tabIndex="0" role="button"/>
                    </div> })
                : null }
                </div>
            <input type="text" placeholder={props.placeholder || "Employee name"}
                    ref={inputRef}
                    className="full-width"
                    aria-label="Search Employees"
                    onChange={e => { 
                        clearTimeout(currentTimeout);
                        if(!e.target.value || e.target.value.length === 0) {
                            setMatchingEmployees([]);
                        } else {
                            currentTimeout = setTimeout((value) => {
                                getList(value);
                            }, 500, e.target.value);
                        }
            }}/>
            <div className="employee-lookup-list absolute full-width box-shadow" role="listbox" tabIndex="0">
                {focused ? employeeItems : null}
            </div>
        </div>
    );
};

export default forwardRef(MultiEmployeeLookup);