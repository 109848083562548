import React from 'react';
import AppStoreBadge from '../../Assets/images/AppStoreBadge.svg';
import PlayStoreBadge from '../../Assets/images/PlayStoreBadge.svg';
import './AppLinks.scss';

const AppLinks = () => {
    return(
        <div className="app-links flex flex-align-center padding-2">
            <h1 className="text-alt text-align-center">Get the app!</h1>
            <p className="text-alt text-lg text-align-center">Download the mobile app from the Apple App Store or Google Play Store now to access Cwtsh from your device.</p>
            <div className="badge-row flex flex-center-both">
                <img src={AppStoreBadge} alt="Apple App Store" />
                <a href="https://play.google.com/store/apps/details?id=com.altlabs.tfwhub">
                    <img className="pointer" src={PlayStoreBadge} alt="Google Play Store" />
                </a>
            </div>
        </div>
    )
}

export default AppLinks;