import React, { useEffect, useState, useRef } from 'react';
import './CircularProgress.scss';

const CircularProgress = props => {
    const [offset, setOffset] = useState(0);
    const circleRef = useRef(null);
    const {
        size,
        progress,
        strokeWidth
    } = props;

    const center = size / 2;
    const radius = size / 2 - strokeWidth / 2;
    const circumference = 2 * Math.PI * radius;

    useEffect(() => {
        const progressOffset = ((100 - progress) / 100) * circumference;
        setOffset(progressOffset);

        circleRef.current.style.transition = 'stroke-dashoffset 850ms ease-in-out';

    }, [setOffset, progress, circumference, offset]);

    return (
        <svg
            className="svg"
            width={size}
            height={size}
        >
            <circle
                className="svg-circle-bg"
                stroke="#CCCCCC"
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
            />
            <circle
                className="svg-circle"
                ref={circleRef}
                stroke="#FF0000"
                cx={center}
                cy={center}
                r={radius}
                strokeWidth={strokeWidth}
                strokeDasharray={circumference}
                strokeDashoffset={offset}
            />
            <text 
                x={`${center}`} 
                y={`${center + 12}`} 
                className="svg-circle-text">
                    {progress}%
            </text>
        </svg>
    );
}

export default CircularProgress;