import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComment, faEllipsisH, faHeart, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import Scrollbar from 'react-scrollbars-custom';
import './FullPost.scss';
import CommentsSection from "../CommentsSection/CommentsSection";
import APIFetch from "../../Utilities/APIFetch";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";
import Badge from "../Badge/Badge";
import { ActionStore } from "../../Stores/ActionStore";
import { getProfile } from "../../Utilities/GraphAPI";
import { AccountStore } from "../../Stores/AccountStore";
import { CompanyValuesStore } from "../../Stores/CompanyValuesStore";
import { useStoreState } from "pullstate";
import LikeList from "../LikeList/LikeList";
import defaultProfileImage from '../../Assets/images/defaultProfileImage.png';
import moment from "moment";

const FullPost = (props) => {
    const [activityData, setActivityData] = useState(null);
    const [activityType, setActivityType] = useState(null);
    const [employee1, setEmployee1] = useState(null);
    const [employee2, setEmployee2] = useState(null);
    const [companyValue, setCompanyValue] = useState(null);
    const [liking, setLiking] = useState(false);
    const [showLikes, setShowLikes] = useState(false);
    const [notificationsEnabled, setNotificationsEnabled] = useState(true);
    const [menu, setMenu] = useState(false);
    const [muting, setMuting] = useState(false);
    const [userLiked, setUserLiked] = useState(null);
    const [likes, setLikes] = useState(null);
    const wrapperRef = useRef(null);
    const myProfile = useStoreState(AccountStore, s => s.profile);

    const onClose = () => {
        props.onClose();
    }

    const onBadgeClick = () => {
        if(employee1) {
            ActionStore.update(s => { s.modals.push({ type: 'Badges', data: employee1 }) });
        }
    }

    const onProfileClick = (employee) => {
        ActionStore.update(s => { s.modals.push({ type: 'Profile', data: employee }) });
    }

    const toggleNotifications = () => {
        if(!muting) {
            setMuting(true);
            setMenu(false);

            if(notificationsEnabled) {
                APIFetch("POST", `comment/mute/${activityData.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(false);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            } else {
                APIFetch("POST", `comment/unmute/${activityData.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setNotificationsEnabled(true);
                    }
                    setMuting(false);
                })
                .catch(e => {
                    setMuting(false);
                });
            }
        }
    }

    const onLike = () => {
        if(!liking) {
            setLiking(true);
            if(userLiked) {
                // Unlike
                APIFetch("POST", `social/unlike/${activityData.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setUserLiked(false);
                        setLikes(likes - 1);
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            } else {
                // Like
                APIFetch("POST", `social/like/${activityData.id}`)
                .then((result) => {
                    if(result && result.ok) {
                        setUserLiked(true);
                        setLikes(likes + 1);
                    }

                    setLiking(false);
                })
                .catch(e => {
                    setLiking(false);
                });
            }
        }
    }

    const viewLikes = () => {
        if(likes > 0) {
            setShowLikes(!showLikes);
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if(menu && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                setMenu(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [wrapperRef, menu, setMenu]);

    useEffect(() => {
        APIFetch('GET', `feed/item/${props.postId}`)
        .then((result) => {
            if(result && result.ok) {
                // Display recognition, awards and badges differently
                if(result.data.kudos) {
                    setActivityData(result.data.kudos);
                    setActivityType("kudos");
                    setNotificationsEnabled(!result.data.kudos.muted);
                    setUserLiked(result.data.kudos.userLiked);
                    setLikes(result.data.kudos.likes);
                    setCompanyValue(CompanyValuesStore.currentState.values.find(v => v.id === result.data.kudos.valueId));

                    getProfile(result.data.kudos.senderId)
                    .then((result) => {
                        if(result.ok) {
                            setEmployee1(result.data);
                        }
                    })
                    .catch(e => {});

                    getProfile(result.data.kudos.recipientId)
                    .then((result) => {
                        if(result.ok) {
                            setEmployee2(result.data);
                        }
                    })
                    .catch(e => {});
                } else if(result.data.award) {
                    setActivityData(result.data.award);
                    setActivityType("award");
                    setNotificationsEnabled(!result.data.award.muted);
                    setUserLiked(result.data.award.userLiked);
                    setLikes(result.data.award.likes);

                    getProfile(result.data.award.recipientId)
                    .then((result) => {
                        if(result.ok) {
                            setEmployee1(result.data);
                        }
                    })
                    .catch(e => {});
                } else if(result.data.badge) {
                    setActivityData(result.data.badge);
                    setActivityType("badge");
                    setUserLiked(result.data.badge.userLiked);
                    setLikes(result.data.badge.likes);
                    setNotificationsEnabled(!result.data.badge.muted);

                    getProfile(result.data.badge.userId)
                    .then((result) => {
                        if(result.ok) {
                            setEmployee1(result.data);
                        }
                    })
                    .catch(e => {});
                }
            }
        })
        .catch(e => {
        });
    }, [props.postId]);

    var content = null;
    var actionBar = activityData ? <div className="action-bar flex-row flex-grow flex-center-both margin-top-2 relative">
        <div className="like-count flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
            <p className="margin-0 margin-top-1 text-sm text-sub margin-left-2">{moment(activityData.createdAt).calendar()}</p>
        </div>
        <div className="like-count flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
            <FontAwesomeIcon icon={faHeart} className={"text-lg pointer" + (userLiked ? '' : ' text-grey')} onClick={onLike} tabIndex="0" aria-label="Like" role="button"/>
            <p className={"margin-0 text-sm text-grey margin-top-1" + (likes > 0 ? " pointer underline bold" : "")} onClick={viewLikes} tabIndex="0" onBlur={() => setShowLikes(false)}>{likes}</p>
            {showLikes ? <LikeList id={activityData.id} onClose={() => setShowLikes(false)} /> : null}
        </div>
        <div className="flex flex-shrink-0 text-align-center flex-center-both margin-right-2 relative">
            <FontAwesomeIcon icon={faComment} className={"text-lg text-grey"} />
            <p className={"margin-0 text-sm text-grey margin-top-1"}>{activityData.comments}</p>
        </div>
        <div className="flex flex-shrink-0 text-align-center flex-center-both relative">
            <FontAwesomeIcon icon={faEllipsisH} className={"text-lg pointer text-grey"} style={{ width: "30px" }} tabIndex="0" onClick={() => setMenu(!menu)} aria-label="Show post options" role="button"/>
        </div>
        {menu ? <div className="card-menu" tabIndex={0} onBlur={() => setMenu(false)} ref={wrapperRef}>
            <div className="pointer bold" onClick={toggleNotifications}>
                {notificationsEnabled ? "Turn off notifications for this post" : "Turn on notifications for this post"}
            </div>
        </div> : null }
    </div> : null;

    switch(activityType) {
        case "kudos":
            content = <div className="flex flex-grow padding-top-4 padding-bottom-4">
                <div className="flex flex-grow flex-align-center">
                    <div className="flex-row flex-center-both">
                        <img src={employee1 && employee1.image ? employee1.image : defaultProfileImage} className="profile-image-lg pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employee1)} tabIndex="0" alt=""/>
                        <FontAwesomeIcon icon={faLongArrowAltRight} className="kudos-arrow"/>
                        <img src={employee2 && employee2.image ? employee2.image : defaultProfileImage} className="profile-image-lg pointer margin-1 flex-shrink-0" onClick={() => onProfileClick(employee2)} tabIndex="0" alt=""/>
                    </div>
                    <div className="flex text-align-center kudos-post-block">
                        <p className="margin-0 bold text-lg text-grey-2"><span className="pointer" tabIndex="0" onClick={() => onProfileClick(employee2)}>{employee2 ? (employee2.id === myProfile.id ? 'You' : employee2.displayName || "Someone" ) : "Someone"}</span></p>
                        <p className="margin-0 text-md">received recognition from</p>
                        <p className="margin-0 bold text-lg text-grey-2"><span className="pointer" tabIndex="0" onClick={() => onProfileClick(employee1)}>{employee1 ? (employee1.id === myProfile.id ? 'You' : employee1.displayName || "Someone" ) : "Someone"}</span></p>
                        <p className="margin-0 text-md">for</p>
                        <p className="margin-0 bold text-lg text-grey-2">{companyValue ? companyValue.name : 'Unknown'}</p>
                        <p className="margin-0 text-md">they said</p>
                        <p className="margin-0 text-md text-grey">'{activityData.comment}'</p>
                        {actionBar}
                    </div>
                </div>
            </div>
            break;
        case "award":
            content = <div className="flex flex-grow padding-top-4 padding-bottom-4">
                <div className="flex-row flex-grow flex-align-center">
                    <div className="pointer margin-right-4" onClick={onBadgeClick}>
                        <Badge badge={{ title: activityData.awardDisplayName, name: activityData.awardName }} className="flex-row flex-align-center" size={256}/>
                    </div>
                    <div className="flex text-align-center">
                        <p className="margin-1 text-md"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employee1)}>{employee1 ? (employee1.id === myProfile.id ? 'You' : employee1.displayName || "Someone") : "Someone"}</span> received the</p>
                        <p className="margin-1 bold text-lg text-grey-2">{activityData.awardDisplayName}</p>
                        <p className="margin-1 text-md">TfWRL said</p>
                        <p className="margin-1 text-md text-grey">'{activityData.comment}'</p>
                        {actionBar}
                    </div>
                </div>
            </div>
            break;
        case "badge":
            content = <div className="flex flex-grow padding-top-4 padding-bottom-4">
                <div className="flex-row flex-grow flex-align-center">
                    <div className="pointer margin-right-4" onClick={onBadgeClick}>
                        <Badge badge={activityData} className="flex-row flex-align-center" size={256}/>
                    </div>
                    <div className="flex text-align-center">
                        <p className="margin-1 text-md"><span className="underline pointer" tabIndex="0" onClick={() => onProfileClick(employee1)}>{employee1 ? (employee1.id === myProfile.id ? 'You' : employee1.displayName || "Someone") : "Someone"}</span> earned the following badge</p>
                        <p className="margin-1 bold text-lg text-grey-2">{activityData.title}</p>
                        <p className="margin-1 text-md">{activityData.description}</p>
                        <p className="margin-1 text-md text-grey">Earned by {activityData.percentageEarned.toFixed(2)}% of users</p>
                        {actionBar}
                    </div>
                </div>
            </div>
            break;
        default: break;
    }

    return (
        <div className="modal fullpost-modal">
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={onClose} tabIndex={0} role="button"/>
                    </div>
                </div>
                <div className="content flex-row flex-grow">
                    <div className="scroll-background flex flex-grow modal-list">
                        <Scrollbar scrollerProps={{tabIndex: 0}}>
                            <div className="flex padding-4">
                                {content ? content : <LoadingOverlay />}
                            </div>
                            <CommentsSection activityId={props.postId} targetComment={props.commentId}/>
                        </Scrollbar>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FullPost;