import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar'
import APIFetch from '../../Utilities/APIFetch';

const BrandBar = (props) => {
    const [data, setData] = useState(null);

    useEffect(() => {
        APIFetch('GET', `reporting/brandredemptions`)
        .then((result) => {
            if(result && result.ok) {
                setData(result.data);
            }
        })
        .catch(e => {
        });
    }, []);

    if(!data) {
        return <div>Loading...</div>;
    }

    return <ResponsiveBar 
            data={data}
            keys={['value']}
            indexBy="brand"
            margin={{ top: 20, right: 120, bottom: 75, left: 60 }}
            padding={0.3}
            colors={["#FF9999"]}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 25,
                legend: 'Brand',
                legendPosition: 'middle',
                legendOffset: 60
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: `Total Redemption Value (£)`,
                legendPosition: 'middle',
                legendOffset: -40
            }}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom-right',
                    direction: 'column',
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20
                }
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            labelFormat={(v) => {
                return `£${v/100}` 
            }}
    />
}

export default BrandBar;