import React, { useEffect, useState } from 'react';
import APIFetch from "../../Utilities/APIFetch";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CreatePost from '../CreatePost/CreatePost';
import Scrollbar from 'react-scrollbars-custom';
import DetailsPopOver from '../../Components/DetailsPopOver/DetailsPopOver';
import SocialPostItemAdmin from '../../Components/SocialPostItemAdmin/SocialPostItemAdmin';
import SocialPostModal from '../../Components/SocialPostModal/SocialPostModal';

const SocialPosts = (props) => {
    const [posts, setPosts] = useState(null);
    const [isCreating, setIsCreating] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [failedRequest, setFailedRequest] = useState(false);
    const [postDetails, setPostDetails] = useState(null);

    const getPosts = () => {
        APIFetch('GET', 'social/posts?api-version=2.0')
        .then((result) => {
            if(result && result.ok) {
                setPosts(result.data);
            } else {
                setFailedRequest(true);
            }
        })
        .catch(e => {
            setFailedRequest(true);
        });
    }

    const deletePost = (id) => {
        APIFetch('DELETE', `social/posts/${id}`)
        .then((result) => {
            if(result && result.ok) {
                getPosts();
            } else {
                setFailedRequest(true);
            }
        })
        .catch(e => {
            setFailedRequest(true);
        });
    }

    useEffect(() => {
        getPosts();
    }, []);

    if(isCreating) {
        return <CreatePost onCancel={() => { setIsCreating(false) }} onComplete={() => { getPosts(); setIsCreating(false); }}/>
    }

    if(isEditing) {
        return <CreatePost post={isEditing} onCancel={() => { setIsEditing(false) }} onComplete={() => { getPosts(); setIsEditing(false); }}/>
    }

    if(postDetails) {
        return <SocialPostModal post={postDetails} onClose={() => { setPostDetails(null) }}/>;
    }

    return(
        <div className="flex full-height">
            {failedRequest ? <DetailsPopOver onClose={() => setFailedRequest(false)} title="Oops, something went wrong!" content="An error occurred with your request, please try again later."/> : null }
            <div className="flex-row flex-align-center padding-left-1 margin-bottom-2">
                <div className="flex-row pointer" onClick={props.onBack} tabIndex={0} role="button">
                    <FontAwesomeIcon icon={faChevronLeft} className="text-lg"/>
                    <p className="margin-0 margin-left-1 text-md">Back</p>
                </div>
            </div>
            <div className="flex-row flex-align-center padding-left-1 margin-bottom-2">
                <div>
                    <div className="text-grey text-lg bold">Social Posts</div>
                    <div className="text-grey light">Create, track and delete social posts within Cwtsh.</div>
                </div>
                <div style={{ marginRight: '35px' }} className="btn btn-positive flex-item-right" onClick={() => setIsCreating(true) } tabIndex={0} role="button">
                    Create a Post
                </div>
            </div>
            <Scrollbar scrollerProps={{tabIndex: 0, role: "group" }} className="padding-2 flex flex-grow">
                <div className="social-feed padding-left-1">
                    { posts ? posts.map(p => {
                        return <SocialPostItemAdmin key={p.id} post={p} onReadMore={() => { setPostDetails(p) }} onDelete={() => deletePost(p.id)} onEdit={() => setIsEditing(p)}/>;
                    }) : <div>Failed to retrieve social feed.</div>}
                </div>
            </Scrollbar>
        </div>
    )
}

export default SocialPosts;