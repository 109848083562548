import { useStoreState } from "pullstate";
import React, { useEffect } from "react";
import Scrollbar from 'react-scrollbars-custom';
import { NotificationStore } from '../../Stores/NotificationStore';
import { loadNotifications } from "../../Utilities/Notifications";
import NotificationItem from "../NotificationItem/NotificationItem";
import './NotificationModal.scss';

const NotificationModal = (props) => {
    const notificationFeed = useStoreState(NotificationStore, s => s.notifications);

    const getFeed = () => {
        loadNotifications(true);
    }

    useEffect(() => {
        // On mount
        getFeed();
        NotificationStore.update(s => { s.count = 0 });
    }, []);

    return (
        <div className="content flex flex-grow padding-bottom-0">
            <div className="content flex-row flex-grow">
                <div className="notification-modal-list scroll-background flex flex-grow margin-top-2" role="group">
                    <Scrollbar scrollerProps={{tabIndex: 0, role: 'list'}}>
                        { notificationFeed && notificationFeed.length === 0 ? <p className="text-md text-align-center">No activity yet!</p> : null }
                        { notificationFeed ? notificationFeed.map(n => {
                            return <NotificationItem notification={n} key={n.id}/>;
                        }) : <p>Failed to retrieve notification feed.</p>}
                    </Scrollbar>
                </div>
            </div>
        </div>
    );
};

export default NotificationModal;