import React, { useEffect, useState } from 'react';
import { ResponsiveStream } from '@nivo/stream';
import APIFetch from '../../Utilities/APIFetch';
import moment from 'moment';
import { DateRangePicker } from 'react-dates';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const UserActivityStream = (props) => {
    const [data, setData] = useState(null);
    const [startDate, setStartDate] = useState(moment(new Date()).startOf(props.period === 'daily' ? 'day' : 'month').subtract(props.period === 'daily' ? 28 : 12, props.period === 'daily' ? 'days' : 'months'));
    const [endDate, setEndDate] = useState(moment(new Date()).startOf(props.period === 'daily' ? 'day' : 'month'));
    const [formatStartDate, setFormatStartDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
    const [selectValue, setSelectValue] = useState("0");

    useEffect(() => {
        getData();
    }, []);

    const getData = (from, to) => {
        APIFetch('POST', `reporting/${props.endpoint}`, { from: from ? from.toISOString() : startDate.toISOString(), to: to ? to.toISOString() : endDate.toISOString(), period: props.period })
        .then((result) => {
            if(result && result.ok) {
                var newData = [];
                let date = moment(from || startDate);

                if(props.period === 'daily') {
                    while(date.isBefore(to || endDate)) {
                        var matchedData = result.data.find(d => {
                            var dataDate = moment(d.date);
                            return dataDate.date() === date.date() && dataDate.month() === date.month();
                        });

                        newData.push({
                            "Recognition Sent": matchedData ? matchedData.recognition : 0,
                            "Redemptions": matchedData ? matchedData.redemptions : 0,
                            "Likes": matchedData ? matchedData.activityLikes : 0,
                            "Comments": matchedData ? matchedData.comments : 0,
                            "Comment Likes": matchedData ? matchedData.commentLikes : 0,
                            "Social Post Likes": matchedData ? matchedData.socialPostLikes : 0
                        });

                        date.add(1, 'days');
                    }
                } else {
                    while(date.isBefore(to || endDate)) {
                        var matchedData = result.data.find(d => {
                            var dataDate = moment(d.date);
                            return dataDate.month() === date.month() && dataDate.year() === date.year();
                        });

                        newData.push({
                            "Recognition Sent": matchedData ? matchedData.recognition : 0,
                            "Redemptions": matchedData ? matchedData.redemptions : 0,
                            "Likes": matchedData ? matchedData.activityLikes : 0,
                            "Comments": matchedData ? matchedData.comments : 0,
                            "Comment Likes": matchedData ? matchedData.commentLikes : 0,
                            "Social Post Likes": matchedData ? matchedData.socialPostLikes : 0
                        });

                        date.add(1, 'months');
                    }
                }

                setFormatStartDate(from || startDate);
                setData(newData);
            }
        })
        .catch(e => {
        });
    }

    const updateDates = (from, to) => {
        getData(from, to);
    }

    const formatTick = (value) => {
        if(props.period === 'daily') {
            var date = new Date(formatStartDate);
            date.setDate(date.getDate() + value);
            return moment(date).format('Do MMM');
        } else {
            var date = new Date(formatStartDate);
            date.setMonth(date.getMonth() + value);
            return moment(date).format('MMM YY');
        }
    }

    const updateSelect = (event) => {
        var value = event.target.value;

        setSelectValue(value);

        switch(value) {
            case "2":
                setStartDate(moment(new Date()).subtract(1, 'years').startOf('month'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'years'), moment(new Date()));
                break;
            case "3":
                setStartDate(moment(new Date()).subtract(1, 'months'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(1, 'months'), moment(new Date()));
                break;
            case "4":
                setStartDate(moment(new Date()).subtract(7, 'days'));
                setEndDate(moment(new Date()));
                updateDates(moment(new Date()).subtract(7, 'days'), moment(new Date()));
                break;
            default:
                break;
        }
    }

    if(!data) {
        return <div>Loading...</div>;
    }

    return <div className="full-width full-height">
        <div className="flex-row flex-align-center">
            <DateRangePicker
                key={formatStartDate}
                startDate={startDate} // momentPropTypes.momentObj or null,
                startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                endDate={endDate} // momentPropTypes.momentObj or null,
                endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                onDatesChange={({ startDate, endDate }) => { setSelectValue("0"); setStartDate(startDate); setEndDate(endDate); updateDates(startDate, endDate); }} // PropTypes.func.isRequired,
                focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                onFocusChange={(newFocusedInput) => { setFocusedInput(newFocusedInput); }} // PropTypes.func.isRequired,
                isOutsideRange={(day) => {
                    if(day.startOf('day').isAfter(moment(new Date()).startOf('day'))) {
                        return true;
                    }

                    if(focusedInput == "endDate" && startDate) {
                        var dateDiff = startDate.startOf('day').diff(day.startOf('day'), 'days');

                        if(dateDiff > 0 || dateDiff < (props.period === 'daily' ? -62 : -1825)) {
                            return true;
                        }
                    }

                    return false;
                }}
                displayFormat="DD/MM/YYYY"
                minimumNights={1}
            />
            <div className="select-wrapper">
                <select className="select-css" onChange={updateSelect} value={selectValue}>
                    <option value="0">
                        Custom
                    </option>
                    {props.period === 'monthly' ? <option value="2">
                        Past Year
                    </option> : null }
                    {props.period === 'daily' ? <option value="3">
                        Past Month
                    </option> : null }
                    {props.period === 'daily' ? <option value="4">
                        Past Week
                    </option> : null }
                </select>
                <FontAwesomeIcon className="chevron" icon={faChevronDown} />
            </div>
        </div>
        <ResponsiveStream
            data={data}
            keys={['Social Post Likes', 'Recognition Sent', 'Redemptions', 'Likes', 'Comment Likes', 'Comments']}
            margin={{ top: 25, right: 150, bottom: props.period === "monthly" ? 90 : 60, left: 50 }}
            enableGridY={true}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                orient: 'bottom',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: -75,
                legend: props.period === "monthly" ? 'Month' : 'Date',
                legendOffset: props.period === "monthly" ? 80 : 55,
                legendPosition: 'middle',
                format: value => formatTick(value)
            }}
            axisLeft={{
                orient: 'left',
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: 'Number of Actions',
                legendOffset: -40,
                legendPosition: 'middle'
            }}
            offsetType="none"
            curve="linear"
            order="descending"
            colors={{ scheme: 'category10' }}
            fillOpacity={0.85}
            borderColor={{ theme: 'background' }}
            dotSize={8}
            dotColor={{ from: 'color' }}
            dotBorderWidth={2}
            dotBorderColor={{ from: 'color', modifiers: [ [ 'darker', 0.7 ] ] }}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'column',
                    translateX: 100,
                    itemWidth: 80,
                    itemHeight: 20,
                    itemTextColor: '#999999',
                    symbolSize: 12,
                    symbolShape: 'circle',
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemTextColor: '#000000'
                            }
                        }
                    ]
                }
            ]}
        />
    </div>
}

export default UserActivityStream;