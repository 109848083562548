import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import Scrollbar from "react-scrollbars-custom";
import APIFetch from "../../Utilities/APIFetch";
import LikeItem from './LikeItem';
import './LikeList.scss';

const LikeList = (props) => {
    const [likes, setLikes] = useState(null);

    useEffect(() => {
        APIFetch('GET', props.endPoint || `feed/item/${props.id}/likes`)
        .then((result) => {
            if(result && result.ok) {
                setLikes(result.data);
            }
        })
        .catch(e => {
        });
    }, []);

    return (
        <div className="like-list noselect">
            <div className={likes === null ? "list loading" : "list"}>
                <div className="title">Likes</div>
                { likes === null ? <Loader
                    type="Oval"
                    color="#FF0000"
                    height={60}
                    width={60}
                    className="loader"
                /> : likes.length > 3 ? <Scrollbar>{likes.map((l) => {
                        return <LikeItem value={l} />
                    })}</Scrollbar> : likes.map((l) => {
                        return <LikeItem value={l} />
                    }) }
            </div>
            <div className="close-icon pointer" onClick={props.onClose}>
                <FontAwesomeIcon icon={faTimes} />
            </div>
        </div>
    );
};

export default LikeList;