import React from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './DetailsPopOver.scss';

const DetailsPopOver = (props) => {
    return (
        <div className={"modal popover-modal " + props.className}>
            <div className="modal-box flex">
                <div className="modal-x-wrapper">
                    <div className="modal-x">
                        <FontAwesomeIcon icon={faTimes} onClick={props.onClose} role="button" tabIndex={0}/>
                    </div>
                </div>
                <div className="details-popover">
                    <p className="text-xlg margin-top-1 bold">{props.title}</p>
                    <p className="text-md text-alt">{props.content}</p>
                </div>
            </div>
        </div>
        
    );
};

export default DetailsPopOver;