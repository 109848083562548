import React from "react";
import './Landing.scss';
import tfwLogo from '../../Assets/images/TfWLogo.svg';
import hubLogo from '../../Assets/images/HubLogo.png';
import Loader from "react-loader-spinner";
import TermsOfUseModal from "../../Components/TermsOfUseModal/TermsOfUseModal";

const Landing = (props) => {
    return <div className="landing flex-row flex-align-center">
        <svg className="background-circle-1" height="130vh" width="130vh" opacity="0.05">
            <circle cx="50%" cy="50%" r="50%" fill="white" />
        </svg>
        <svg className="background-circle-2" height="130vh" width="130vh" opacity="0.05">
            <circle cx="50%" cy="50%" r="50%" fill="white"/>
        </svg>
        <svg className="background-circle-3" height="130vh" width="130vh" opacity="0.05">
            <circle cx="50%" cy="50%" r="50%" fill="white" />
        </svg>
        <svg className="background-circle-4" height="130vh" width="130vh" opacity="0.05">
            <circle cx="50%" cy="50%" r="50%" fill="white" />
        </svg>
        <img className="tfw-logo" src={tfwLogo} alt="TfW Logo" />
        <div className="title-row flex flex-center-both">
            {props.pendingTerms ? <TermsOfUseModal pendingTerms={props.pendingTerms} termsCallback={props.termsCallback}/> : null }
            <img className="title-logo" src={hubLogo} alt="Cwtsh Logo" />
            { props.unauthorized ? <div>
                <p className="text-xlg bold text-align-center">You do not have permissions to access this resource.</p>
                <p className="text-lg light text-align-center">If you believe this is an error please contact the system administrator.</p>
            </div>
            : props.loading === false ? <div
                    className="btn btn-xlg pointer"
                    onClick={props.beginLogin}
                    tabIndex={0}
                    role="button">
                    Login
                </div>
            : <div>
                <Loader
                    type="Oval"
                    color="#FFFFFF"
                    height={40}
                    width={40}
                    className="list-loader margin-bottom-1"
                />
                <p>Loading account details...</p>
            </div>
            }
        </div>
    </div>
};

export default Landing;